import { Theme, ThemeManager, ThemeTypes } from './themeManage'
import antDesignTheme from './antDesignTheme'
import customTheme from './customTheme';

const themes: Theme = customTheme

// 默认主题
export const defaultTheme = ThemeTypes.dark

const themeManager = new ThemeManager(defaultTheme, themes);

// 主题色
export const antDthemes: Record<string, Record<string, any>> = antDesignTheme

export const loadGlobalVar = (theme: string) => {
  if (theme in antDthemes) {
    themeManager.setTheme(theme);
  }
}