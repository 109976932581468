import React from 'react';
import styles from './index.module.scss';

interface ProductInfoComponentProps {
  symbol: string;
  price: string;
}

const ProductInfoComponent: React.FC<ProductInfoComponentProps> = ({
  symbol,
  price
}) => {
  return (
    <span className={styles.price}>
      { symbol } { price }
    </span>
  );
};

export default ProductInfoComponent;
