import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, message } from 'antd';
import { find } from 'lodash';
import moment from 'moment';
import BreadCrumb from '@/components/BreadCrumb';
import { approvalFlow, orderDetail } from '@api/newApi/orderdetail';

import { GetRequest } from '@/util';
import { useTranslation } from 'react-i18next';

import Progress from './components/Progress';
import Information from './components/Information';
import LogisticsTrajectory from './components/LogisticsTrajectory';
import Goods from './components/Goods';
import PayInfo from './components/PayInfo';
import ShopRemark from './components/ShopRemark';
import ContractInfo from './components/ContractInfo';
import styles from './index.module.scss';
import ExtInfo from '@/pages/OrderDetailNew/components/ConsigneeInfo';
import Mark from '@/pages/OrderDetailNew/components/Mark';
import { ExclamationCircleFilled } from '@ant-design/icons';

interface IAppProps {}

const Detail: React.FC<IAppProps> = () => {
  const { t } = useTranslation();
  const urlParams: any = GetRequest();
  const id = urlParams.id;
  const isFrom = urlParams.isFrom;
  const [logisticsInfo, setLogisticsInfo] = useState(null);
  const [contractList, setContracList] = useState([]);
  const getFromInfo = (isFrom: any) => {
    //1 慧采 2 订单回收 3 订单审批 0 订单列表
    switch (isFrom) {
      case '3':
        return { label: TLT(`审批列表`), url: '/approvalList' };
      default:
        return { label: TLT(`订单列表`), url: '/orderList' };
    }
  };
  const crumbData = [
    {
      label: TLT(`工作台`),
      url: '/userCenter',
    },
    {
      label: getFromInfo(isFrom).label,
      url: getFromInfo(isFrom).url,
    },
    {
      label: TLT(`订单编号`) + id,
    },
  ];

  const [orderDetails, setOrderDetails] = useState<any>({});
  const store: any = useSelector(state => {
    return state;
  });
  const orderPin = urlParams.orderPin ? urlParams.orderPin : store?.User?.data[0]?.pin ?? '';
  const isVsp = urlParams.isVsp === '1' || false;

  const orderDetailFn = () => {
    const getData = async () => {
      const result: any = (await orderDetail({ orderId: id, orderPin: orderPin, orderSource: isVsp ? 0 : 1 })) as any;
      if (result.success) {
        const order = result.value;
        order.bizOrder = order.bizOrder || {};
        setOrderDetails(order);
        order?.deliveryInfo?.trackInfoList.forEach((ele, idx) => {
          ele.title = ele.trackContent;
          ele.description = moment(ele.trackMsgTime).format('YYYY-MM-DD HH:mm:ss');
          if (!idx) ele.status = 'finish';
        });

        const newContractList = [
          {
            label: TLT(`合同号`),
            value: order.orderRes.ebsContractCode,
          },
          {
            label: TLT(`贸易条款`),
            value: order?.orderRes?.tradeTerms,
          },
          {
            label: TLT(`支付方式`),
            value: order?.orderRes?.payType,
          },
          {
            label: TLT(`交易币种`),
            value: order?.orderRes?.currency,
          },
        ];
        setContracList(newContractList);
        setLogisticsInfo(order?.deliveryInfo?.trackInfoList || []);
      } else {
        message.error(result.msg);
      }
    };
    getData();
  };

  // 刷新页面接口
  const [refresh, setRefresh] = useState<any>({});
  useEffect(() => {
    if (refresh) {
      orderDetailFn && orderDetailFn();
      setRefresh(false);
    }
  }, [refresh]);

  //商品清单
  const orderSkus = orderDetails?.orderRes?.waresReqs;
  const orderFreightResList = orderDetails?.orderRes?.orderFreightResList;

  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });

  // 判断是否审批账号 6：审批
  const isApprovalAccount = currentUser?.userType === 6 ?? false;

  const isShowPo = (!isVsp && orderSkus && orderDetails.bizOrder.orderState == 1) || isApprovalAccount;

  return (
    <div className={styles.detailContainer}>
      <BreadCrumb data={crumbData} />
      <div className={styles.progress}>
        <Progress
          orderDetails={orderDetails}
          orderDetailFn={() => {
            setRefresh(true);
          }}
        ></Progress>
      </div>

      {!!logisticsInfo && !!logisticsInfo.length && (
        <div className={styles.shopRemark}>
          <LogisticsTrajectory
            LogisticsInfo={logisticsInfo}
            expectedDeliveryDate={`${TLT(`预计XX送达`).replace(
              'XX',
              moment(orderDetails?.orderRes?.expectedDeliveryDate).format('MM-DD'),
            )}`}
          />
        </div>
      )}

      <ExtInfo orderDetails={orderDetails} contentList={contractList}></ExtInfo>

      {/* <div className={styles.information}>
        <ContractInfo tit="合同信息" contentList={contractList}></ContractInfo>
      </div> */}

      {/* 收货人信息 */}
      {/* <div className={styles.information}>
        <Information orderDetails={orderDetails}></Information>
      </div> */}

      <Mark orderDetails={orderDetails}></Mark>
      {/* <div className={styles.shopRemark}>
        <ShopRemark orderDetails={orderDetails} />
      </div> */}

      <div className={styles.product}>
        <Goods
          goodsList={orderSkus}
          hasSplitFreight={!!orderDetails.hasSplitFreight}
          serviceSkus={orderDetails?.bizOrder?.serviceSkus ?? []}
        ></Goods>
        {orderSkus && orderSkus.some(ele => ele.sourceCountryCode == 'CN') && (
          <div className={styles.questionTip}>
            <ExclamationCircleFilled style={{ color: '#7A7E97', marginRight: 3 }} /> {TLT(`跨境商品，货期和报关方式与境内贸易商品不同，敬请留意。如有疑问，请随时联系我们的运营经理`)}
          </div>
        )}
        {/* {Object.keys(orderDetails).length ? <PayInfo orderDetails={orderDetails}></PayInfo> : ''} */}
      </div>
    </div>
  );
};

export default Detail;
