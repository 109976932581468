/*
 * @Author: weiyanna
 * @Date: 2022-03-15 11:13:21
 * @LastEditTime: 2022-10-11 18:47:13
 * @LastEditors: weiyanna weiyanna@jd.com
 * @Description: 规格参数组件
 * @FilePath: /isp_pro/src/components/QrCode/index.tsx
 */
import { Tooltip } from 'antd';
import React from 'react';
import styles from './index.module.scss';
export interface IAppProps {
  List: string;
  paddingLeft?: string;
}
const PropertyList: React.FC<IAppProps> = (props: any) => {
  const { List, paddingLeft = '0' } = props;
  return (
    <>
      <div className={styles.mc_introduce}>{TLT(`规格参数`)}</div>
      
      <div className={styles.mc_plbox} style={{ paddingLeft: paddingLeft }}>
        {List?.map((item: any) => (
          <div className={styles.mc_plbox_box} key={item?.key}>
            <div className={styles.mc_plbox_box_title} title={item?.key}>
              {item?.key}:
            </div>
            {item?.value?.length > 13 ? (
              <Tooltip placement="top" title={item?.value}>
                <div className={styles.mc_plbox_box_content}>{item?.value}</div>
              </Tooltip>
            ) : (
              <div className={styles.mc_plbox_box_content}>{item?.value}</div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default PropertyList;
