import React from 'react';
import UserCenterMenu from '@/components/UserCenterMenu';
import { Affix } from 'antd';
import styles from './index.module.scss';
import { getRouteMetaInfo } from '@/router/router';
import Title from '@/components/Title';

const Layout: React.FC = ({ children }) => {
  const currentPath = window.location.pathname;

  const meta = getRouteMetaInfo(currentPath);

  return (
    <div className={styles.layout}>
      <div>
        <Affix offsetTop={0}>
          <UserCenterMenu />
        </Affix>
      </div>
      <div className={styles.main}>
        {meta?.moduleName ? <Title title={TLT(meta?.moduleName)}></Title> : ''}
        {children}
      </div>
    </div>
  );
};

export default Layout;
