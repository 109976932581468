import React, { useState, useEffect } from 'react';
import styles from '@/pages/HomePage/index.module.scss';
import { getBannerInfo } from '@/api/newApi/homePage';
import { Image, Carousel } from 'antd';
import { useOpenDir, OpenDirOption } from '@/util/hooks/openPage';
import { getJdiOSFile } from '@/util/index';
import Slider from 'react-slick';
import { customClickReportSimple } from '@/dataPort';

interface BannerItem {
  imgUrl: string;
  sorted: number;
  jumpUrl: string;
}

type BannerList = BannerItem[];

const MyComponent = () => {
  const openDir = useOpenDir();

  const [imgs, setImages] = useState<{
    small: BannerList;
  }>({
    small: [],
  });

  const init = async () => {
    const result = await getBannerInfo();
    console.log(result, 'result----');
    if (result.success) {
      console.log(result.value);
      setImages(result.value);
    }
  };

  function SampleNextArrow(props: any) {
    const { className, onClick } = props;
    return (
      <div className={`${className} ${styles.arrow}`} onClick={onClick}>
        <img src={getJdiOSFile('mubla1/134168571329041947.png')} alt="" style={{ transform: 'rotate(180deg)' }} />
      </div>
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, onClick } = props;
    return (
      <div className={`${className} ${styles.arrow}`} onClick={onClick}>
        <img src={getJdiOSFile('mubla1/134168571329041947.png')} alt="" />
      </div>
    );
  }

  const jump = (item: OpenDirOption) => {
    openDir(item);
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <div className={styles.bannerContainer}>
      {imgs?.large && (
        <Slider
          nextArrow={<SampleNextArrow />}
          prevArrow={<SamplePrevArrow />}
          autoplay={true}
          className={styles.bannerLargeImage}
          infinite={true}
          dots={true}
        >
          {imgs.large?.map((item, index) => {
            return (
              <Image
                key={index}
                src={item.imgUrl}
                onClick={() => {
                  customClickReportSimple('HomePage_BanneLargeClk', item)
                  jump(item);
                }}
                preview={false}
              ></Image>
            );
          })}
        </Slider>
      )}

      <div className={styles.bannerList}>
        {imgs?.small?.map((item, index) => {
          return (
            <div key={index} className={`${styles.bannerItem} ${styles.searchProductItem}`}>
              <Image
                src={item.imgUrl}
                preview={false}
                onClick={() => {
                  customClickReportSimple('HomePage_BanneSmallClk', item);
                  jump(item);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyComponent;
