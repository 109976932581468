// 定义 CSS 变量的类型
type CssVarValue = string | number;

interface CssVars {
  [key: string]: CssVarValue;
}

// 定义设置全局 CSS 变量的函数
export function setGlobalCssVars(vars: CssVars): void {
  requestAnimationFrame(() => {
    const root = document.documentElement;
    Object.entries(vars).forEach(([key, value]) => {
      root.style.setProperty(`--${key}`, value.toString());
    });
  });
}

// 定义主题的类型
export interface Theme {
  [key: string]: CssVars;
}

// 定义一个主题管理类
export class ThemeManager {
  private currentTheme: string;
  private themes: Theme;

  constructor(initialTheme: string, themes: Theme) {
    this.currentTheme = initialTheme;
    this.themes = themes;
    this.applyTheme(initialTheme);
  }

  public getTheme(): string {
    return this.currentTheme;
  }

  public setTheme(themeName: string): void {
    if (this.themes[themeName]) {
      this.currentTheme = themeName;
      this.applyTheme(themeName);
    } else {
      console.warn(`Theme "${themeName}" not found.`);
    }
  }

  private applyTheme(themeName: string): void {
    const themeVars = this.themes[themeName];
    if (themeVars) {
      setGlobalCssVars(themeVars);
    }
  }

  public addTheme(themeName: string, themeVars: CssVars): void {
    this.themes[themeName] = themeVars;
  }
}

export enum ThemeTypes {
  yellow = 'yellow',
  blue = 'blue',
  red = 'red',
  dark = 'dark',
}