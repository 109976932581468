import React, { useCallback, useEffect, useState, useRef } from 'react';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import InputNumber from 'rc-input-number';
import { formatMoney, cnyMoney } from '@/util/index';
import { message, Affix, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import cookies from 'react-cookies';

import { useRecoilState, useSetRecoilState } from '../../hooks';
import { updateProductNum, checkProduct, getRemainStock } from '@/api/newApi/carts';
import { getImageDomain } from '@/pages/Carts/utils';
import { formatCartInfo } from '@/util/formatServiceStuff';
import InputNumberPanel from '@/components/InputNumberPanel';
import { toPercent } from '@/util/tools';
import Des from '@/components/Atomics/Des/des';
import Price from '@/components/Atomics/Prices/price';
import Tag from '@/components/Atomics/Tags/tags';
import { cx } from '@/util/tools';

import styles from '../../index.module.scss';
import goodsStyles from './index.module.scss';
import ServiceStuff from '@/components/ServiceStuff';
import EditMaterialCode from '../EditMaterialCode';
let flag: any = null; // inputnumber使用，暂存计时器

const Goods = () => {
  const { t } = useTranslation();
  const setCurrent = useSetRecoilState('current');
  const [skuList, setSkuList] = useRecoilState('skuList');
  const [selected, setSelected] = useRecoilState('selected');
  const [selectedSkuList, setSelectedSkuList] = useRecoilState('selectedSkuList');
  const setTotalInfos = useSetRecoilState('totalInfos');
  const [skuTempList, setSkuTempList] = useState([]); //选中的SKU
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0'); // 当前站点是否本土
  const materialCodeRef = useRef([]);
  //是否是IE白名单
  const clientOpenWindow: any = useSelector(state => {
    return state?.User?.data[0]?.clientOpenWindow;
  });

  // 获取用户操作权限
  const userLimit: any = useSelector((state: any) => {
    return state?.UserLimit;
  });

  const handleNums = useCallback(
    (num, skuId) => {
      if (!num) {
        return;
      }
      log('jdisp', 'Cart_add');
      log('jdisp', 'Cart_remove');
      updateProductNum({
        sku: skuId,
        num,
      }).then(res => {
        if (res.success) {
          setSkuList(res?.value?.cartWares);
          setTotalInfos({
            totalCheckCount: res?.value?.totalCheckCount || 0,
            totalCheckKindCount: res?.value?.totalCheckKindCount || 0,
            totalCount: res?.value?.totalCount || 0,
            totalKindCount: res?.value?.totalKindCount || 0,
            totalPrice: res?.value?.totalPriceCurrencies?.showCurrency?.salePrice || 0,
            totalPriceCurrencies: res?.value?.totalPriceCurrencies,
          });
          setSelectedSkuList(res?.value?.cartWares?.filter((item: any) => item.checked).map((item: any) => item));
        } else {
          message.error(res.msg);
        }
      });
    },
    [skuList],
  );
  /**
   * 获得剩余库存
   * @param number
   * @param sku
   */
  const queryRemainStock = (number: any, sku: any) => {
    getRemainStock({ mkuId: sku, reqNum: number }).then((res: any) => {
      console.log('res-->', res);
    });
  };

  const handleChangeNums = (item: any, number: any, index: any) => {
    // 存储计步器数值，用于展示
    const tempSkuList = [...skuList];
    tempSkuList[index] = { ...item, skuNum: number };
    setSkuTempList(tempSkuList);

    // 延迟调用后端接口
    if (flag) {
      clearTimeout(flag);
      flag = null;
    }
    flag = setTimeout(() => {
      handleNums(number, item?.sku);
      // queryRemainStock(number,item?.sku);
    }, 600);
  };
  useEffect(() => {
    setSkuTempList(skuList);
    console.log('skuList----liyi_skdksld', skuList);
  }, [skuList]);

  const handleToggle = useCallback(
    (event, skuId) => {
      const { target } = event;
      const { checked } = target;
      const skus = skuId.split(',');
      const multiple = skus.length > 1;
      const copy = selected.map(item => item);
      if (checked) {
        if (multiple) {
          setSelected(skus);
        } else {
          setSelected(copy.concat(skus));
        }
      } else {
        if (multiple) {
          setSelected([]);
          setSelectedSkuList([]);
        } else {
          copy.splice(selected.indexOf(skus[0]), 1);
          setSelected(copy);
        }
      }

      checkProduct({
        skus: skus,
        checked: checked,
      }).then(res => {
        if (res?.success) {
          setTotalInfos({
            totalCheckCount: res?.value?.totalCheckCount || 0,
            totalCheckKindCount: res?.value?.totalCheckKindCount || 0,
            totalCount: res?.value?.totalCount || 0,
            totalKindCount: res?.value?.totalKindCount || 0,
            totalPrice: res?.value?.totalPriceCurrencies?.showCurrency?.salePrice || 0,
            totalPriceCurrencies: res?.value?.totalPriceCurrencies,
          });
          setSelectedSkuList(res?.value?.cartWares?.filter((item: any) => item.checked).map((item: any) => item));
        }
      });
    },
    [selected],
  );
  // 获取最小库存
  const getMinStockNum = (item: any, isDisableProduct: boolean) => {
    // console.log("获取最小库存--->",item);
    // 如果商品不可以用
    if (isDisableProduct) {
      return 99999;
    } else {
      //有库存
      if (item?.remainNum > 0) {
        //选中数量大于库存值
        if (item?.skuNum > item?.remainNum) {
          return item?.remainNum || 1;
        } else {
          return item?.lowestBuy || 1;
        }
      } else {
        // 无库存
        return item?.lowestBuy || 1;
      }
    }
    // return isDisableProduct ? 99999 : item?.lowestBuy;
  };
  //获得最大库存
  const getMaxStockNum = (item: any, isDisableProduct: boolean) => {
    // console.log(item?.remainNum);
    if (isDisableProduct) {
      return 0;
    } else {
      //有库存
      if (item?.remainNum > 0) {
        return item?.remainNum;
      } else {
        return 99999;
      }
    }
    // return isDisableProduct ? 0 : 99999;
  };
  return (
    <div className={styles.goods}>
      <div className={goodsStyles.cartHeader}>
        <div className={goodsStyles.headerContent}>
          <div className={goodsStyles.headerTitle}>{TLT('购物车')}</div>
          <Affix offsetTop={0}>
            <div className={goodsStyles.headerColumns}>
              <div className={goodsStyles.selectAllColor}></div>
              <span className={goodsStyles.columnProduct}>{TLT(`商品`)}</span>
              <span className={goodsStyles.columnPrice}>{TLT(`价格`)}</span>
              <span className={goodsStyles.columnQuantity}>{TLT(`数量`)}</span>
              <span className={goodsStyles.columnSubtotal}>{TLT(`小计`)}</span>
              <span className={goodsStyles.columnOperation}>{TLT(`操作`)}</span>
            </div>
          </Affix>
        </div>
      </div>

      <div className={styles.content}>
        {skuTempList?.map((item, index) => {
          const gifts = item?.gifts;
          let giftsList = [];
          if (gifts !== undefined) {
            const temp = Object.keys(gifts)?.map(item => ({ code: item, info: gifts[item] }));
            giftsList = formatCartInfo(temp);
          }
          const isDisableProduct = item?.saleState?.saleState === 0;
          return (
            <div
              key={item.id}
              className={`${styles.rowContainter} ${selected?.indexOf(item.sku) > -1 ? styles.active : ''}`}
            >
              <div className={`${styles.row} ${isDisableProduct ? styles.disableRow : ''}`}>
                <div className={styles.check}>
                  {isDisableProduct ? (
                    <i></i>
                  ) : (
                    <Checkbox
                      disabled={!item?.inPool || item?.remainNum === 0}
                      checked={selected?.indexOf(item.sku) > -1}
                      data-track={JSON.stringify({
                        eventId: 'Cart_CheckboxSelect',
                      })}
                      onChange={e => handleToggle(e, item.sku)}
                    ></Checkbox>
                  )}
                </div>
                <div className={`${styles.good} ${giftsList?.length > 0 ? styles.mrt10 : ''}`}>
                  <div className={styles.main}>
                    <div className={styles.skuImg}>
                      <LazyLoad>
                        <div className={styles.skuImgBox} style={{ backgroundImage: `url("${item.skuImg}")` }} />
                      </LazyLoad>
                      {item?.remainNum === 0 && (
                        <div className={styles.stock}>
                          <span>
                            <em style={{ width: '80%' }}>{TLT(`无货`)}</em>
                          </span>
                        </div>
                      )}
                    </div>

                    <div className={styles.detail}>
                      <div
                        data-track={JSON.stringify({
                          eventId: 'Cart_ProductTitle',
                        })}
                        className={cx(styles.title, 'underText')}
                        onClick={() => {
                          window._openPage(`/sku/${item.sku}`, clientOpenWindow ? '_self' : '_blank');
                        }}
                      >
                        {item?.skuName}
                      </div>

                      <div className={styles.dess}>
                        {Boolean(item.saleAttributes?.filter(item => item.valLangName !== '').length) && (
                          <Des
                            label={TLT(`属性`)}
                            copy={false}
                            text={item.saleAttributes
                              ?.filter(valLangName => valLangName !== '')
                              .map(item => item.valLangName)
                              .join(' - ')}
                          ></Des>
                        )}

                        <Des label={TLT(`商品编码`)} text={item.sku}></Des>
                        {item.unit && <Des label={TLT(`单位`)} copy={false} text={item.unit}></Des>}
                        {!isNaN(item?.showCurrency?.valueAddedTaxRate) && (
                          <Des
                            copy={false}
                            label={TLT(`税率`)}
                            text={toPercent(item?.showCurrency?.valueAddedTaxRate)}
                          ></Des>
                        )}
                        {item?.sourceCountryCode === 'CN' && <Tag type="cross" />}
                      </div>
                    </div>
                  </div>
                  {giftsList?.length > 0 && (
                    <div className={styles.gift}>
                      <ServiceStuff data={giftsList} />
                    </div>
                  )}
                  {isDisableProduct && <div className={styles.disableProduct}>{TLT(`该商品不可售`)}</div>}
                </div>
                {/* <div title={item.model} className={styles.specification}>{item.model}</div> */}

                {/* 价格 */}
                {/* 入池才展示价格 */}
                {/* 本土跨境样式两者是否统一 */}
                {item?.inPool ? (
                  <div className={styles.price}>
                    <Price
                      type={4}
                      salePrice={cnyMoney(item?.showCurrency?.salePrice, false)}
                      price={cnyMoney(item?.showCurrency?.price, false)}
                      symbol={item?.showCurrency?.symbol}
                    />
                  </div>
                ) : (
                  <div className={styles.price}></div>
                )}

                {/* 数量 */}
                <div
                  className={
                    item.moq && item.moq > item.skuNum ? [styles.amount, styles.redmount].join(' ') : styles.amount
                  }
                >
                  <div className={styles.priceInput}>
                    <InputNumberPanel
                      cusStyles={{
                        height: '33px',
                        borderRadius: '4px',
                        fontSize: '12px',
                      }}
                      trackInfo={{
                        increase: 'Cart_IncreaseQty',
                        decrease: 'Cart_DecreaseQty',
                        input: 'Cart_ModifyQty'
                      }}
                      min={getMinStockNum(item, isDisableProduct)}
                      max={getMaxStockNum(item, isDisableProduct)}
                      value={item?.skuNum}
                      stock={item?.remainNum}
                      disabled={isDisableProduct || !item?.inPool || item?.remainNum === 0}
                      changeCallBack={(params: any) => {
                        console.log('------------');
                        // 查看更多抽屉 埋点
                        if (!isDisableProduct) {
                          handleChangeNums(item, params, index);
                        }
                      }}
                    ></InputNumberPanel>
                    <div className={styles.moqtip}>{`${TLT(`{{tag1}}{{tag2}}起购`, {
                      tag1: item?.moq,
                      tag2: item?.unit,
                    })}`}</div>
                  </div>
                </div>
                {/* 需跟产品对照是否保持一致 */}
                {/* 小计 */}
                <div className={styles.total}>
                  {item?.inPool && (
                    <Price
                      type={3}
                      salePrice={cnyMoney(item?.notesCurrencies?.showCurrency?.salePrice, false)}
                      price={cnyMoney(item?.notesCurrencies?.showCurrency?.price, false)}
                      symbol={item?.notesCurrencies?.showCurrency?.symbol}
                    />
                  )}
                </div>
                <div className={styles.operate}>
                  <span
                    className={cx(styles.operateBtn, 'underText')}
                    data-track={JSON.stringify({
                      eventId: 'Cart_DeleteSingle',
                    })}
                    onClick={() => {
                      setCurrent({
                        method: 'delete',
                        list: [item],
                      });
                    }}
                  >
                    {TLT(`删除`)}
                  </span>

                  {!item?.mkuMaterial && userLimit?.limitData?.enableMaterial == 1 && (
                    <span
                      className={cx(styles.operateBtn, 'underText')}
                      data-track={JSON.stringify({
                        eventId: 'Cart_AddMaterial',
                      })}
                      onClick={() => {
                        materialCodeRef.current[item.id]?.addMaterialCode();
                      }}
                    >
                      {`${TLT(`填写物料信息	`)}`}
                    </span>
                  )}
                </div>
              </div>
              {/* 物料编码 */}
              {userLimit?.limitData?.enableMaterial == 1 && (
                <EditMaterialCode
                  ref={e => (materialCodeRef.current[item.id] = e)}
                  key={item?.sku}
                  data={item}
                  hasMkuMaterial={item?.mkuMaterial}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Goods;
