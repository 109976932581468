import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Steps } from 'antd';
import styles from './../index.module.scss';
import moment from 'moment';
import { orderDetail } from '@/api/newApi/orderdetail';

interface IAppProps {
  orderDetails: any;
}
const OrderProgress: React.FC<IAppProps> = (props: any) => {
  const { t, i18n } = useTranslation();
  // 订单状态 (0:已提交、1:待运营确认、2：待客户确认，3:审核中，4已发货、5:已完成、99:已取消)

  const { orderDetails } = props;
  const orderRes = orderDetails?.orderRes;
  const needApprove = orderDetails?.needApprove;
  const orderStatus = orderRes?.orderStatus;
  const statusNode = orderDetails?.statusNode;

  // 格式化订单在不同状态下的数据结构
  const progressInfo = [
    {
      title: i18n.language == 'zh' ? '已提交' : 'Submitted', //TLT(`已提交`), // 0:已提交
      icon: 'icon_submit',
      isShow: true,
      isGreen: orderStatus === 99 ? false : true,
      isShowProcess: true,
      isGreenProcess: orderStatus !== 99 && orderStatus >= 1 ? true : false,
      time: orderRes?.orderTime,
    },
    {
      title: i18n.language == 'zh' ? '处理中' : 'Processing', //TLT(`处理中`),
      icon: 'icon_processing', // 1:待运营确认
      isShow: true,
      isGreen: orderStatus !== 99 && orderStatus >= 1 ? true : false,
      isShowProcess: true,
      isGreenProcess: orderStatus !== 99 && orderStatus > 1 ? true : false,
      time: orderRes?.operateTime,
    },
    {
      title: i18n.language == 'zh' ? '待确认' : 'To be Confirmed', //TLT(`待确认`), // 2：待客户确认
      icon: 'icon_confirmed',
      isShow: true,
      isGreen: orderStatus !== 99 && orderStatus >= 2 ? true : false,
      isShowProcess: true,
      isGreenProcess: orderStatus !== 99 && orderStatus > 2 ? true : false,
      time: orderRes?.checkTime,
    },
    {
      title: i18n.language == 'zh' ? '审批中' : 'Pending Approval', //TLT(`审批中`), // 3:审核中
      icon: 'icon_pending',
      isShow: orderStatus === 99 || !needApprove ? false : true,
      isGreen: orderStatus !== 99 && orderStatus >= 3 ? true : false,
      isShowProcess: true,
      isGreenProcess: orderStatus !== 99 && orderStatus > 3 ? true : false,
      time: orderRes?.approvalTime,
    },
    {
      title: i18n.language == 'zh' ? '发货中' : 'Shipping in Progress', //TLT(`发货中`), // 4已发货
      icon: 'icon_shipping',
      isShow: orderStatus === 99 ? false : true,
      isGreen: orderStatus !== 99 && orderStatus >= 4 ? true : false,
      isShowProcess: true,
      isGreenProcess: orderStatus !== 99 && orderStatus > 4 ? true : false,
      time: orderRes?.deliveryTime,
    },
    {
      title: i18n.language == 'zh' ? '已完成' : 'Completed', //TLT(`已完成`), // 5:已完成
      icon: 'icon_completed',
      isShow: orderStatus === 99 ? false : true,
      isGreen: orderStatus !== 99 && orderStatus >= 5 ? true : false,
      isShowProcess: false,
      time: orderRes?.overTime,
    },
    {
      title: i18n.language == 'zh' ? '已取消' : 'Cancelled', // TLT(`已取消`),
      icon: 'icon_cancel',
      isShow: orderStatus === 99 ? true : false,
      isGreen: false,
      isShowProcess: false, // 99:已取消
      time: orderRes?.cancelTime,
    },
  ];

  //获取图标
  const getIcon = orderItem => {
    const code = orderItem.nodeCode;
    let iconClass = 'icon_submit';
    if (code === 1) {
      iconClass = 'icon_processing';
    } else if (code === 2) {
      iconClass = 'icon_confirmed';
    } else if (code === 4) {
      iconClass = 'icon_shipping';
    } else if (code === 3) {
      iconClass = 'icon_pending';
    } else if (code === 5) {
      iconClass = 'icon_completed';
    } else if (code === 90) {
      iconClass = 'icon_pending';
    } else if (code === 99) {
      iconClass = 'icon_cancel';
    }

    return iconClass;
  };

  return (
    <div className={styles.orderProgress}>
      <Steps
        current={statusNode?.findLastIndex(item => item.isGreen) + 1}
        labelPlacement="vertical"
        items={statusNode?.map(node => {
          return {
            title: node.title,
            description: node.isGreen ? moment(node.time).format('YYYY-MM-DD HH:mm:ss') : '',
            status: node.isGreen ? 'finish' : 'wait'
          };
        }) || []}
      />
    </div>
  );
};

export default OrderProgress;
