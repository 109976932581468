/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-08-06 10:42:08
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-10-24 18:42:35
 * @FilePath: /isp_International/src/pages/CartsNew/components/EditMaterialCode/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Col, Form, Input, message, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from '../../index.module.scss';
import { saveMaterialCode } from '@/api/newApi/materialcode';
import { useRecoilState, useSetRecoilState } from '../../hooks';
import { getCartList } from '@/api/newApi/carts';
import Des from '@/components/Atomics/Des/des';

const EditMaterialCode = forwardRef((props: any, ref) => {
  const { hasMkuMaterial, data } = props;
  const { t, i18n } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [isHasMkuMaterial, setIsHasMkuMaterial] = useState(hasMkuMaterial);
  const [form] = Form.useForm();
  const [skuList, setSkuList] = useRecoilState('skuList');
  const setSelected = useSetRecoilState('selected');
  const setSelectedSkuList = useSetRecoilState('selectedSkuList');
  const setTotalInfos = useSetRecoilState('totalInfos');
  const [isLoading, setLoading] = useRecoilState('loading');

  useEffect(() => {
    setIsHasMkuMaterial(hasMkuMaterial);
  }, [hasMkuMaterial]);

  const refreshList = useCallback(() => {
    setLoading(true);
    getCartList().then(res => {
      if (res?.success) {
        setSkuList(res?.value?.cartWares);
        setTotalInfos({
          totalCheckCount: res?.value?.totalCheckCount || 0,
          totalCheckKindCount: res?.value?.totalCheckKindCount || 0,
          totalCount: res?.value?.totalCount || 0,
          totalKindCount: res?.value?.totalKindCount || 0,
          totalPrice: res?.value?.totalPriceCurrencies?.showCurrency?.salePrice || 0,
          totalPriceCurrencies: res?.value?.totalPriceCurrencies,
        });
        setSelectedSkuList(res?.value?.cartWares?.filter((item: any) => item.checked).map((item: any) => item));
        setSelected(res?.value?.cartWares?.filter((item: any) => item.checked).map((item: any) => item.sku));
        setLoading(false);
      }
    });
  }, []);

  //   useEffect(()=>{
  //     console.log(skuList, 'skuList');
  //   }, [skuList])

  /**
   * 表单完成
   */
  const onFinish = async values => {
    if (!values?.materialId) {
      message.warning(TLT(`请填写物料编码`));
      return;
    }
    if (!values?.materialName) {
      message.warning(TLT(`请填写物料名称`));
      return;
    }
    values.mkuId = +data?.sku;
    const resData = await saveMaterialCode(values);
    if (resData?.success) {
      refreshList();
      setIsEdit(false);
    } else {
      message.error(resData?.msg);
    }
  };

  const addMaterialCode = () => {
    setIsEdit(true);
    form?.setFieldsValue({
      materialId: data?.mkuMaterial?.materialId,
      materialName: data?.mkuMaterial?.materialName,
    });
  };
  useImperativeHandle(ref, () => ({
    addMaterialCode,
  }));

  return (
    <>
      {isEdit || isHasMkuMaterial ? (
        <div className={styles.materialCodeContainer}>
          <div className={styles.editMaterialCode}>
            {isEdit ? (
              <ConfigProvider
                theme={{
                  components: {
                    Form: {
                      labelColor: 'rgba(7, 14, 50, 0.5)',
                    },
                  },
                }}
              >
                <Form
                  style={{ width: '100%' }}
                  form={form}
                  layout="vertical"
                  wrapperCol={{ span: 22 }}
                  onFinish={onFinish}
                >
                  <Form.Item label={TLT(`物料编码`)} name="materialId">
                    <Input />
                  </Form.Item>
                  <Form.Item label={TLT(`物料名称`)} name="materialName">
                    <Input />
                  </Form.Item>
                </Form>
              </ConfigProvider>
            ) : isHasMkuMaterial && !isLoading ? (
              <div className={styles.editMaterialCodeInfo}>
                <div>
                  <Des label={TLT(`物料编码`)} text={data?.mkuMaterial?.materialId}></Des>
                </div>
                <div style={{ marginTop: 7 }}>
                  <Des
                    columnFlex={true}
                    label={TLT(`物料名称`)}
                    text={data?.mkuMaterial?.materialName}
                    copy={false}
                  ></Des>
                </div>
              </div>
            ) : null}
          </div>

          <div className={styles.editMaterialBtns}>
            {isEdit && (
              <>
                <Button type="primary" onClick={() => form?.submit()}>
                  {TLT(`保存`)}
                </Button>
                <Button
                  onClick={() => {
                    setIsEdit(false);
                  }}
                >
                  {TLT(`取消`)}
                </Button>
              </>
            )}
          </div>

          {!isEdit && isHasMkuMaterial && (
            <div className={styles.modifyBtn}>
              <span
                data-track={JSON.stringify({
                  eventId: 'Cart_EditMaterial',
                })}
                className={styles.modifyBtnEdit}
                onClick={addMaterialCode}
              >{`${TLT(`修改物料信息`)}`}</span>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
});

export default EditMaterialCode;
