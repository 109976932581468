import React, { useEffect, useState } from 'react';
import { Affix, Button, Card, Checkbox, Col, message, Modal, Pagination, Row, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import BreadCrumb from '@/components/BreadCrumb';
import UserCenterMenu from '@/components/UserCenterMenu';
import SimplePagination from '@/components/common/SimplePagination';

import Empty from '@/components/common/Empty';
import SearchForm, { UserType } from './components/SearchForm';
import OrderTitle from './components/OrderTitle';
import OrderItem from './components/OrderItem';
import ExportOrder from './components/ExportOrder';
import Tab from './components/Tab';
import cookies from 'react-cookies';
import styles from './index.module.scss';
import DeliveryGreen from '@/assets/images/orderList/delivery_green.png';
import DeliveryOrange from '@/assets/images/orderList/delivery_orange.png';
import DeliveryBlack from '@/assets/images/orderList/delivery_black.png';
import ProductStatus from '@/pages/OrderList/components/ProductStatus';
import Title from '@/components/Title';
import { customClickReportSimple } from '@/dataPort';

import { useTranslation } from 'react-i18next';
import { flatten } from 'lodash';
import { goTop } from '@/util';
import PaginationJD from '@/components/PaginationJD';

import {
  ApproveModal,
  batchConfirm,
  handleConfirmOrder,
  orderStatus,
  OrderStatus,
  OrderStatusTrack,
  print,
  RejectModal,
  removeOrder,
} from '@/components/Order';

import {
  exportOrderRequest,
  getOrderCount,
  getOrderList,
  getOrderSettingMap,
  orderPrintApi,
  queryDeliveryApi,
  querySortByDateApi,
} from '@/api/newApi/order';
import { formatOrderList } from '@/util/formatServiceStuff';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dispatchAnnounceData } from '@/redux/action';
import moment from 'moment';
import { getAuthority } from '@/api/newApi/header';

const OrderList: React.FC<{ isAudit?: boolean; isDelete?: boolean; isVsp?: boolean; isBatch?: boolean }> = ({
  isAudit = false, //订单审批
  isDelete = false, //订单回收站
  isVsp = false, //慧采列表
}) => {
  const { t, i18n } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const batchId = queryParams.get('batchId') || '';
  const statusParam = parseInt(queryParams.get('status') || '0');
  const urlStatus: OrderStatus = (OrderStatus as any)[Object.keys(OrderStatus)[statusParam]];
  const [status, setStatus] = useState<OrderStatus>(urlStatus);

  const [searchParams, setSearchParams] = useState<any>({});
  const [interfaceParams, setInterfaceParams] = useState<any>({});
  const [paymentTypes, setPaymentTypes] = useState<Array<object>>([]);
  const [isOrderConfig, setIsOrderConfig] = useState(false); //订单设置弹窗
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(30);
  const [orders, setOrders] = useState<Array<object>>([]);
  const [orders1, setOrders1] = useState<Array<object>>([]);
  const [total, setTotal] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [orderCount, setOrderCount] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isShowDeliveryTime, setSetIsShowDeliveryTime] = useState<boolean>(false); // 到货时间展示按钮

  const [approveModalVisible, setApproveModalVisible] = useState<boolean>(false);
  const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(true);
  const [selectedOrderIds, setSelectedOrderIds] = useState<Array<number>>([]);
  const [selectedPidOrdeIds, setSelectedPidOrdeIds] = useState<Array<number>>([]); //选中的父单号
  const [selectedOrders, setSelectedOrders] = useState<Array<{ orderNo: string; processId: string; pin: string }>>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [touchSelectedId, setTouchSelectedId] = useState<number>(''); //hover的订单id
  const [trackInfoList, setTrackInfoList] = useState<Array<object>>([]);
  const [productLoading, setProductLoading] = useState(true);

  //  父订单半选状态
  const [parentIndeterminate, setParentIndeterminate] = useState<Array<number>>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [confirmOrder, setConfirmOrder] = useState([]); //显示确定订单按钮的订单数组

  const [showOrderDownloadSetting, setShowOrderDownloadSetting] = useState(false);
  const [exportSetting, setExportSetting] = useState(null); //初始化导出配置
  const [orderConfigList, setOrderConfigList] = useState(null); //初始化导出配置
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0'); // 当前站点是否本土

  const history = useHistory();

  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  // 新权限列表
  const promiseMap: any = useSelector(state => {
    return state?.User?.publicPromise;
  });
  const userType: UserType = currentUser?.userType ?? UserType.unknonw;

  const userPin: string = currentUser.pin;
  const selectedMyOrderIds = orders
    ?.filter((o: any) => o.pin === userPin && selectedOrderIds.includes(o.orderNo))
    .map((o: any) => o.orderNo);

  const changeLogin = () => {
    // getLogin({ pin: 'ISP研发账号测试4' }).then((res: any) => {});
  };
  const getData = () => {
    let orderState;
    switch (status) {
      case OrderStatus.committed:
        orderState = '1';
        break;
      case OrderStatus.waitingConfirm:
        orderState = '2';
        break;
      case OrderStatus.waitingApprove:
        orderState = '3';
        break;
      case OrderStatus.shipping:
        orderState = '4';
        break;
      case OrderStatus.complete:
        orderState = '5';
        break;
      case OrderStatus.cancel:
        orderState = '99';
        break;
      case OrderStatus.deliverySubmitted:
        orderState = '90';
        break;
      case OrderStatus.shipped:
        orderState = '80';
        break;
      case OrderStatus.confirming:
        orderState = '20';
        break;
      case OrderStatus.confirmReceipt:
        orderState = '95';
        break;
      default:
        orderState = undefined;
        break;
    }
    const params = {
      pageNo: page,
      pageSize,
      orderStatus: orderState,
      ...searchParams,
    };
    setInterfaceParams(params);
    setProductLoading(true);
    getOrderList(params)
      .then((res: any) => {
        const value = res?.value ?? {};
        let data = value?.list ?? [];
        data = formatOrderList(data);
        setOrders(data);
        setTotal(value?.totalRows ?? 0);
        setTotalPage(value?.totalPages ?? 0);
      })
      .finally(() => {
        setProductLoading(false);
      });
  };

  const updateOrderCount = () => {
    getOrderCount().then((res: any) => {
      setOrderCount(res?.value ?? {});
    });
  };

  //订单导出
  const exportOrder = () => {
    const paramer =
      orderConfigList?.length &&
      orderConfigList.map(ele => ({
        orderInfo: ele.orderInfo,
        orderInfoName: ele.orderInfoName,
        orderInfoKey: ele.orderInfoKey,
      }));
    if (!selectedOrderIds.length) return message.error(TLT(`请至少选择一个订单`));
    exportOrderRequest({
      orderId: selectedOrderIds,
      orderExportConfig: paramer,
    }).then(res => {
      if (res.success && res.value && res.value.fileUrl) {
        location.href = res?.value?.fileUrl;
      }
    });
  };

  //excel导出设置获取
  const getSetMap = async () => {
    try {
      const res: any = await getOrderSettingMap();

      if (res?.success) {
        const value = res?.value?.result;
        setOrderConfigList(value);
        setExportSetting(value);
      } else {
        message.error('获取配置失败');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setExportOrder = data => {
    setOrderConfigList(data);
    getSetMap();
  };

  //订单打印
  const printOrderFun = selectedOrderIds => {
    if (!selectedOrderIds.length) return message.error(TLT(`请至少选择一个订单`));
    if (selectedOrderIds.length > 1) return message.error(TLT(`订单打印仅支持单个订单`));
    const paramer =
      orderConfigList?.length &&
      orderConfigList.map(ele => ({
        orderInfo: ele.orderInfo,
        orderInfoName: ele.orderInfoName,
        orderInfoKey: ele.orderInfoKey,
      }));

    orderPrintApi({
      orderId: selectedOrderIds,
      orderExportConfig: paramer,
    }).then(res => {
      if (res.success && res.value && res.value.fileUrl) {
        location.href = res?.value?.fileUrl;
      }
    });
  };

  const clearSelect = () => {
    setSelectedOrderIds([]);
    setSelectAll(false);
    setIndeterminate(false);
  };

  useEffect(() => {
    getSetMap();
    getUserLimit();
  }, []);

  useEffect(() => {
    if (refresh) updateOrderCount();
  }, [refresh]);

  useEffect(() => {
    changeLogin();
    if (refresh) {
      clearSelect();
      getData();
      setRefresh(false);
    }
  }, [refresh]);

  //获取用户权限
  const getUserLimit = async () => {
    const resData = await getAuthority();
    if (resData?.success) {
      setSetIsShowDeliveryTime(resData?.value?.showGroupByExpDeliverDate == 1);
    }
  };

  // 按到货时间排序展示订单列表
  const getSortByDeliveryDate = async () => {
    const resData = await querySortByDateApi({
      pageNo: page,
      pageSize,
      ...searchParams,
    });

    if (resData?.success) {
      setIsModalOpen(true);
      setOrders1(resData?.value);
    } else {
      message.error(resData?.message);
    }
  };

  const onPageChange = page => {
    customClickReportSimple('OrderList_ViewPreviousTopPage')
    setPage(page);
    setRefresh(true);
  };
  const nextPage = () => {
    if (page < totalPage) {
      setPage(page + 1);
      setRefresh(true);
    }
  };
  const reset = () => {
    setPage(1);
    setRefresh(true);
  };

  const onCheckOrder = (e: CheckboxChangeEvent, order: any) => {
    let _ids = [];
    const indeterminates = parentIndeterminate.filter(id => id != order.parentOrderId);
    //当前操作的是没有子单的订单或者是有拆单订单的子单
    const isChildOrderOrNoChildOrder = order.childOrderNum <= 0 || order.parentOrderId;
    if (isChildOrderOrNoChildOrder) {
      _ids = selectedOrderIds.filter(id => id != order.orderNo);
      if (e.target.checked) {
        _ids.push(order.orderNo);
      }
      //操作的是子单
      if (order.parentOrderId) {
        const pids = selectedPidOrdeIds.filter(id => id != order.parentOrderId); //选中的父单
        const siblingOrderIds =
          orders.filter(o => o.orderNo == order.parentOrderId)[0]?.orderResList?.map(o => o.orderNo) || []; //选中的兄弟ids
        const allchecked = siblingOrderIds.every(id => _ids.includes(id)); //当前操作的兄弟ids是否都被选中
        const sameIds = _ids.filter(element => siblingOrderIds.includes(element)); //全部选中的ids包含当前父元素下的子元素ids
        if (sameIds.length > 0 && !allchecked) {
          indeterminates.push(order.parentOrderId);
        }

        if (allchecked) {
          pids.push(order.parentOrderId);
        }
        setSelectedPidOrdeIds(pids);
      }
      setParentIndeterminate(indeterminates);
      setSelectedOrderIds(_ids);
    } else {
      const pids = selectedPidOrdeIds.filter(id => id != order.orderNo);
      const childIds = order?.orderResList.map(o => o.orderNo);
      _ids = selectedOrderIds.filter(id => !childIds.includes(id));
      console.log(childIds);
      if (e.target.checked) {
        pids.push(order.orderNo);
        _ids = _ids.concat(childIds);
      }
      setSelectedPidOrdeIds(pids);
      setSelectedOrderIds(_ids);
    }
    //操作所有订单
    const allIds = flatten(
      orders.map((o: any) => (o.orderResList ? o.orderResList.map((x: any) => x.orderNo) : o.orderNo)),
    );
    setIndeterminate(!!_ids.length && _ids.length < allIds.length);
    setSelectAll(_ids.length === allIds.length);
  };

  const onRemoveOrder = (order: any) => {
    removeOrder(order.orderNo, () =>
      setTimeout(() => {
        reset();
      }, 1000),
    );
  };

  useEffect(() => {
    setTrackInfoList([]);
    if (touchSelectedId) getDeliveryData(touchSelectedId);
  }, [touchSelectedId]);

  /**
   * 判断在当预计配送时间前还是后 0预计XX到达 1今天到达 2已过配送时间
   */
  const getJuddgeTime = (time: string) => {
    const momentToday = moment();
    const startTime = moment(time).startOf('day'); //配送当天开始
    const endTime = moment(time).endOf('day'); //配送当天结束
    return momentToday.isBefore(startTime) ? 0 : momentToday.isAfter(endTime) ? 2 : 1;
  };

  const getDeliveryData = async orderId => {
    const resData = await queryDeliveryApi({
      orderId,
    });
    if (resData?.success) {
      const { value } = resData;
      if (value.trackInfoList.length) {
        value.trackInfoList.forEach((ele, idx) => {
          ele.title = ele.trackContent;
          ele.description = moment(ele.trackMsgTime).format('YYYY-MM-DD HH:mm:ss');
          if (!idx) ele.status = 'finish';
        });
        setTrackInfoList(value.trackInfoList);
      }
    }
  };

  //按钮模块儿
  const buyBatchActions = () => (
    <>
      <Button
        type="text"
        clstag="h|keycount|myorder|11"
        style={{ marginRight: '6px' }}
        data-track={JSON.stringify({
          eventId: 'OrderList_BatchPrint',
        })}
        onClick={() => {
          printOrderFun(selectedOrderIds);
        }}
      >
        {TLT(`订单打印`)}
      </Button>
      <Button
        type="text"
        onClick={() => {
          exportOrder();
          log('jdisp', 'OrderList_Export');
        }}
        data-track={JSON.stringify({
          eventId: 'OrderList_BatchExport',
        })}
        clstag="h|keycount|myorder|10"
        style={{ marginRight: '6px' }}
      >
        {TLT(`批量导出`)}
        {/*{currentUser?.orderExportWhite && (*/}
        {/*  <>*/}
        {/*    <OrderDownloadModal*/}
        {/*      closeSetting={() => setShowOrderDownloadSetting(false)}*/}
        {/*      showSetting={showOrderDownloadSetting}*/}
        {/*    ></OrderDownloadModal>*/}
        {/*  </>*/}
        {/*)}*/}
      </Button>
      <Button
        type="text"
        onClick={() => {
          setIsOrderConfig(true);
          log('jdisp', 'orderSetting');
        }}
        clstag="h|keycount|myorder|10"
        style={{ marginRight: '6px' }}
        data-track={JSON.stringify({
          eventId: 'OrderList_OrderSettings',
        })}
      >
        {TLT(`订单设置`)}
      </Button>

      {status == OrderStatus.shipping && (
        <Button
          type="text"
          clstag="h|keycount|myorder|12"
          style={{ marginRight: '6px' }}
          disabled={selectedOrderIds.length === 0}
          onClick={() => {
            log('jdisp', 'OrderList_ConfirmReceipt');
            batchConfirm(
              selectedOrderIds,
              () => {
                if (status === OrderStatus.all) {
                  setRefresh(true);
                } else {
                  reset();
                }
              },
              t,
            );
          }}
        >
          {TLT(`批量确认收货`)}
        </Button>
      )}
    </>
  );

  const batchActions = () => {
    const selectOrderCount = selectedOrderIds.length;
    const totalMoney = 0;

    return (
      <Row className={styles.batchActions} style={{ alignItems: 'center' }}>
        <Col span="14">
          <Checkbox
            data-track={JSON.stringify({
              eventId: 'OrderList_SelectAll',
            })}
            className={styles.checkAll}
            clstag={isAudit ? 'h|keycount|ordercenter|5' : 'h|keycount|myorder|14'}
            style={{ color: '#333' }}
            indeterminate={indeterminate}
            checked={selectAll}
            onChange={e => {
              const allIds = flatten(
                orders.map((o: any) => (o.orderResList ? o.orderResList.map((x: any) => x.orderNo) : o.orderNo)),
              );

              //父单号
              const pidOrderIds = orders.filter(o => o.childOrderNum > 0).map((o: any) => o.orderNo);
              setSelectedPidOrdeIds(e.target.checked ? pidOrderIds : []);
              setSelectedOrderIds(e.target.checked ? allIds : []);
              setIndeterminate(false);
              setSelectAll(e.target.checked);
            }}
          >
            {TLT(`全选`)}
          </Checkbox>

          {selectOrderCount > 0 && (
            <>
              <span className={styles.selectedTitle}>
                ( {TLT(`已选`)} <span className={[styles.blod].join(' ')}>{selectOrderCount}</span> )
              </span>
            </>
          )}
          {buyBatchActions()}
        </Col>
        <Col span="9">
          <SimplePagination
            page={page}
            total={total}
            totalPage={totalPage}
            pageSize={pageSize}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    );
  };

  const orderListTitle = (
    <header className={styles.orderListHeader}>
      <div className={`${styles.headerRow} ${styles.headerRowBorder}`}>
        <div className={styles.productBox}>
          <span className={styles.productInfo}>{TLT(`商品信息`)}</span>
          <span className={styles.quantity}>{TLT(`数量`)}</span>
        </div>
        <span className={styles.total}>{TLT(`总价`)}</span>
        <span className={styles.action}>{TLT(`操作`)}</span>
      </div>
    </header>
    // <Row className={styles.header}>
    //   <Col className={styles.title1} style={{ paddingLeft: '20px' }}></Col>
    //   <Col
    //     className={styles.title2}
    //     style={{ display: 'flex', alignItems: 'center', paddingLeft: isDelete ? '49px' : '24px' }}
    //   >
    //     {TLT(`商品信息`)}
    //   </Col>
    //   <Col className={[styles.title3, styles.center].join(' ')}>{TLT(`价格/数量`)}</Col>
    //   {isLocalPro && <Col className={[styles.title7, styles.center].join(' ')}>{TLT(`是否报关`)}</Col>}
    //   <Col className={[styles.title4, styles.center].join(' ')}>{TLT(`收货人`)}</Col>
    //   <Col className={[styles.title5, styles.center].join(' ')}>{TLT(`总价`)}</Col>
    //   <Col className={[styles.title6, styles.center].join(' ')}>{TLT(`订单状态`)}</Col>
    //   <Col className={[styles.title7, styles.center].join(' ')}>{TLT(`操作`)}</Col>
    // </Row>
  );

  const orderConfirm = (orderId: any) => {
    handleConfirmOrder(orderId, t, () => {
      reset();
    });
  };

  // 页面列表
  const orderList = orders => {
    return orders.length > 0 ? (
      orders.map((o: any, index: number) =>
        o.childOrderNum > 0 ? (
          <div
            key={index + o.orderNo}
            className={`${styles.productFullBox} ${
              o.orderResList.every(childOrder => selectedOrderIds.includes(childOrder.orderNo))
                ? styles.productFullActive
                : ''
            }`}
          >
            <OrderTitle
              type={'parent'}
              order={o}
              isAudit={isAudit}
              isDelete={isDelete}
              parentIndeterminate={parentIndeterminate.includes(o.orderNo)}
              isVsp={isVsp}
              checked={selectedPidOrdeIds.includes(o.orderNo)}
              onCheckOrder={onCheckOrder}
              onRemoveOrder={() => onRemoveOrder(o)}
            />
            {o.orderResList.map((childOrder: any, childIndex: number) => (
              <div
                key={childOrder.orderNo}
                className={`${styles.productFullMulBox} ${
                  selectedOrderIds.includes(childOrder.orderNo) ? styles.productFullMulBoxActive : ''
                }`}
              >
                <OrderTitle
                  type={'child'}
                  order={childOrder}
                  isAudit={isAudit}
                  isDelete={isDelete}
                  isVsp={isVsp}
                  checked={selectedOrderIds.includes(childOrder.orderNo)}
                  onCheckOrder={onCheckOrder}
                  onRemoveOrder={() => onRemoveOrder(childOrder)}
                  printHandler={orderNo => {
                    printOrderFun([orderNo]);
                  }}
                />
                <ProductStatus order={childOrder} />
                <OrderItem
                  trackInfoList={trackInfoList}
                  order={childOrder}
                  isAudit={isAudit}
                  isDelete={isDelete}
                  isVsp={isVsp}
                  onCancel={() => {
                    if (status === OrderStatus.all) {
                      setRefresh(true);
                    } else {
                      reset();
                    }
                  }}
                  onApprove={() => reset()}
                  onReject={() => reset()}
                  orderConfirm={orderConfirm}
                  hoverCallback={orderId => {
                    setTouchSelectedId(orderId);
                  }}
                />

                {childOrder.waresReqs.length > 2 && (
                  <div
                    className={styles.moreBtn}
                    onClick={() => {
                      //父单中的子单下边的展开收缩按钮操作
                      const newOrders = [...orders];
                      newOrders.forEach(ele => {
                        //选中当前单子的父单
                        if (ele.orderNo == o.orderNo) {
                          ele?.orderResList.forEach(ele1 => {
                            if (ele1.orderNo == childOrder.orderNo) {
                              if (!Object.keys(ele1).includes('expanded')) {
                                ele1.expanded = true;
                              } else {
                                ele1.expanded = !ele1.expanded;
                              }
                            } else {
                              ele1.expanded = false;
                            }
                          });
                        } else {
                          ele.expanded = false;
                        }
                      });
                      setOrders(newOrders);
                    }}
                  >
                    {childOrder.expanded
                      ? TLT(`收起`)
                      : TLT(`查看剩余 XXX 个商品`).replace('XXX', '' + (childOrder.waresReqs.length - 2))}{' '}
                    <i className={!childOrder.expanded ? styles.crumbs_arrow : styles.crumbs_arrow1}></i>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div
            key={index + o.orderNo}
            className={`${styles.productFullBox} ${
              selectedOrderIds.includes(o.orderNo) ? styles.productFullActive : ''
            }`}
          >
            <OrderTitle
              order={o}
              isAudit={isAudit}
              isDelete={isDelete}
              isVsp={isVsp}
              checked={selectedOrderIds.includes(o.orderNo)}
              onCheckOrder={onCheckOrder}
              onRemoveOrder={() => onRemoveOrder(o)}
              printHandler={orderNo => {
                printOrderFun([orderNo]);
              }}
            />
            <ProductStatus order={o} />
            <OrderItem
              trackInfoList={trackInfoList}
              order={o}
              isAudit={isAudit}
              isDelete={isDelete}
              isVsp={isVsp}
              onCancel={() => {
                if (status === OrderStatus.all) {
                  setRefresh(true);
                } else {
                  reset();
                }
              }}
              onApprove={() => reset()}
              onReject={() => reset()}
              orderConfirm={orderConfirm}
              hoverCallback={orderId => {
                setTouchSelectedId(orderId);
              }}
            />
            {o.waresReqs.length > 2 && (
              <div
                onClick={() => {
                  const newOrders = [...orders];
                  newOrders.forEach(ele => {
                    if (ele.orderNo == o.orderNo) {
                      if (!Object.keys(ele).includes('expanded')) {
                        ele.expanded = true;
                      } else {
                        ele.expanded = !ele.expanded;
                      }
                    } else {
                      ele.expanded = false;
                      //其他的订单下有没有展开的子单
                      ele?.orderResList &&
                        ele?.orderResList?.length &&
                        ele.orderResList.forEach(ele1 => {
                          if (ele1?.expanded) {
                            ele1.expanded = false;
                          }
                        });
                    }
                  });
                  setOrders(newOrders);
                }}
                style={{
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '12px',
                  cursor: 'pointer',
                }}
              >
                {o.expanded ? TLT(`收起`) : TLT(`查看剩余 XXX 个商品`).replace('XXX', '' + (o.waresReqs.length - 2))}{' '}
                <i className={!o.expanded ? styles.crumbs_arrow : styles.crumbs_arrow1}></i>
              </div>
            )}
          </div>
        ),
      )
    ) : (
      <Empty />
    );
  };

  //弹框标题图标
  const modalTitleIcon = (order: any) => {
    const expectedDeliveryDate = order.expectedDeliveryDate;
    return (
      <>
        <img className={styles.car} src={order.expectedDeliveryDate == -1 ? DeliveryGreen : DeliveryOrange} />
      </>
    );
  };

  //弹框订单列表时间
  const modalOrderText = (order: any) => {
    const expectedDeliveryDate = order.expectedDeliveryDate;
    return (
      <>
        <span className={styles.tit_tip_text}>
          {expectedDeliveryDate == -1
            ? TLT(`以下订单可确认收货, 请核对线下实际送达情况`)
            : expectedDeliveryDate == -2
            ? TLT(`以下订单暂无送达时间`)
            : getJuddgeTime(expectedDeliveryDate) == 1
            ? TLT(`以下订单预计今日送达，请留意物流信息`)
            : TLT(`以下订单预计XX送达`).replace('XX', moment(expectedDeliveryDate).format('MM-DD'))}
        </span>
      </>
    );
  };

  //到货时间弹框列表
  const orderList1 = orders => {
    return orders && orders.length > 0 ? (
      orders.map((o: any, index: number) => (
        <div
          key={index + o.orderNo}
          className={[styles.borderLeft, styles.borderBottom, styles.borderRight].join(' ')}
          style={{ marginBottom: '18px' }}
        >
          <div className={styles.tit_tip}>
            {modalTitleIcon(o)}
            {modalOrderText(o)}
          </div>
          {o.orderResList.map((childOrder: any, childIndex: number) => (
            <>
              <OrderTitle
                type={'child'}
                order={childOrder}
                isAudit={isAudit}
                isDelete={isDelete}
                isVsp={isVsp}
                checked={selectedOrderIds.includes(childOrder.orderNo)}
                onCheckOrder={onCheckOrder}
                onRemoveOrder={() => onRemoveOrder(childOrder)}
              />
              <OrderItem
                trackInfoList={trackInfoList}
                order={childOrder}
                isAudit={isAudit}
                isDelete={isDelete}
                isVsp={isVsp}
                onCancel={() => {
                  if (status === OrderStatus.all) {
                    setRefresh(true);
                  } else {
                    reset();
                  }
                }}
                onApprove={() => reset()}
                onReject={() => reset()}
                orderConfirm={orderConfirm}
                hoverCallback={orderId => {
                  setTouchSelectedId(orderId);
                }}
              />
            </>
          ))}
        </div>
      ))
    ) : (
      <Empty />
    );
  };

  const crumbData = [
    {
      label: TLT(`订单管理`),
    },
  ];
  const approveModal = (
    <ApproveModal
      orders={selectedOrders}
      visible={approveModalVisible}
      onCancel={() => setApproveModalVisible(false)}
      onOK={() => {
        setApproveModalVisible(false);
        reset();
      }}
    />
  );

  const rejectModal = (
    <RejectModal
      orders={selectedOrders}
      visible={rejectModalVisible}
      onCancel={() => setRejectModalVisible(false)}
      onOK={() => {
        setRejectModalVisible(false);
        reset();
      }}
    />
  );

  return (
    <div className={styles.orderlist}>
      <div className={styles.orderlist_menu}>
        <Affix offsetTop={0}>
          <UserCenterMenu />
        </Affix>
      </div>
      <div className={styles.orderlist_content}>
        <Title title={TLT(`订单管理`)}></Title>
        <div className={styles.content}>
          <div style={{ marginBottom: '10px' }}>
            <Tab
              isAudit={isAudit}
              isDelete={isDelete}
              status={status}
              orderCount={orderCount}
              onOrderTabChange={(activeKey: OrderStatus) => {
                console.log(activeKey, 'activeKey');
                const status = Object.values(OrderStatus).indexOf(activeKey);
                console.log(status);
                if (OrderStatusTrack[status]) {
                  customClickReportSimple(OrderStatusTrack[status]);
                }
                history.replace(`/orderList?status=${status}`);
                setStatus(activeKey as OrderStatus);
                reset();
              }}
            />
            <SearchForm
              isAudit={isAudit}
              isDelete={isDelete}
              paymentTypes={paymentTypes}
              onReset={() => {
                setSearchParams({});
                reset();
              }}
              onSearch={(params: any) => {
                setSearchParams(params);
                reset();
              }}
            />
          </div>
          <Spin spinning={productLoading}>
            <div className={styles.orderList}>
              {batchActions()}
              {orderListTitle}
              {orderList(orders)}
              <div style={{ textAlign: 'right' }}>
                <PaginationJD
                  hideOnSinglePage
                  current={page}
                  total={total}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  onChange={(_page: any) => {
                    customClickReportSimple('OrderList_ViewPreviousBottomPage')
                    setPage(_page);
                    setRefresh(true);
                    goTop(false);
                  }}
                />
              </div>
            </div>
          </Spin>
        </div>
      </div>
      <ExportOrder
        show={isOrderConfig}
        setShow={setIsOrderConfig}
        setOrderConfigList={setExportOrder}
        exportSetting={exportSetting}
        selectedOrderIds={selectedOrderIds}
      />
    </div>
  );
};
export default OrderList;
