import { ThemeTypes } from './themeManage';
import { getJdiOSFile } from '@/util';

export default {
  [ThemeTypes.dark]: {
    'primary-color': '#070E32',
    'primary-color-6': 'rgba(7, 14, 50, 0.6)',
    'global-text-color': '#070E32',
    /**
     * header 部分
     */
    'page-max-width': '1296px',
    'page-min-width': '996px',
    'header-bg': '#02263D',
    'header-box-shadow': '0px 2px 5px rgba(0, 0, 0, 0.04)',
    'header-logo': `url("${getJdiOSFile('mubla1/22607583145767011.png')}")`,
    // text
    'header-text-color': '#FFF',
    'header-icon-color': '#FFF',
    'header-icon-tip-text-color': '#FFF',
    'header-icon-tip-bg': '#E02020',
    'header-text-color-hover': '#FFF', // 待定
    // search
    'header-search-border-color': '#FFF',
    'header-search-icon-bg': '#E1251B',
    'header-search-icon-bg-hover': '#D2170D',
    'header-search-icon-color': '#FFF',
    'header-search-icon-image': `url("${getJdiOSFile('mubla1/196328254774944514.png')}")`,
    // 购物车
    'header-car-image': `url("${getJdiOSFile('mubla1/196328163928866332.png')}")`,
    // 分割线
    'header-split-line-color': 'rgba(255, 255, 255, 0.2)',
    /**
     * controller tools 部分
     */
    'controller-tools-bg': '#0D3753',
    'controller-tools-text-color': '#FFF',
    'controller-tools-text-right-color': 'rgba(255, 255, 255, 0.6)',
    'controller-tools-icon-color': '#FFF',
    'controller-tools-line-bg': '#677D8B',
    // footer
    'footer-color-bg': 'rgb(61, 67, 82)',
    'footer-color-text-primary': 'rgba(255, 255, 255, 0.8)',
    'footer-color-text-title': 'rgba(255, 255, 255)',
  },
  [ThemeTypes.red]: {
    'primary-color': '#070E32',
    'primary-color-6': 'rgba(7, 14, 50, 0.6)',
    'global-text-color': '#070E32',
    /**
     * header 部分
     */
    'page-max-width': '1296px',
    'page-min-width': '996px',
    'header-bg': '#E1251B',
    'header-box-shadow': '0px 2px 5px rgba(255, 255, 255, 0.04)',
    'header-logo': `url("${getJdiOSFile('mubla1/22607583145767011.png')}")`,
    // text
    'header-text-color': '#FFF',
    'header-icon-color': '#FFF',
    'header-icon-tip-text-color': '#FFF',
    'header-icon-tip-bg': '#E02020',
    'header-text-color-hover': '#FFF', // 待定
    // search
    'header-search-border-color': '#FFF',
    'header-search-icon-bg': '#E1251B',
    'header-search-icon-bg-hover': '#D2170D',
    'header-search-icon-color': '#FFF',
    'header-search-icon-image': `url("${getJdiOSFile('mubla1/196328254774944514.png')}")`,
    // 购物车
    'header-car-image': `url("${getJdiOSFile('mubla1/196328163928866332.png')}")`,
    // 分割线
    'header-split-line-color': 'rgba(255, 255, 255, 0.2)',
    /**
     * controller tools 部分
     */
    'controller-tools-bg': '#C9130A',
    'controller-tools-text-color': '#FFF',
    'controller-tools-text-right-color': 'rgba(255, 255, 255, 0.6)',
    'controller-tools-icon-color': '#FFF',
    'controller-tools-line-bg': '#E43B32',
  },
};
