import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TabInfo from './left/tabInfo';

// 订单流程
import OrderProgress from './right/order';
import ApprovalProgress from './right/approval';

import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Des from '@/components/Atomics/Des/des';

interface IAppProps {
  orderDetails: any;
  orderDetailFn?: () => void;
}
const Progress: React.FC<IAppProps> = (props: any) => {
  const [type, setType] = useState<string>('order');
  const { orderDetails, orderDetailFn } = props;
  const store: any = useSelector(state => {
    return state;
  });

  const { t } = useTranslation();

  useEffect(() => {
    const userType = store?.User?.data[0]?.userType ?? '';
    if (userType === 6) {
      setType('approval');
    }
  }, []);
  const changeProgressType = type => {
    setType(type);
  };

  const parentOrderId = orderDetails?.orderRes?.parentOrderId;

  return (
    <div className={styles.mainProgress}>
      <div className={styles.orderInfoContainer}>
        <div className={styles.orderNumbersRow}>
          <div className={styles.orderNumberWrapper}>
            <Des
              label={`${TLT(`订单编号`)} #`}
              autoSize
              copy={false}
              text={orderDetails?.orderRes?.orderNo}
              fontSize="24px"
            ></Des>
          </div>
          {parentOrderId && parentOrderId !== '0' && (
            <div className={styles.parentOrderWrapper}>
              <Des label={`${TLT(`父单号`)}. #`} autoSize copy={false} text={parentOrderId}></Des>
            </div>
          )}
        </div>
        <div className={styles.orderDateRow}>
          <Des
            label={TLT('下单时间')}
            autoSize
            copy={false}
            text={moment(orderDetails?.orderRes?.orderTime).format('YYYY-MM-DD HH:mm:ss')}
          ></Des>
        </div>
      </div>

      <div className={styles['progress_info-containter']}>
        <TabInfo orderDetails={orderDetails} changeProgressType={changeProgressType} changeOrderDetail={orderDetailFn}>
          <div className={styles.right}>
            {type === 'order' ? (
              <OrderProgress orderDetails={orderDetails}></OrderProgress>
            ) : (
              <ApprovalProgress orderDetails={orderDetails}></ApprovalProgress>
            )}
          </div>
        </TabInfo>
      </div>
    </div>
  );
};

export default Progress;
