/*
 * @Author: your name
 * @Date: 2021-12-28 17:53:05
 * @LastEditTime: 2024-10-23 23:32:15
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/pages/OrderList/components/Goods/index.tsx
 */
import React, { useState } from 'react';
import { Row, Col, message, Tooltip } from 'antd';
import Icon from '@/components/common/Icon';
import styles from '../../index.module.scss';
import iconCopy from '@/assets/images/orderList/icon_copy.svg';
import iconX from '@/assets/images/orderList/icon_x.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { formatMoney, cnyMoney } from '@/util';
import Service from '@/components/ServiceStuff';
import { useTranslation } from 'react-i18next';
import ServiceStuff from '@/components/ServiceStuff';
import cookies from 'react-cookies';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import Des from '@/components/Atomics/Des/des';
import Tag from '@/components/Atomics/Tags/tags';
import { customClickReportSimple } from '@/dataPort';

const Goods: React.FC<{
  goods: any;
}> = ({ goods }) => {
  const { t } = useTranslation();
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0'); // 当前站点是否本土
  const history = useHistory();

  // 获取用户操作权限
  const userLimit: any = useSelector((state: any) => {
    return state?.UserLimit;
  });

  //以下没用
  // const data = goods.serviceSkus || [];
  // let serviceData: any = [];
  // if (data.length) {
  //   serviceData = goods?.extAttr?.giftAppendix ? data.concat(JSON.parse(goods?.extAttr?.giftAppendix)) : data;
  // } else {
  //   serviceData = goods?.extAttr?.giftAppendix ? JSON.parse(goods?.extAttr?.giftAppendix) : [];
  // }
  // if (serviceData.length > 0) {
  //   serviceData.forEach((service: any) => {
  //     service.type = service.skuType;
  //     service.skuName = service.name;
  //     service.serviceSkuPrice = service.salesPrice;
  //   });
  // }

  return (
    <>
      <div className={styles.productItemDetails}>
        <div className={styles.productItemInfoBox}>
          <LazyLoad>
            <img className={styles.productItemImage} src={goods.skuImg} alt="skuImg" />
          </LazyLoad>
          <div className={styles.productItemInfo}>
            <div
              data-track={JSON.stringify({
                eventId: 'OrderList_ProductTitle',
              })}
              onClick={() => window._openPage(goods.sku ? `/sku/${goods.sku}` : '', '_blank')}
              className={`${styles.productItemName} ${styles.truncate}`}
            >
              {goods.skuName}
            </div>

            <div className={styles.productItemMetadata}>
              {goods.specifications && <Des label={TLT(`商品型号`)} text={goods.specifications} copy={false} />}
              <Des label={TLT(`商品编码`)} text={goods.sku} copy={false} copyCb={() => {
                customClickReportSimple('OrderDetail_CopyMKU')
              }}/>
              {userLimit?.limitData?.enableMaterial == 1 && goods?.mkuMaterial?.materialId && (
                <Des label={TLT(`物料编码`)} text={goods?.mkuMaterial?.materialId} copy={false} />
              )}
              <Des label={TLT(`含税价`)} text={goods.symbol + formatMoney(goods.taxSalePrice, false)} copy={false} />
              <Des label={TLT(`未税价`)} text={goods.symbol + formatMoney(goods.skuPrice, false)} copy={false} />
              <Des label={TLT(`税率`)} text={goods.valueAddedTaxRate + '%'} copy={false} />
              {isLocalPro && (
                <Des
                  copy={false}
                  label={TLT(`是否报关`)}
                  text={goods?.customsClearance == 1 ? TLT(`是`) : goods?.customsClearance == 0 ? TLT(`否`) : '-'}
                />
              )}
              {goods?.sourceCountryCode == 'CN' && <Tag type="cross" />}
            </div>
          </div>
        </div>
        <span className={styles.productItemquantity}>
          <span>x {goods.skuNum}</span>
          {!goods?.afterSalesStatus ? null : goods?.afterSalesStatus == -1 ? (
            <span
              className={styles.shouhouBtn}
              onClick={() => {
                history.push(`/afterSale/apply?orderId=${goods?.orderId}&mkuId=${goods?.sku}`);
              }}
              data-track={JSON.stringify({
                eventId: 'OrderList_InitiateAfterSale',
              })}
            >
              {TLT(`申请售后`)}
            </span>
          ) : (
            <span
              className={styles.shouhouBtn}
              onClick={() => {
                history.push(`/afterSale/detail?afterSalesOrderId=${goods?.afterSalesOrderId}`);
              }}
              data-track={JSON.stringify({
                eventId: 'OrderList_ViewAfterSale',
              })}
            >
              {TLT(`售后详情`)}
            </span>
          )}
        </span>
      </div>

      {/* <Row style={{ paddingLeft: '20px', marginTop: '-14px', marginBottom: '4px' }}>
        <ServiceStuff data={goods.gift} />
      </Row> */}
    </>
  );
};

export default Goods;
