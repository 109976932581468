/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2025-01-09 18:56:17
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2025-01-09 22:13:47
 * @FilePath: /isp_International/src/pages/SkuDetailV2/atoms/specialAttrs.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { atom } from 'recoil';

import SpecialAttrs from '../types/specialAttrs';

const specialAttrs = atom<SpecialAttrs[] | undefined>({
  key: 'specialAttrs',
  default: undefined,
});

export default specialAttrs;
