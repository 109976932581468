/*
 * @Author: weiyanna
 * @Date: 2021-12-07 16:33:36
 * @LastEditTime: 2024-06-25 18:20:09
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/components/Address/List/Item/index.tsx
 */
import React from 'react';
import { Button, Row, Space, Col, Checkbox, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
// import AddressVisibilityModal from '../AddressVisibilityModal';
export interface IAppProps {
  address: any;
  onCheckAddress: (e: boolean, address: any) => void;
  onDeleteAddress: (e: boolean, address: any) => void;
  onEditAddress: (e: boolean, address: any) => void;
  onEditAlloc: (e: boolean, address: any) => void;
  checked: boolean;
}
const AddressItem: React.FC<IAppProps> = (props: any) => {
  const { onCheckAddress, onDeleteAddress, onEditAddress, address, checked } = props;
  const { t } = useTranslation();
  // 用户PIN
  const userPin: any = useSelector((state: any) => {
    return state.User.data[0] ? state.User.data[0].pin : '';
  });
  /**
   * 删除地址
   */
  const deleteAddress = (e: any) => {
    e.stopPropagation();
    onDeleteAddress(address);
  };
  /**
   * 修改地址
   */
  const editAddress = (e: any) => {
    e.stopPropagation();
    onEditAddress(address);
  };
  // 非当前登录角色的数据，不能进行增删改
  const disabled = address.pin === userPin ? false : true;
  return (
    <>
      <Row
        className={styles.item}
        align="middle"
        gutter={[20, 0]}
        onClick={() => {
          if (!disabled) {
            onCheckAddress(!checked, address);
          }
        }}
      >
        {/* 名称 */}
        <Col span={3} style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            data-track={JSON.stringify({
              eventId: 'AddAddress_SelectCheckbox',
            })}
            disabled={disabled}
            checked={checked}
            style={{ transform: 'translate(0, 2px)' }}
          />
          <Tooltip placement="topLeft" title={address.receiveName}>
            <span className={styles.name} style={{ marginLeft: '10px', fontWeight: 'bold', fontSize: '14px' }}>
              {address.receiveName}
            </span>
          </Tooltip>
        </Col>
        {/* 电话 */}
        <Col span={4}>
          <Tooltip placement="topLeft" title={address.receivePhone}>
            <span className={styles.name} style={{ fontWeight: 'bold' }}>
              {address.receivePhone}
            </span>
          </Tooltip>
        </Col>
        {/* 地区 */}
        {/* <Col span={8}>
          <Tooltip placement="topLeft" title={address.areaAddress}>
            <span className={styles.path}>
              {address.areaAddress}
            </span>
          </Tooltip>
        </Col> */}
        {/* 地址 */}
        <Col span={13}>
          <Tooltip placement="topLeft" title={`${address?.areaAddress} ${address?.detailAddress}`}>
            <span className={styles.path}>
              {address?.sortRule !== 'childPriority'
                ? address?.areaAddress + ' ' + address?.detailAddress
                : address?.detailAddress + ' ' + address?.areaAddress}
            </span>
          </Tooltip>

          {address.defaultStatus === 1 && !disabled ? (
            <span className={styles.setDefault}>{TLT(`默认`)}</span>
          ) : (
            <span className={styles.width}></span>
          )}
        </Col>
        {/* 操作 */}
        <Col span={4}>
          {!disabled && (
            <Space size={10} style={{ height: '19px' }}>
              <Button
                data-track={JSON.stringify({
                  eventId: 'AddAddress_Edit',
                })}
                disabled={disabled}
                type="link"
                onClick={editAddress}
              >
                {TLT(`编辑`)}
              </Button>
              <Button
                data-track={JSON.stringify({
                  eventId: 'AddAddress_DeleteSingle',
                })}
                disabled={disabled}
                type="link"
                onClick={deleteAddress}
              >
                {TLT(`删除`)}
              </Button>
            </Space>
          )}
        </Col>
      </Row>
    </>
  );
};

export default AddressItem;
