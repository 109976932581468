import React from 'react';
import styles from './index.module.scss';
import { getJdiOSFile } from '@/util/index';
import { Tooltip } from 'antd';
import OrderLogisticsTrajectory from '@/pages/OrderDetailNew/components/OrderLogisticsTrajectory';

const OrderStatusEnum: Record<number, Record<string, string>> = {
  1: { text: '已提交', color: 'rgba(7, 14, 50, 0.8)' },
  2: { text: '待确认', color: 'rgba(7, 14, 50, 0.8)' },
  3: { text: '审批中', color: '#FF8B1B' },
  4: { text: '下单成功', color: '#00AC05' },
  5: { text: '已完成', color: 'rgba(7, 14, 50, 0.8)' },
  80: { text: '已发货', color: '#00AC05' },
  90: { text: '已收货', color: 'rgba(7, 14, 50, 0.8)' },
  95: { text: '已确认收货', color: 'rgba(7, 14, 50, 0.8)' },
  99: { text: '已取消', color: 'rgba(7, 14, 50, 0.8)' },
};

const ApprovalStatusEnum: Record<number, Record<string, string>> = {
  1: { text: '审批中', color: '#FF8B1B' },
  2: { text: '通过', color: '#00AC05' },
  3: { text: '驳回', color: '#FF8B1B' },
  4: { text: '已取消', color: 'rgba(7, 14, 50, 0.8)' },
};

const OrderStatus: React.FC<{ order: any; type?: string }> = ({ order = {}, type = 'order' }) => {
  const itemStatus = type === 'order' ? order.orderStatus : order.approvalStatus;
  const orderRender = type === 'order' ? OrderStatusEnum[order.orderStatus] : ApprovalStatusEnum[order.approvalStatus];
  return (
    <div className={styles.container}>
      {orderRender && (
        <div className={styles.statusRow}>
          <span className={styles.statusLabel}>{TLT('订单状态')}</span>
          <span className={styles.statusValue} style={{ color: orderRender.color }}>
            {TLT(orderRender.text)}
          </span>
        </div>
      )}

      {itemStatus >= 4 && (
        <Tooltip
          title={
            <OrderLogisticsTrajectory
              params={{ orderNo: order?.orderNo, timeRuleVersion: JSON.parse(order.orderExtInfo)?.timeRuleVersion }}
            />
          }
          placement="right"
          color="#fff"
          key="tooltip"
          trigger="click"
        >
          <>
            <div className={styles.trackingRow}>
              <img className={styles.icon} src={getJdiOSFile('mubla1/132946353446519494.png')} alt="Tracking icon" />
              <span className={styles.trackingLabel}>{TLT('物流轨迹')}</span>
              <img className={styles.arrowIcon} src={getJdiOSFile('mubla1/196048986162778761.png')} alt="Arrow icon" />
            </div>
          </>
        </Tooltip>
      )}
    </div>
  );
};

export default OrderStatus;
