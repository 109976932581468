import React, { useCallback, useEffect, useState } from 'react';
import { Button, message, Tooltip, Checkbox } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import SlideProducts from './slideProducts';
import { useRecoilState, useSetRecoilState } from '@/pages/CartsNew/hooks';
import { formatMoney } from '@/util/index';
import { checkProduct } from '@api/newApi/carts';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { cx } from '@/util/tools';
import styles from '../../index.module.scss';
import { customClickReportSimple } from '@/dataPort';

const search = queryString.parse(window.location.search);

const placeOrder = () => {
  const { t } = useTranslation();
  const [totalInfos, setTotalInfos] = useRecoilState('totalInfos');
  const [skuList, setSkuList] = useRecoilState('skuList');
  const [showSlide, setShowSlide] = useRecoilState('showSlide');
  const [selected, setSelected] = useRecoilState('selected');
  const [selectedSkuList, setSelectedSkuList] = useRecoilState('selectedSkuList');
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isCanPlaceOrder, setIsCanPlaceOrder] = useRecoilState('isCanPlaceOrder');
  const setCurrent = useSetRecoilState('current');

  useEffect(() => {
    let selectSku = 0;
    skuList?.map(item => {
      if (item?.saleState?.saleState === 1 && item?.inPool && item?.remainNum !== 0) {
        selectSku += 1;
      }
    });
    const temp = selected.filter(s => {
      return s && s.trim();
    });
    if (selectSku === temp.length && temp.length > 0) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  }, [selected]);

  const handleToggle = useCallback(
    (event, skus) => {
      const { target } = event;
      const { checked } = target;
      const multiple = skus.length > 1;
      const copy = selected.map(item => item);
      log('jdisp', 'Cart_All');
      // inPool 是否入池
      //入池商品 且 库存不为0选中
      const inPoorSkus = skuList.filter(ele => ele?.inPool && ele?.remainNum !== 0 && skus.includes(ele.sku));
      console.log(inPoorSkus, 'inPoorSkus');
      if (checked) {
        if (multiple) {
          setSelected(inPoorSkus.map(ele => ele.sku) || []);
          setSelectedSkuList(inPoorSkus);
        } else {
          setSelected(copy.concat(inPoorSkus));
        }
      } else {
        if (multiple) {
          setSelected([]);
          setSelectedSkuList([]);
        } else {
          copy.splice(selected.indexOf(skus[0]), 1);
          setSelected(copy);
        }
      }
      checkProduct({
        skus: skus,
        checked: checked,
      }).then(res => {
        if (res?.success && res?.value?.cartWares.length > 0) {
          setTotalInfos({
            totalCheckCount: res?.value?.totalCheckCount || 0,
            totalCheckKindCount: res?.value?.totalCheckKindCount || 0,
            totalCount: res?.value?.totalCount || 0,
            totalKindCount: res?.value?.totalKindCount || 0,
            totalPrice: res?.value?.totalPriceCurrencies?.showCurrency?.salePrice || 0,
            totalPriceCurrencies: res?.value?.totalPriceCurrencies,
          });
        }
      });
    },
    [selected, selectedSkuList, skuList],
  );

  const deleteAll = () => {
    if (selectedSkuList?.length > 0) {
      setCurrent({
        method: 'delete',
        list: selectedSkuList,
      });
    } else {
      message.warning(TLT(`请选择要删除的商品`));
    }
    log('jdisp', 'Cart_delete');
  };
  const goToFillOrder = () => {
    // 是否有某些商品购买数量小于最小起订量
    const skuNumLessMoq = selectedSkuList?.filter(ele => ele.moq && ele.skuNum < ele.moq);
    if (skuNumLessMoq && skuNumLessMoq.length) {
      return message.warning(
        TLT(`${skuNumLessMoq && skuNumLessMoq.map(ele => ele.skuName + '、')}${TLT(`这些商品小于最小起订量`)}`),
      );
    }

    if (selectedSkuList?.length > 0) {
      const purchCodeParam = search?.purchCode ? `&purchCode=${search.purchCode}` : '';
      window.location.href = `/order/goOrderInfo?tradeModel=1${purchCodeParam}`;
    }
    log('jdisp', 'Cart_Placeorder');
  };

  return (
    <div className={`${styles.placeOrder}`}>
      <div className={styles.left}>
        <div className={styles.selectAll}>
          <Checkbox
            checked={isSelectAll}
            data-track={JSON.stringify({
              eventId: 'Cart_SelectAllCheckbox',
            })}
            onChange={e =>
              handleToggle(
                e,
                skuList?.map(item => {
                  if (item?.saleState?.saleState === 1) {
                    return item.sku;
                  }
                }),
              )
            }
          >
            {TLT(`全选`)}
          </Checkbox>
        </div>
        <Button
          data-track={JSON.stringify({
            eventId: 'Cart_DeleteSelected',
          })}
          type="link"
          className={cx(styles.deleteAll, 'underText')}
          onClick={deleteAll}
        >
          {TLT(`删除`)}
        </Button>
      </div>
      <div className={styles.center}>
        <div className={styles.pieces}>
          <span>
            {/*Selected {totalInfos?.totalCheckKindCount} kinds, a total of {totalInfos?.totalCheckCount} pieces:{' '}*/}
            {TLT(`已选{kind}件/共{check}件`)
              .replace('{kind}', totalInfos?.totalCheckKindCount)
              .replace('{check}', totalInfos?.totalCheckCount)}
          </span>
          {!showSlide && selected.length > 0 && (
            <CaretDownOutlined
              style={{ cursor: 'pointer' }}
              onClick={event => {
                customClickReportSimple('Cart_Preview');
                event.stopPropagation();
                setShowSlide(true);
              }}
            ></CaretDownOutlined>
          )}
          {showSlide && selected.length > 0 && (
            <CaretUpOutlined
              style={{ cursor: 'pointer' }}
              onClick={event => {
                event.stopPropagation();
                setShowSlide(false);
              }}
            ></CaretUpOutlined>
          )}
        </div>
        <div className={styles.price}>
          <span className={styles.title}>{TLT(`应付总金额`)}</span>
          <span className={styles.num}>
            <em>{totalInfos?.totalPriceCurrencies?.showCurrency?.symbol}</em>
            {formatMoney(totalInfos?.totalPrice, false)}
          </span>
        </div>
      </div>
      <div className={styles.right}>
        {isCanPlaceOrder ? (
          <Button
            data-track={JSON.stringify({
              eventId: 'Cart_Checkout',
            })}
            danger
            type="primary"
            size="large"
            style={{ minWidth: '140px' }}
            onClick={goToFillOrder}
          >
            {TLT(`去下单`)}
          </Button>
        ) : (
          <Tooltip title="The administrator cannot place orders" placement="topRight" overlayStyle={{ color: 'red' }}>
            <Button
              data-track={JSON.stringify({
                eventId: 'Cart_Checkout',
              })}
              danger
              type="primary"
              size="large"
              disabled={true}
              style={{ minWidth: '140px' }}
            >
              {TLT(`去下单`)}
            </Button>
          </Tooltip>
        )}
      </div>
      {showSlide && selected.length > 0 && <SlideProducts></SlideProducts>}
    </div>
  );
};

export default placeOrder;
