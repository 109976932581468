import React, { useEffect, useState } from 'react';
import { Row, Button, message, Col, Pagination, Modal, Tooltip, Table } from 'antd';

import UserCenterMenu from '@/components/UserCenterMenu';
import Tab from '@/components/Tab';

import styles from './index.module.scss';
import moment from 'moment';
import { PAGE_SIZE_OPTIONS } from '@/enum/config';
import { goTop } from '@/util';
import { findIndex } from 'lodash';
import { useHistory } from 'react-router-dom';
import CommonBasicHeader from '@/components/CommonBasicHeader';

import SearchEmpty from '@/assets/images/accountSettings/icon_search_empty.png';
import { getDownloadFileList, removeDownloadFile } from '@/api/newApi/personalCenter/downloadCenter';
import { useTranslation } from 'react-i18next';
import PaginationJD from '@/components/PaginationJD';
import { getTaskList } from '@/api/newApi/taskCenter';
export interface IAppProps {}
// (1排队中2执行中3已完成4失败）
enum TaskStateEnum {
  待执行 = 1,
  执行中,
  执行完成,
  执行失败,
}
enum TypeEnum {
  订单导入 = 'getOrderPrintList',
  订单导出 = 'getOrderExportList',
}
const DownloadCenter: React.FC<IAppProps> = () => {
  const queryParams = new URLSearchParams(window.location.search);
  // 页面刷新flag
  const [refresh, setRefresh] = useState(true);
  // 页码
  const [page, setPage] = useState<number>(1);
  // 中英文切换
  const { t } = useTranslation();
  // 页面数据展示条数
  const [pageSize, setPageSize] = useState<number>(30);
  // 页面数据总数
  const [total, setTotal] = useState<number>(0);
  // 数据集合
  const [dataSource, setDataSource] = useState([]);
  // 页面加载状态
  const [pageLoad, setPageLoad] = useState(false);

  const tabs = [
    {
      name: TLT(`订单打印`),
      type: 'getOrderPrintList',
      count: 0,
    },
    // {
    //   name: TLT(`订单导出`),
    //   type: 'getOrderExportList',
    //   count: 0,
    // },
  ];
  // 页面展示tab，默认为 订单打印
  const [type, setType] = useState<TypeEnum>((queryParams.get('type') as TypeEnum) || 'getOrderPrintList');
  // 路由历史记录操作
  const history = useHistory();
  // 获取列表
  const getList = ({ page = 1, pageSize }) => {
    setPageLoad(true);
    setDataSource([]);
    getTaskList({
      index: 1,
      size: pageSize,
    }).then((res: any) => {
      setPageLoad(false);
      const value = res?.value ?? {};
      const taskList = value?.records ?? [];
      setDataSource(taskList);
      setTotal(value?.total ?? 0);
    });
  };
  // 重置页面及刷新列表
  const reset = () => {
    setPage(1);
    setDataSource([]);
    setRefresh(true);
    log('jdisp', 'Downloads_Refresh');
  };
  // 当前分页重新获取数据
  useEffect(() => {
    if (refresh) {
      getList({
        page: 1,
        pageSize,
      });
      setRefresh(false);
    }
  }, [refresh]);
  // 刷新按钮
  const refreshButton = (
    <>
      <span style={{ marginRight: '15px', color: 'rgba(0,0,0,0.45)' }}>{TLT(`显示最近100条记录`)}</span>
      <span
        style={{
          color: 'rgba(0,3,20,0.85)',
          fontSize: '14px',
          padding: '6px 19px',
          cursor: 'pointer',
          border: '1px solid rgba(0,3,20,0.15)',
          borderRadius: '2px',
        }}
        onClick={reset}
      >
        {TLT(`刷新`)}
      </span>
    </>
  );

  const defaultTab = findIndex(tabs, ['type', type]);
  const removeItem = (id: string) => {
    Modal.confirm({
      title: TLT(`删除这个任务/结果吗?`),
      content: TLT(`删除后将无法恢复。`),
      cancelText: TLT(`确定`),
      okText: TLT(`取消`),
      onCancel: () => {
        // 因为按钮排序问题 取消即确定
        removeDownloadFile(type === TypeEnum.订单导出 ? 'export' : 'print', id).then(() => {
          message.success(TLT(`删除成功`));
          reset();
        });
      },
      onOk: () => {
        console.log('确定');
      },
    });
  };

  console.log(dataSource, 'dataSource');
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {/* <BreadCrumb data={crumbData} /> */}
      <div className={styles.container}>
        <UserCenterMenu />
        <div className={styles.dlCenter}>
          <CommonBasicHeader title={TLT(`任务中心`)}></CommonBasicHeader>
          <div className={styles.list} style={{ background: '#fff', paddingBottom: '24px' }}>
            {/* <Tab
              style={{ padding: '0' }}
              defaultIndex={defaultTab}
              tabs={tabs}
              rightContent={refreshButton}
              onChange={index => {
                const _type = tabs[index].type as TypeEnum;
                history.replace(`/downloadCenter?type=${_type}`);
                setType(_type);
                reset();
              }}
            /> */}
            <div className={styles.table}>
              {!dataSource.length ? (
                !pageLoad ? (
                  <div className={styles.empty}>
                    <img src={SearchEmpty} alt="" />
                    <div className={styles.title}>{TLT(`没有可下载的文件`)}</div>
                  </div>
                ) : null
              ) : (
                <>
                  <Table
                    dataSource={dataSource}
                    pagination={false}
                    bordered={false}
                    scroll={{
                      x: 800,
                    }}
                    columns={[
                      {
                        title: TLT(`任务号`),
                        dataIndex: 'id',
                        key: 'id',
                        width: 90,
                      },
                      {
                        title: TLT(`文件名`),
                        dataIndex: 'reqFileName',
                        key: 'reqFileName',
                      },
                      {
                        width: 120,
                        title: TLT(`成功数/总数`),
                        dataIndex: 'totalRows',
                        key: 'totalRows',
                        align: 'center',
                        render: (_, record) => {
                          return +record?.successRows + '/' + record?.totalRows;
                        },
                      },
                      {
                        title: TLT(`创建时间`),
                        dataIndex: 'createDate',
                        key: 'createDate',
                        render: createDate => {
                          return <div className={styles.time}>{moment(createDate).format('YYYY-MM-DD HH:mm:ss')}</div>;
                        },
                      },
                      {
                        title: TLT(`更新时间`),
                        dataIndex: 'updateTime',
                        key: 'updateTime',
                        render: updateTime => {
                          return <div className={styles.time}>{moment(updateTime).format('YYYY-MM-DD HH:mm:ss')}</div>;
                        },
                      },
                      {
                        width: 120,
                        title: TLT(`任务名称`),
                        dataIndex: 'bizTypeName',
                        key: 'bizTypeName',
                      },
                      {
                        title: TLT(`状态`),
                        dataIndex: 'statusName',
                        key: 'statusName',
                        width: 100,
                      },
                      {
                        title: TLT(`操作`),
                        dataIndex: 'operation',
                        key: 'operation',
                        width: 160,
                        render: (_, row: any) => {
                          return (
                            <>
                              {row.status !== 'PROCESSING' && row.status !== 'PENDING' && row.reqFileUrl && (
                                <Button
                                  type="link"
                                  data-track={JSON.stringify({
                                    eventId: 'TaskCenter_Download',
                                  })}
                                  style={{ marginRight: '16px' }}
                                  onClick={() => {
                                    window._openPage(row.resFileUrl, '_self');
                                    log('jdisp', 'Downloads_download');
                                  }}
                                >
                                  {TLT(`下载`)}
                                </Button>
                              )}
                              {/* {(row.status === 3 || row.status === 4) && (
                                <Button
                                  type="link"
                                  onClick={() => {
                                    removeItem(row.id);
                                    log('jdisp', 'Downloads_delete');
                                  }}
                                >
                                  {TLT(`删除`)}
                                </Button>
                              )} */}
                            </>
                          );
                        },
                      },
                    ]}
                  ></Table>

                  <div style={{ textAlign: 'right', marginTop: '24px' }}>
                    <PaginationJD
                      current={page}
                      total={total}
                      pageSize={pageSize}
                      pageSizeOptions={PAGE_SIZE_OPTIONS}
                      onChange={page => {
                        setPage(page);
                        setRefresh(true);
                        goTop(false);
                      }}
                      onShowSizeChange={(current, pageSize) => {
                        setPageSize(pageSize);
                        reset();
                      }}
                      showSizeChanger={false}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadCenter;
