import React, { useState } from 'react';
import {Form, Input, Button, Modal, message} from 'antd';
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { settleIn } from '@/api/login';
import PIC_ENMU from '../../enum/index';
import { useTranslation } from 'react-i18next';

const phone_reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/;
const email_reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable no-template-curly-in-string */

interface FormValues {
  address: string;
  companyname: string;
  demand: string;
  email: string;
  industry: string;
  phone: string;
  username: string;
}
const Register = (props: any) => {
  const { t, i18n } = useTranslation();
  const { clickHide } = props;
  const [visible, setVisible] = React.useState(false);
  const [modalText, setModalText] = React.useState('申请入驻成功');
  const [isSuccess, setIsSuccess] = React.useState(true);
  const showModal = () => {
    setVisible(true);
  };
  const requestSettleIn = async (parmas: any) => {
    const res = (await settleIn(parmas)) as any;
    setIsSuccess(res.success);
    if (res && res.success) {
      showModal();
      setModalText(res.msg);
    } else {
      message.error(res.msg)
    }

  };
  const onFinish = (values: FormValues) => {
    Object.keys(values).map(key => {
      //@ts-ignore
      values[key] = encodeURIComponent(values[key]);
    });
    const params = {
      answer: JSON.stringify(values),
    };
    requestSettleIn(params);
  };
  const handleOk = () => {
    setVisible(false);
  };
  const icon = () => {
    if (!isSuccess) {
      //@ts-ignore
      return <InfoCircleOutlined style={{ color: '#faad14' }} />;
    }
    //@ts-ignore
    return <CheckCircleOutlined style={{ color: '#3EBF17' }} />;
  };
  return (
    (<div className={`${styles.formBox}`}>
      <div className={`${styles.title}`} style={PIC_ENMU.banner.banner_register}>
        <p>{ TLT(`申请注册`) }</p>
        <p>{ TLT(`以下信息仅作为京东工业品建立采购合作使用，且严格保密您的企业信息。`) }</p>
      </div>
      <div className={`${styles.form}`}>
        <Form name="nest-messages" onFinish={onFinish}>
          <Form.Item name={['name']} label="" rules={[{ required: true, message: TLT(`请输入姓名`) }]}>
            <Input className={`${styles.formItem}`} placeholder={TLT(`您的姓名`)} />
          </Form.Item>
          <Form.Item name={['companyName']} label="" rules={[{ required: true, message: TLT(`请输入真实公司名称`) }]}>
            <Input className={`${styles.formItem}`} placeholder={TLT(`请输入真实公司名称`)} />
          </Form.Item>
          <Form.Item
            name={['mobile']}
            label=""
            rules={[
              { required: true, message: TLT(`请输入正确的手机号`) }
            ]}
          >
            <Input className={`${styles.formItem}`} placeholder={TLT(`手机号`)} />
          </Form.Item>
          <Form.Item
            name={['email']}
            label=""
            rules={[
              { required: true, message: TLT(`请输入正确的邮箱`) },
              { pattern: email_reg, message: TLT(`请输入正确的邮箱`) },
            ]}
          >
            <Input className={`${styles.formItem}`} placeholder={TLT(`邮箱`)} />
          </Form.Item>
          <Form.Item name={['industry']} label="" rules={[{ required: true, message: TLT(`请输入公司所属行业`) }]}>
            <Input className={`${styles.formItem}`} placeholder={TLT(`公司所属行业`)} />
          </Form.Item>
          <Form.Item name={['area_str']} label="" rules={[{ required: true, message: TLT(`请输入常用采购地址`) }]}>
            <Input className={`${styles.formItem}`} placeholder={TLT(`常用采购地址（省市区县级）`)} />
          </Form.Item>
          <Form.Item name={['desc']} label="" rules={[{ required: true, message: TLT(`请输入采购需求`) }]}>
            <Input.TextArea
              autoSize
              className={`${styles.form_textarea}`}
              style={{ width: '100%', resize: 'none' }}
              showCount
              maxLength={500}
              placeholder={TLT(`请输入采购需求`)}
            />
          </Form.Item>
          <Form.Item>
            <Button className={`${styles.subBtn}`} htmlType="submit">
              {TLT(`提交`)}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className={`${styles.tool}`}>
        <span>{TLT(`采购专线：400-618-8988`)}</span>
        <div className={`${styles.forget}`} onClick={clickHide}>
          {TLT(`已有账号，立即登录`)}
        </div>
      </div>
      {/*<div className={`${styles.reminder}`}>*/}
      {/*  <div className={`${styles.tit}`}>温馨提示：</div>*/}
      {/*  <p className={`${styles.paragraph}`}>为更及时有效响应您的需求，请提供完整真实的企业信息。</p>*/}
      {/*  <p className={`${styles.paragraph}`}>*/}
      {/*    以上信息仅作为需要和京东工业品建立采购合作使用，我们将严格保证您的个人信息。*/}
      {/*  </p>*/}
      {/*</div>*/}
      <Modal className={`form-box`} title={null} footer={null} open={visible}>
        <div className={`${styles.confirmTit}`}>
          <div className={`${styles.iconSize}`}>{icon()}</div>
          <span className={`${styles.tit}`}>{TLT('提交成功')}</span>
        </div>
        <p className={`${styles.message}`}>{modalText}</p>
        {/* <div className={`${styles.line}`}></div> */}
        <div className={`${styles.footer}`}>
          <Button type="primary" className={`${styles.confirmBtn} ${styles.btn}`} onClick={handleOk}>
            {TLT('确定')}
          </Button>
        </div>
      </Modal>
    </div>)
  );
};

export default Register;
