import React, { useEffect, useState } from 'react';
import Account from '@/pages/Account';
import { getPolicies } from '@/api/newApi/Policy';
import { Spin } from 'antd';

interface IframeComponentProps {
  src: string;
}

const IframeComponent: React.FC<IframeComponentProps> = () => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPolicies().then(res => {
      if (res.success) {
        setUrl(res.value.afterSalePolicyUrl);
        setLoading(false);
      }
    });
  }, []);

  const iframeStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    border: 'none',
    display: 'block',
  };

  return (
    <Account>
      <Spin style={{ width: '100%', height: '100%', marginTop: 64 }} spinning={loading}></Spin>
      {!loading && (
        <iframe
          src={url}
          style={iframeStyle}
          allowFullScreen
        />
      )}
    </Account>
  );
};

export default IframeComponent;
