import React, { useState } from 'react';
import { Button, Checkbox, message } from 'antd';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import styles from './index.module.scss';
import iconCopy from '@/assets/images/orderList/icon_copy.svg';
import { ReactComponent as IconPrint } from '@/assets/images/orderList/icon_print1.svg';
import { ReactComponent as IconDelete2 } from '@/assets/images/orderList/icon_delete2.svg';
import iconInfo from '@/assets/images/orderList/icon_info.svg';
import icon_tips from '@/assets/images/orderList/icon_tips.png';
import Joy from '@/assets/images/joy.svg';
import stylesBox from '@/pages/OrderList/index.module.scss';
import Des from '@/components/Atomics/Des/des';
import Time from '@/components/Atomics/Time';
import Tag from '@/components/Atomics/Tags/tags';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { getJdiOSFile } from '@/util/index';
import PriceSymbol from '@/components/Atomics/PriceSymbol/price';
import { customClickReportSimple } from '@/dataPort';

import { OrderStatus, orderStatus, print, orderTime } from '@/components/Order';

import moment from 'moment';

import { useSelector } from 'react-redux';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { UserType } from '../../components/SearchForm';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '@/util';

const OrderTitle: React.FC<{
  order: any;
  type?: 'parent' | 'child';
  isAudit: boolean;
  isDelete: boolean;
  isVsp: boolean;
  auditQueryType: string;
  checked: boolean;
  onCheckOrder: (e: CheckboxChangeEvent, order: any) => void;
  onRemoveOrder: React.MouseEventHandler<any>;
  isConfirm: boolean;
  parentIndeterminate: boolean;
  orderConfirm: () => void;
  printHandler: (orderNo: string) => void;
}> = ({
  order,
  type,
  isAudit,
  isDelete,
  isVsp,
  checked,
  auditQueryType,
  onCheckOrder,
  onRemoveOrder,
  isConfirm,
  orderConfirm,
  parentIndeterminate,
  printHandler,
}) => {
  const receiver = () => (
    <div>
      <div className={styles.receiver_title}>{order.consignee + ' ' + order.deliveryPhone}</div>
      <div className={styles.receiver_title} style={{ marginTop: '10px' }}>
        {order.deliveryCountry || ''}
      </div>
      <div className={styles.receiver_detail} style={{ marginTop: '15px' }}>
        {order.deliveryAddress}
      </div>
    </div>
  );

  return (
    <>
      <div
        className={`${stylesBox.orderListHeader} ${type !== 'parent' ? stylesBox.orderListHeaderBorder : ''}`}
        style={{ marginBottom: 0 }}
      >
        <div className={`${stylesBox.headerRow}`} style={{ ...(type === 'child' ? { background: '#FFF' } : {}) }}>
          <div className={`${stylesBox.productBox} ${stylesBox.productBoxHeader}`}>
            {/* 多选框 */}
            {type !== 'child' ? (
              <Checkbox
                clstag="h|keycount|myorder|15"
                checked={checked}
                indeterminate={parentIndeterminate}
                onChange={e => onCheckOrder(e, order)}
              />
            ) : (
              <Checkbox clstag="h|keycount|myorder|15" checked={checked} onChange={e => onCheckOrder(e, order)} />
            )}
            {/* 时间 */}
            <div className={styles.itemMergeTime}>
              <Time timestamp={orderTime(order)} />
            </div>

            <Des
              className={styles.itemMerge}
              label={type === 'child' ? TLT(`子订单编号`) : TLT(`订单编号`)}
              autoSize={true}
              text={order.orderNo}
              columnFlex={true}
              copyCb={() => {
                customClickReportSimple('OrderList_CopyOrderId')
              }}
            ></Des>

            {type !== 'child' && (
              <>
                {order.thirdOrderId && (
                  <Des
                    className={styles.itemMerge}
                    label={TLT(`采购单号`)}
                    autoSize={true}
                    text={order.thirdOrderId}
                    columnFlex={true}
                    copy={false}
                  ></Des>
                )}

                <Des
                  className={styles.itemMerge}
                  label={TLT(`收货人`)}
                  autoSize={true}
                  text={
                    <>
                      <span className={`${styles.personInfo} underText`}>
                        {order.consignee}{' '}
                        <img
                          className={styles.arrowIcon}
                          src={getJdiOSFile('mubla1/196048986162778761.png')}
                          alt="Arrow icon"
                        />
                      </span>
                    </>
                  }
                  columnFlex={true}
                  copy={false}
                  tooltip={receiver}
                ></Des>
              </>
            )}
          </div>
          <span className={stylesBox.total}>
            {/* 订单总价 */}
            {type !== 'child' && (
              <Des
                label={TLT(`订单总价`)}
                autoSize={true}
                text={<PriceSymbol symbol={order.symbol} price={formatMoney(order?.totalPrice, false)} />}
                columnFlex={true}
                textEnd={true}
                copy={false}
              ></Des>
            )}
          </span>

          <span className={stylesBox.action}>
            {type !== 'parent' && (
              <Tag
                type="print"
                align="end"
                onClick={() => {
                  customClickReportSimple('OrderList_PrintSingle')
                  printHandler(order.orderNo);
                }}
              />
            )}
          </span>
        </div>
      </div>

      {type === 'parent' && (
        <div className={styles.orderSplitNotice}>
          <ExclamationCircleFilled style={{ color: '#7A7E97' }} />
          <div
            className={styles.splitInfo}
            dangerouslySetInnerHTML={{
              __html: TLT('当前订单已拆分为{{tag}}个子订单', {
                tag: `<span class="splitCount">${order?.childOrderNum}</span>`,
              }),
            }}
          ></div>
          <span className={styles.splitReason}>
            {TLT(`您订单中的商品在不同库房或属于不同商家，故拆分为以下订单分开配送，敬请谅解`)}
          </span>
        </div>
      )}
    </>
  );
};

export default OrderTitle;
