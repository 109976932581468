/*
 * @Author: weiyanna weiyanna@jd.com
 * @Date: 2023-03-09 11:02:45
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-23 18:24:36
 * @FilePath: /isp_pro/src/pages/Approval/Create/components/modifyNameModal/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState } from 'react';
import { Modal, message, Row, Button } from 'antd';

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  open?: boolean;
  node?: any;
  confirmCallBack?: (node: any) => void;
  cancelCallBack?: () => void;
}

const ModifyNameModal: React.FC<IProps> = (props: any) => {
  const { open, node, confirmCallBack, cancelCallBack } = props;
  const [nameValue, setNameValue] = useState(node.nodeName); // 页面操作类型
  const [errorMessage, setErrorMessage] = useState(false); // 没有输入就点击提交按钮

  const { t } = useTranslation();
  const handleInputChange = (event: any) => {
    setNameValue(event.target.value);
    if (nameValue.length !== 0) {
      setErrorMessage(false);
    }
  };
  const handleInputBlur = (event: any) => {
    setNameValue(event.target.value);
    if (nameValue.length !== 0) {
      setErrorMessage(false);
    }
  };
  const handleClickConform = () => {
    if (nameValue.length === 0) {
      setErrorMessage(true);
    } else {
      message.success(TLT(`修改成功`));
      node.nodeName = nameValue;
      confirmCallBack && confirmCallBack(node);
    }
  };
  return (
    (<Modal
      centered
      open={open}
      width={464}
      className={styles.addComments}
      destroyOnClose={true}
      title={TLT(`修改名称`)}
      footer={
        // <div className={styles.addFooter}>
        //   <div className={styles.footerLeft}></div>
        //   <Button onClick={() => {
        //       cancelCallBack && cancelCallBack()
        //     }}>取消</Button>
        //     <Button type="primary" style={{marginLeft: '8px'}} onClick={handleClickConform}>修改</Button>
        // </div>
        <Row justify={'end'} style={{width: '100%',textAlign: 'right'}}>
          <Button onClick={() => {
             cancelCallBack && cancelCallBack()
          }}>{TLT(`取消`)}</Button>
          <Button type="primary" style={{marginLeft: '16px'}} onClick={handleClickConform}>{TLT(`修改`)}</Button>
        </Row>
      }
      // closeIcon={<div className={styles.iconClose}></div>}
      onCancel={() => {
        cancelCallBack && cancelCallBack();
      }}
    >
      <div className={styles.modifyContent}>
        <input
          placeholder={TLT(`请输入新名称`)}
          maxLength={15}
          value={nameValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          className={`${errorMessage ? styles.error : ''}`}
        />
        <span className={styles.lengthTips}>{nameValue.length}/15</span>
        {errorMessage && <span className={styles.errorTips}>{TLT(`请输入新名称`)}</span>}
      </div>
    </Modal>)
  );
};

export default ModifyNameModal;
