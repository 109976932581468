import React from 'react';
import styles from './index.module.scss';
import { getJdiOSFile } from '@/util/index';

const Solutions = () => {
  const solutions = [
    {
      name: TLT('伴随式出海'),
      description: TLT('跟随企业的全球化业务发展需要，快速在全球热点地区设立供应链服务，助力企业的出海需求'),
      backgroundImage: getJdiOSFile('mubla1/196329227785345023.png'),
    },
    {
      name: TLT('全托管服务'),
      description: TLT('从营销、商品、定价，到下单、履约、结算、售后，为供应商完美解决全球业务拓展的难题'),
      backgroundImage: getJdiOSFile('mubla1/196329227812822501.png'),
    },
    {
      name: TLT('数智供应链'),
      description: TLT(
        '以商品数字化、采购数字化、履约数字化和运营数字化能力，降低供应链的显性和隐性成本，实现保供、合规、降本、增效的目的',
      ),
      backgroundImage: getJdiOSFile('mubla1/196329012039887363.png'),
    },
  ];

  return (
    <div className={styles.solutionsContainer}>
      <h2 className={styles.solutionsTitle}>{TLT('您的工业用品一站式解决方案')}</h2>
      <div className={styles.solutionsList}>
        {solutions.map((solution, index) => (
          <div
            key={index}
            className={styles.solutionItem}
            style={{ backgroundImage: `url(${solution.backgroundImage})` }}
          >
            <div className={styles.solutionInner}>
              <div className={styles.solutionHeader}>
                <h3 className={styles.solutionName}>{solution.name}</h3>
              </div>
              <p className={styles.solutionDescription}>{solution.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Solutions;
