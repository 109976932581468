import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Modal } from 'antd';
import { Link } from 'react-router-dom';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import styles from '../../index.module.scss';
import iconArrowRight from '@/assets/images/orderList/icon_arrow_right.svg';
import iconInfo from '@/assets/images/orderList/icon_info.svg';
import iconAudit from '@/assets/images/orderList/icon_audit.svg';
import { cnyMoney } from '@/util';
import Goods from '../Goods';
import OrderActions from '../OrderActions';
import { InfoCircleOutlined } from '@ant-design/icons';
import { customClickReportSimple } from '@/dataPort';

import {
  OrderStatus,
  orderStatus,
  AuditStatus,
  approvalStatus,
  paymentType,
  restore,
  CancelModal,
  ApproveModal,
  RejectModal,
} from '@/components/Order';
import { pick } from 'lodash';
import { getAfterSaleGoods, getServiceUrl } from '@/api/orderList/api';
import ApproveProcess from '@/components/ApproveProcess';
import { UserType } from '../../components/SearchForm';
import { useTranslation } from 'react-i18next';

interface ApprovalStatusMap {
  [key: number]: { color: string; tag: string };
}
interface Order {
  approvalStatus: number;
}

const approvalStatusMap: ApprovalStatusMap = {
  2: { color: 'green', tag: 'access' },
  3: { color: 'red', tag: 'reject' },
};

const OrderItem: React.FC<{
  order: any;
  isAudit: boolean;
  isDelete: boolean;
  isVsp: boolean;
  auditQueryType: string;
  onCancel: () => void;
  onApprove: () => void;
  onReject: () => void;
  onRestore: () => void;
  isConfirm: boolean;
  orderConfirm: () => void;
}> = ({
  order,
  isAudit,
  isDelete,
  isVsp,
  auditQueryType,
  onCancel,
  onApprove,
  onReject,
  onRestore,
  isConfirm,
  orderConfirm,
}) => {
  const { t, i18n } = useTranslation();
  const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
  const [auditModalVisible, setAuditModalVisible] = useState<boolean>(false);
  const [approveModalVisible, setApproveModalVisible] = useState<boolean>(false);
  const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
  const [afterSaleModalVisible, setAfterSaleModalVisible] = useState<boolean>(false);
  const [afterSaleGoodsList, setAfterSaleGoodsList] = useState<Array<object>>([]);
  const [selectedOrders, setSelectedOrders] = useState<Array<{ jdOrderId: string; processId: string; pin: string }>>(
    [],
  );

  const history = useHistory();

  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  const userType: UserType = currentUser?.userType ?? 0;
  const userPin: string = currentUser.pin;

  const orderConsignee = order.orderConsignee || order.consignee;

  const receiver = (
    <div>
      <div className={styles.bold}>{orderConsignee.name + ' ' + orderConsignee.mobile}</div>
      <div className={styles.bold} style={{ marginTop: '10px' }}>
        {`${orderConsignee.provinceName || ''} ${orderConsignee.cityName || ''} ${orderConsignee.countyName ||
          ''} ${orderConsignee.townName || ''}`}
      </div>
      <div style={{ marginTop: '5px' }}>详细地址-{orderConsignee.address}</div>
    </div>
  );

  const cancelModal = (
    <CancelModal
      order={order}
      visible={cancelModalVisible}
      onCancel={() => setCancelModalVisible(false)}
      onOK={() => {
        setCancelModalVisible(false);
        onCancel();
      }}
    />
  );

  const afterSaleModal = (
    <Modal
      width={620}
      title="申请售后"
      open={afterSaleModalVisible}
      footer={null}
      onCancel={() => setAfterSaleModalVisible(false)}
      className={styles.buyAccountModal}
    >
      <div style={{ color: '#666' }}>选择需要售后的商品</div>
      <div
        style={{
          overflowY: 'auto',
          maxHeight: '520px',
          marginTop: '20px',
        }}
      >
        {afterSaleGoodsList.map((g: any) => (
          <Row
            key={g.skuId}
            className={styles.borderBottom}
            style={{
              padding: '15px 0',
              fontSize: '12px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Col className={[styles.center].join(' ')} style={{ alignItems: 'start', width: '230px' }}>
              <img src={`https://m.360buyimg.com/da/s150x150_${g.imgUrl}`} style={{ width: '40px', height: '40px' }} />
              <span className={styles.goodsName} style={{ marginLeft: '10px' }}>
                {g.name}
              </span>
            </Col>
            <Col className={[styles.center].join(' ')}>
              <span style={{ fontWeight: 'bold' }}>
                <span className={styles.blue}>{order.symbol + cnyMoney(g.price, false)}</span> x {g.num}
                {g?.extAttr?.x ?? '件'}
              </span>
            </Col>
            <Col>
              <Button
                type="primary"
                ghost
                disabled={!g.canApply}
                onClick={() => history.push(`/application?orderId=${order.jdOrderId}&skuId=${g.skuId}`)}
              >
                申请售后
              </Button>
            </Col>
          </Row>
        ))}
      </div>
    </Modal>
  );
  const approveModal = (
    <ApproveModal
      orders={selectedOrders}
      visible={approveModalVisible}
      onCancel={() => setApproveModalVisible(false)}
      onOK={() => {
        setApproveModalVisible(false);
        onApprove();
      }}
    />
  );

  const rejectModal = (
    <RejectModal
      orders={selectedOrders}
      visible={rejectModalVisible}
      onCancel={() => setRejectModalVisible(false)}
      onOK={() => {
        setRejectModalVisible(false);
        onReject();
      }}
    />
  );
  const findAuditStatus = (order: any) => {
    const { approvalStatus } = order;
    switch (approvalStatus || 0) {
      case 1:
        return TLT(`审批中`);
      case 2:
        return TLT(`通过`);
      case 3:
        return TLT(`驳回`);
      case 4:
        return TLT(`已取消`);
      default:
        return TLT(`驳回`);
    }
  };
  return (
    <>
      <header className={styles.productItemOrderListHeader}>
        <div className={styles.productItemheaderRow}>
          <div className={styles.productItemproductBox}>
            {order.orderSkus.slice(0, 5).map((g: any, index: number) => (
              <Goods key={index} goods={g} />
            ))}
          </div>
          <span className={styles.productItemtotal}>
            {order.symbol + cnyMoney(order.orderNeedMoney || order.needPayMoney, false)}
          </span>
          <span className={styles.productItemaction}>
            <OrderActions
              order={order}
              isAudit={isAudit}
              isDelete={isDelete}
              // isVsp={isVsp}
              isConfirm={isConfirm}
              orderConfirm={orderConfirm}
              auditQueryType={auditQueryType}
              onApplyAftersale={() => {
                getAfterSaleGoods({ orderId: order.jdOrderId }).then((res: any) => {
                  setAfterSaleGoodsList(res?.value?.paginatedArrayList?.[0]?.skuList ?? []);
                  setAfterSaleModalVisible(true);
                });
              }}
              onCancel={() => {
                setCancelModalVisible(true);
              }}
              onApprove={() => {
                setSelectedOrders([pick(order, ['jdOrderId', 'processId', 'pin'])]);
                setApproveModalVisible(true);
                customClickReportSimple('ApprovalList_Approve')
              }}
              onReject={() => {
                setSelectedOrders([pick(order, ['jdOrderId', 'processId', 'pin'])]);
                setRejectModalVisible(true);
                customClickReportSimple('ApprovalList_Reject')
              }}
              onRestore={() => {
                restore(order.jdOrderId, () => {
                  onRestore();
                });
              }}
              onCountDown={() => onCancel()}
              onReceiptCountDown={() => onCancel()}
            />
          </span>
        </div>
      </header>

      {/* <Col className={styles.borderRight}>
        {order.orderSkus.slice(0, 5).map((g: any, index: number) => (
          <Goods key={index} goods={g} />
        ))}
      </Col> */}
      {/* <Col className={[styles.title4, styles.goodsCell, styles.borderRight, styles.borderTop].join(' ')}>
        <Row style={{ alignItems: 'center' }}>
          <span
            style={{
              marginRight: '5px',
              wordBreak: 'break-all',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical',
              overflowY: 'hidden',
            }}
          >
            {orderConsignee.name}
          </span>
        </Row>
      </Col> */}
      {/* <Col className={[styles.title5, styles.goodsCell, styles.borderRight, styles.borderTop].join(' ')}>
        <div className={[styles.price, styles.bold].join(' ')}>
          {order.symbol + cnyMoney(order.orderNeedMoney || order.needPayMoney, false)}
        </div>
      </Col> */}

      {/* 审批信息 */}
      {/* <Col
        className={[styles.title6, styles.goodsCell, styles.borderRight, styles.borderTop].join(' ')}
        style={{ flexDirection: 'column' }}
      >

        <Row style={{ marginTop: '0px', marginBottom: '8px', alignItems: 'center' }}> */}
          {/* <span
            // onClick={() => {
            //   // 母子帐号没有审批链路，不需要点开
            //   if (userType !== UserType.parentAccount && userType !== UserType.childAccount) {
            //     setAuditModalVisible(true);
            //   }
            // }}
            // style={{ cursor: 'pointer', color: '#00678B' }}
            style={{
              cursor: 'default',
              color: approvalStatusMap[order.approvalStatus]?.color || '#00678B',
              fontWeight: 'normal',
            }}
            // style={{ color: '#00678B' }}
          >
            {findAuditStatus(order)}
          </span> */}
          {/* {// 订单列表：审批中或者有审批文件
          (order?.operateMap?.showLookFileBtn ?? false) && ( // 审批列表：showLookFileBtn
            <Icon icon={iconAudit} style={{ marginLeft: '5px', cursor: 'default' }} />
          )} */}
        {/* </Row>

        <Row style={{ marginTop: '0px', alignItems: 'center', fontWeight: 'normal' }}>
          <span
            onClick={() => {
              // 母子帐号没有审批链路，不需要点开
              if (userType !== UserType.parentAccount && userType !== UserType.childAccount) {
                setAuditModalVisible(true);
              }
            }}
            className={`${styles.titleArrow}`}
            style={{ cursor: 'pointer', color: 'rgba(51,51,51,0.65)' }}
          >
            {TLT(`审批详情`)}
            <i className={`${styles.crumbs_arrow}`}></i>
          </span>
        </Row>
      </Col> */}

      {/* <Col
        className={[styles.title7, styles.goodsCell, styles.borderTop].join(' ')}
        style={{ flexDirection: 'column' }}
      >
        <OrderActions
          order={order}
          isAudit={isAudit}
          isDelete={isDelete}
          // isVsp={isVsp}
          isConfirm={isConfirm}
          orderConfirm={orderConfirm}
          auditQueryType={auditQueryType}
          onApplyAftersale={() => {
            getAfterSaleGoods({ orderId: order.jdOrderId }).then((res: any) => {
              setAfterSaleGoodsList(res?.value?.paginatedArrayList?.[0]?.skuList ?? []);
              setAfterSaleModalVisible(true);
            });
          }}
          onCancel={() => {
            setCancelModalVisible(true);
          }}
          onApprove={() => {
            setSelectedOrders([pick(order, ['jdOrderId', 'processId', 'pin'])]);
            setApproveModalVisible(true);
            log('jdisp', 'ApprovalList_Approve');
          }}
          onReject={() => {
            setSelectedOrders([pick(order, ['jdOrderId', 'processId', 'pin'])]);
            setRejectModalVisible(true);
            log('jdisp', 'ApprovalList_Reject');
          }}
          onRestore={() => {
            restore(order.jdOrderId, () => {
              onRestore();
            });
          }}
          onCountDown={() => onCancel()}
          onReceiptCountDown={() => onCancel()}
        />
      </Col> */}
      {cancelModal}
      {afterSaleModalVisible && afterSaleModal}
      {approveModal}
      {rejectModal}
    </>
  );
};

export default OrderItem;
