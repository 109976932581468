import React from 'react'
import styles from './index.module.scss'
import { getJdiOSFile } from '@/util/index';

function Mod() {
  return (
   

<div className={styles.featureContainer}>
  <div className={styles.featureItem}>
    <img className={styles.featureIcon} src={getJdiOSFile('mubla1/133226224369847385.png')} alt="Efficient Delivery" />
    <div className={styles.featureContent}>
      <h3 className={styles.featureTitle}>{TLT('高效交付')}</h3>
      <p className={styles.featureDescription}>{TLT('全球供应链，高效交付')}</p>
    </div>
  </div>
  
  <div className={styles.featureItem}>
    <img className={styles.featureIcon} src={getJdiOSFile('mubla1/23845295193769734.png')} alt="Best Quality" />
    <div className={styles.featureContent}>
      <h3 className={styles.featureTitle}>{TLT('最佳品质，合理价格')}</h3>
      <p className={`${styles.featureDescription} ${styles.multiLine}`}>{TLT('我们提供高品质的商品和合理的价格')}</p>
    </div>
  </div>
  
  <div className={styles.featureItem}>
    <img className={styles.featureIcon} src={getJdiOSFile('mubla1/133226224369965796.png')} alt="24/7 Support" />
    <div className={styles.featureContent}>
      <h3 className={styles.featureTitle}>{TLT('24/7 支持')}</h3>
      <p className={`${styles.featureDescription} ${styles.multiLine}`}>{TLT('我们始终提供7x24小时最好的服务支持')}</p>
    </div>
  </div>
</div>

  )
}
export default Mod
