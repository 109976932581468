import http from '../axios';

export function getPolicies(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/cms/simple/list', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}