import React from 'react';
import { Input, Card } from 'antd';
import styles from './index.module.scss';
import { useSetRecoilState } from '../../hooks';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

export default () => {
  const setOrderRemarks = useSetRecoilState('orderRemarks');
  const { t } = useTranslation();

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOrderRemarks(e.target.value);
  };

  return (
    <Card title={TLT('备注')} bordered={false} className={styles.main}>
      <TextArea
        data-track={JSON.stringify({
          eventId: 'Checkout_Remark',
        })}
        showCount
        maxLength={200}
        autoSize={{ minRows: 4 }}
        style={{ width: '100%', resize: 'none' }}
        onChange={onChange}
        placeholder={TLT(`请填写备注`)}
      />
    </Card>
  );
};
