import React from 'react';
import { Card, Form, Select, Input, Row, Col } from 'antd';
import { useRecoilState } from '../../hooks';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import type { FormProps } from 'antd'

const { Option } = Select;

interface EnumItem {
  id: string;
  value: string;
}

interface ConditionItem {
  id: string;
  category: string;
  enums?: EnumItem[];
  text?: string;
  value?: string;
}

const Index: React.FC = ({ form }) => {
  const [conditionList, setConditionList] = useRecoilState<ConditionItem[]>('conditionList');
  const [condition] = useRecoilState<ConditionItem[]>('condition');
  // const [form] = Form.useForm();

  //表单项目=修改
  const changeHanlder = (ele, val) => {
    const selected = condition.findIndex(item => item.id === ele.id);
    const item = {
      id: ele.id,
      category: ele.category,
      value: val,
    };
    if (selected == -1) {
      condition.push(item);
    } else {
      condition[selected] = item;
    }
  };

  const customScrollToFirstError: FormProps['scrollToFirstError'] = {
    block: 'center',
    behavior: 'auto',
  };

  return (
    <>
      {conditionList && conditionList.length > 0 && (
        <Card title={TLT(`采购信息备注`)} bordered={false} className={styles.m}>
          <Form
            form={form}
            layout="vertical"
            scrollToFirstError={customScrollToFirstError}
          >
            <Row gutter={16}>
              {conditionList.map((ele, index) => (
                <Col span={12} key={ele.id}>
                  <Form.Item
                    name={ele.id}
                    label={<span>{ele.category}</span>}
                    rules={[{ required: true, message: `${TLT(`请输入{{tag}}`, { tag: ele.category })}` }]}
                  >
                    {ele.enums && ele.enums.length > 0 ? (
                      <Select
                        style={{
                          width: '100%',
                        }}
                        onChange={val => {
                          console.log(val);
                          const item = ele.enums.filter(item => item.id == val);
                          const newList: any = [...conditionList];
                          const newEle = { ...ele };
                          newEle.text = val;
                          const index = newList.findIndex(ele => ele.id == newEle.id);
                          if (index > -1) {
                            newList[index] = newEle;
                          }
                          setConditionList(newList);
                          changeHanlder(ele, item[0].value);
                        }}
                      >
                        {ele?.enums.map((item, i) => (
                          <Select.Option key={item.id}>{item.value}</Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        onChange={e => {
                          changeHanlder(ele, e.target.value);
                          const newList: any = [...conditionList];
                          const newEle = { ...ele };
                          newEle.text = e.target.value;
                          const index = newList.findIndex(ele => ele.id == newEle.id);
                          if (index > -1) {
                            newList[index] = newEle;
                          }
                          setConditionList(newList);
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </Form>
        </Card>
      )}
    </>
  );
};

export default Index;
