/*
 * @Author: duchongchong1
 * @Date: 2023-08-31 19:55:45
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-12-23 16:44:00
 * @FilePath: /isp_International/src/pages/OrderInfo/components/Panel/index.tsx
 * @Description:
 */
import React, { useEffect, useState } from 'react';
import { message, Button } from 'antd';
import { getApprovalByCondition, submitOrder } from '@/api/newApi/orderInfo';
import { useRecoilState, useRecoilValue, useSetRecoilState } from '../../hooks';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import cookies from 'react-cookies';
import { getPersonInfo, getPunchoutOrder } from '@api/newApi/carts';
import Approval from '../Approval';
import { formatMoney } from '@/util';
import { useSelector } from 'react-redux';
import { createForm } from '@/util/userProfile';
import { usePermissions } from '@/util/hooks/checkPermissions';

interface Shipment {
  cartResp: {
    totalCount: number;
    totalKindCount: number;
  };
}

const Index = (props: any) => {
  const setApprovalList = useSetRecoilState('approvalList'); // 审批流程
  const consignee = useRecoilValue('consignee');
  const [others, setOthers] = useRecoilState('others');
  const orderRemarks = useRecoilValue('orderRemarks');
  const shippingtype = useRecoilValue('shippingtype');
  const shipment = useRecoilValue('shipment');
  const transfee = useRecoilValue('transfee');
  const condition = useRecoilValue('condition');
  const approval = useRecoilValue('approval');
  const taxfee = useRecoilValue('taxfee');
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0'); // 当前站点是否本土
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isShowApprovalmoDal, setIsShowApprovalmoDal] = useState(false);
  const { loading, checkPermission } = usePermissions();

  const cartResp = ((shipment as unknown) as Shipment)?.cartResp;

  useEffect(() => {
    getPersonInfo().then(res => {
      if (res?.value?.userType === 4) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    });
  }, []);

  const currentLocation: any = useSelector((state: any) => {
    return state.User.data?.[0] || {};
  });

  const punchoutChecker = async (value: string) => {
    const result = await getPunchoutOrder({
      orderId: value,
    });
    if (result.success) {
      createForm(result.value.formData, result.value.hookUrl);
    }
  };

  const handleSubmit = async () => {
    await props.form?.submit()
    await props.form?.validateFields();
    
    console.log(shipment, 'shipment');
    // return
    console.log('consignee', consignee);
    if (!consignee) {
      message.error(TLT(`请填写收货地址信息！`));
      return;
    }

    console.log('consignee', 1);
    //采购单号配置项目下标
    const purchasOrderIndex = condition.findIndex(ele => ele?.id == -1);
    const submitCondition = JSON.parse(JSON.stringify(condition));
    console.log('consignee', 2);
    //本土条件下提交条件数组内去掉PO单号
    if (isLocalPro) {
      submitCondition.splice(purchasOrderIndex, 1);
      if (!condition[purchasOrderIndex]?.value && checkPermission('isPoNumberRequired')) return; //本本模式未填写po单号不允许提交
    }
    console.log('consignee', 3);
    // 配送相关参数
    const params = {
      remark: orderRemarks,
      receiverId: consignee.id,
      tradeModel: others?.tradeModel,
      shippingType: shippingtype,
      tradeType: 1,
      countyId: consignee?.countyId,
      configList: submitCondition,
      thirdOrderId: condition[purchasOrderIndex]?.value,
    };

    console.log('consignee', 4);
    if (isLocalPro) {
      const mkuList = shipment?.productList
        ? shipment?.productList.map(ele => ({
            sku: ele.sku,
            customsDeclaration: ele.isCustomsDeclaration,
            scheduledDeliveryTime: ele?.scheduledDeliveryTime,
            deliveryType: ele?.deliveryType,
          }))
        : [];
      params.mkuList = mkuList;
    }

    // 没选取审批流弹框选取 选了审批流直接提交
    if (!approval) {
      //根据条件查询审批流
      const resData = await getApprovalByCondition({
        condition,
      });

      console.log('consignee', 5);
      if (resData.success) {
        setApprovalList(resData?.value);
        //查询到有审批流且审批流有多项就弹框选取审批流再提交
        if (resData?.value && resData?.value.length) {
          if (resData?.value.length > 1) {
            setIsShowApprovalmoDal(true);
            return;
          } else {
            params.processKey = resData?.value[0]?.processKey;
          }
        }
      } else {
        return message.error(resData.msg);
      }
    } else {
      params.processKey = approval;
    }
    if (isSubmitLoading) {
      return;
    }
    setIsSubmitLoading(true);
    submitOrder(params).then(async (resp: any) => {
      setIsSubmitLoading(false);
      if (resp.success) {
        if (checkPermission('isFormDataRequiredAfterPoSubmission') && resp.value) {
          await punchoutChecker(resp.value);
          return;
        }

        setOthers({
          ...others,
          paySuccess: true,
        });
      } else {
        setOthers({
          ...others,
          paySuccess: false,
        });
        message.error(resp.msg);
      }
    });
  };
  //多币种获取展示币种以及符号
  const curCartResp = shipment?.cartResp;
  const symbol = curCartResp?.totalPriceCurrencies?.showCurrency?.symbol;
  const totalPrice = isLocalPro
    ? curCartResp?.totalPriceCurrencies?.showCurrency?.price
    : curCartResp?.totalPriceCurrencies?.showCurrency?.salePrice;
  const includeTaxPrice =
    curCartResp?.totalPriceCurrencies?.showCurrency?.price +
    curCartResp?.totalPriceCurrencies?.showCurrency?.valueAddedTax; //商品含税总金额
  const shouldPayPrice = curCartResp?.totalPriceCurrencies?.showCurrency?.includeTaxPrice; //应付总金额
  return (
    <div className={styles.m}>
      <div className={styles.orderSummaryContent}>
        <h2 className={styles.orderSummaryTitle}>{TLT('订单汇总	')}</h2>
        <div className={styles.divider}></div>
        <div className={styles.orderDetails}>
          <div className={styles.orderDetailRow}>
            <span className={styles.detailLabel}>{TLT(`商品总数`)}</span>
            <span className={styles.detailValue}>
              {TLT('{{kind}}种(共{{total}}件)', { kind: cartResp?.totalKindCount, total: cartResp?.totalCount })}
            </span>
          </div>
          <div className={styles.priceRow}>
            <span className={styles.priceLabel}>{TLT(`商品未税总金额`)}</span>
            <span className={styles.priceValue}>
              {symbol}
              {totalPrice ? (isLocalPro ? formatMoney(totalPrice, false) : totalPrice) : 0}
            </span>
          </div>
          <div className={styles.priceRow}>
            <span className={styles.priceLabel}>{TLT(`增值税总金额`)}</span>
            <span className={styles.priceValue}>
              {symbol}
              {isLocalPro ? formatMoney(taxfee, false) : taxfee}
            </span>
          </div>
          <div className={styles.priceRow}>
            <span className={styles.priceLabel}>{TLT(`商品含税总金额`)}</span>
            <span className={styles.priceValue}>
              {symbol}
              {totalPrice
                ? isLocalPro
                  ? formatMoney(Number((+includeTaxPrice).toFixed(4)), false)
                  : Number((+totalPrice + +transfee + +taxfee).toFixed(4))
                : 0}
            </span>
          </div>
          <div className={styles.priceRow}>
            <span className={styles.priceLabel}>{TLT(`运费`)}</span>
            <span className={styles.priceValue}>
              {symbol}
              {isLocalPro ? formatMoney(transfee, false) : transfee}
            </span>
          </div>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.orderTotalRow}>
          <span className={styles.orderTotalLabel}>{TLT(`应付总金额`)}</span>
          <span className={styles.orderTotalValue}>
            {symbol}
            {isLocalPro ? formatMoney(shouldPayPrice, false) : shouldPayPrice}
          </span>
        </div>
        <Button
          type="primary"
          loading={isSubmitLoading}
          onClick={handleSubmit}
          disabled={disabled}
          className={styles.submitOrderWrapper}
          danger
          data-track={JSON.stringify({
            eventId: 'Checkout_PlaceOrder',
          })}
        >
          {TLT(`提交订单`)}
        </Button>
      </div>

      <Approval
        isShow={isShowApprovalmoDal}
        callback={(type: number) => {
          if (type == 1) {
            handleSubmit();
          }
          setIsShowApprovalmoDal(false);
        }}
      />
    </div>
  );
};

export default Index;
