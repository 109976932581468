import React from 'react';
import styles from './index.module.scss';
import { formatMoney, cnyMoney } from '@/util';

const orderDetailsContainer: React.FC<any> = ({ orderDetails, contentList }) => {
  const orderRes = orderDetails?.orderRes;
  //收货人信息
  const deliveryCountry = orderRes?.deliveryCountry ?? '';
  const address = orderRes?.deliveryAddress ?? '';
  const _address = deliveryCountry + ' ' + address;

  return (
    <div className={styles.infoColumns}>
      <div className={styles.addressColumn}>
        <h3 className={styles.sectionTitle}>{TLT(`收货人信息`)}</h3>
        <div className={styles.addressDetails}>
          <span className={styles.name}>{orderRes?.consignee ?? ''}</span>
          <span className={styles.phone}>{orderRes?.deliveryPhone ?? ''}</span>
          <span className={styles.address}>{_address}</span>
        </div>
      </div>

      <div className={styles.contractColumn}>
        <h3 className={styles.sectionTitle}>{TLT(`合同信息`)}</h3>
        <div className={styles.contractDetails}>
          {contentList &&
            contentList.map((item, idx) => {
              return (
                <div className={styles.contractRow} key={idx}>
                  <span className={styles.label}>{item.label}</span>
                  <span className={styles.value}>{item.value || '-'}</span>
                </div>
              );
            })}
        </div>
      </div>

      {Object.keys(orderDetails).length && (
        <div className={styles.summaryColumn}>
          <h3 className={styles.sectionTitle}>{TLT('订单汇总')}</h3>
          <div className={styles.summaryDetails}>
            <div className={styles.summaryItems}>
              <div className={styles.summaryRow}>
                <span className={styles.label}>{TLT(`商品总数`)}</span>
                <span className={styles.price}>
                  {TLT(`{kind}种(共{num}件)`)
                    .replaceAll('{kind}', orderRes?.orderSkuKindNum)
                    .replaceAll('{num}', orderRes?.orderSkuNum)}
                </span>
              </div>
              <div className={styles.summaryRow}>
                <span className={styles.label}>{TLT(`商品未税总金额`)}</span>
                <span className={styles.price}>
                  {orderRes?.symbol + cnyMoney(orderRes?.waresSaleTotalPrice, false)}
                </span>
              </div>
              <div className={styles.summaryRow}>
                <span className={styles.label}>{TLT(`税金`)}</span>
                <span className={styles.price}>
                  {'+' + ' ' + orderRes?.symbol + cnyMoney(orderRes?.orderTaxPrice, false)}
                </span>
              </div>
              <div className={styles.summaryRow}>
                <span className={styles.label}>{TLT(`商品含税总金额`)}</span>
                <span className={styles.price}>
                  {'+' + ' ' + orderRes?.symbol + cnyMoney(orderRes?.totalPrice, false)}
                </span>
              </div>

              {orderRes?.serviceFee && (
                <div className={styles.summaryRow}>
                  <span className={styles.label}>{TLT(`服务费`)}</span>
                  <span className={styles.price}>
                    {'+' + ' ' + orderRes?.symbol + cnyMoney(orderRes?.serviceFee, false)}
                  </span>
                </div>
              )}
              <div className={styles.summaryRow}>
                <span className={styles.label}>{TLT(`运费`)}</span>
                <span className={styles.price}>
                  {'+' + ' ' + orderRes?.symbol + cnyMoney(orderRes?.orderFreightPrice, false)}
                </span>
              </div>
            </div>
            <div className={styles.totalRow}>
              <span className={styles.totalLabel}>{TLT(`应付总金额`)}</span>
              <span className={styles.totalPrice}>{orderRes?.symbol + '' + cnyMoney(orderRes?.totalPrice, false)}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default orderDetailsContainer;
