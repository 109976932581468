import React, { useEffect, useState } from 'react';
import { Drawer, Input } from 'antd';
import styles from './index.module.scss';

import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getJdiOSFile } from '@/util/index';

type PropApi = {
  closeDrawer?: (item?: any) => void; // 关闭抽屉回调
  showDrawer: boolean; // 显示抽屉
  consigneeList: any[]; // 其他地址列表
  consignee: any; // 当前地址
  setConsigneeList: any;
  setConsignee: any;
};

export default (props: PropApi) => {
  const { closeDrawer, showDrawer, consigneeList, consignee = null, setConsigneeList, setConsignee } = props;
  const [open, setOpen] = useState(false);
  const [selectConsignee, setSelectConsignee] = useState<any>({}); // 当前选择的地址
  const [currentSearchWord, setCurrentSearchWord] = useState<string>(''); // 当前搜索词
  const { t } = useTranslation();
  // 关闭抽屉
  const onClose = (newItem: any) => {
    // closeDrawer && closeDrawer(selectConsignee);
    // setConsignee(newItem);
    closeDrawer && closeDrawer();
  };
  const onClose2 = (item: any) => {
    const newConsigneeList = consigneeList.map(value => {
      if (value.id === item.id) {
        // item.defaultStatus = 1;
        return { ...item, defaultStatus: 1 };
      } else {
        // item.defaultStatus = 0;
        return { ...value, defaultStatus: 0 };
      }
    });
    const newItem = { ...item };
    newItem.defaultStatus = 1;
    // setSelectConsignee(newItem);
    setConsignee(newItem);
    setConsigneeList(newConsigneeList);
    // closeDrawer && closeDrawer(selectConsignee);
    closeDrawer && closeDrawer();
  };
  const onClose3 = (item: any) => {
    setConsignee(item);
    closeDrawer && closeDrawer();
  };
  // 修改搜索词
  const searchWord = (v: any) => {
    if (!v.target.value) return;
    const list = ['*', '.', ' ?', '+', '$', '^', '[', ']', '(', ')', '{', '}', '|', '\\', '/'];
    if (list.indexOf(v.target.value) > -1) {
      const v2 = '\\' + v.target.value;
      setCurrentSearchWord(v2);
    } else {
      setCurrentSearchWord(v.target.value);
    }
  };

  // 监听外层抽屉打开关闭
  useEffect(() => {
    setOpen(showDrawer);
  }, [showDrawer]);

  return (
    <div className={styles.drawerlistbox} id="AddressSelectorDrawer">
      <Drawer
        title={
          <div className={styles.drawertitle}>
            <div className={styles.title}>{`${TLT(`配送地址选择`)} (${consigneeList?.length})`}</div>
            <CloseOutlined onClick={onClose} />
          </div>
        }
        placement="right"
        closable={false}
        getContainer={() => document.getElementById('AddressSelectorDrawer') as HTMLDivElement}
        onClose={onClose}
        open={open}
        width={800}
      >
        <div className={styles.drawerlist}>
          <div className={styles.drawerlist_searchbox}>
            <Input
              onChange={searchWord}
              placeholder={TLT(`请输入要搜索的地址`)}
              prefix={<SearchOutlined className="site-form-item-icon" />}
            />
          </div>

          <div className={styles.drawerlist_listbox}>
            {consigneeList
              ?.filter(showlist => {
                if (
                  showlist.receiveName?.match(currentSearchWord) ||
                  showlist.receivePhone?.match(currentSearchWord) ||
                  showlist.country?.match(currentSearchWord) ||
                  showlist.detailAddress?.match(currentSearchWord)
                ) {
                  return showlist;
                } else {
                  return false;
                }
              })
              .map(item => {
                return (
                  <div
                    key={item.id}
                    onClick={() => {
                      onClose3(item);
                    }}
                    className={styles.addressCard}
                  >
                    <div className={`${styles.addressContent} ${item == consignee && styles.addressContentActive}`}>
                      <div className={styles.contactInfo}>
                        <span className={styles.companyName}>{item.receiveName}</span>
                        <span className={styles.phoneNumber}>{item.receivePhone}</span>
                      </div>
                      <p className={styles.addressDetails}>
                        {item?.country}
                        {item?.detailAddress}
                      </p>

                      <img
                        className={styles.addressContentIcon}
                        src={getJdiOSFile('mubla1/195960648892789537.png')}
                        alt="Edit address"
                      />
                    </div>

                    <div className={styles.line} />
                  </div>
                );
              })}
          </div>
        </div>
      </Drawer>
    </div>
  );
};
