import React, { useEffect, useState } from 'react';
import { InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { customClickReport } from '@/dataPort';
import { getRouteMetaInfo } from '@/router/router';

import styles from './index.module.scss';

let flag: any = null; // inputnumber使用，暂存计时器
interface IProps {
  errorTips?: string; // 错误提示
  min?: any; // 最小值
  max?: any; // 最大值
  value?: any; // 当前值
  stock?: any; // 库存
  step?: any; // 步长
  disabled?: boolean; // 当前是否可以用
  product?: any; // 商品主数据
  changeCallBack?: (params: any) => void; // 回调函数
  cusStyles?: {
    height: string;
    borderRadius: string;
    fontSize: string;
  };
  trackInfo?: {
    increase: string;
    decrease: string;
    input: string;
  };
}

const InputNumberPanel: React.FC<IProps> = (props: any) => {
  const {
    errorTips,
    min,
    max,
    value,
    step,
    disabled,
    changeCallBack,
    product,
    stock,
    cusStyles = {
      height: '48px',
      fontSize: '14px',
      borderRadius: '8px',
    },
    trackInfo,
  } = props;
  const { i18n, t } = useTranslation();
  const [inputMax, setInputMax] = useState(max); // 输入最大值
  const [inputMin, setInputMin] = useState(min); // 输入最小值
  const [defaultValue, setDefaultValue] = useState(value); // 默认值
  const [selfErrorTips, setSelfErrorTips] = useState(errorTips); // 错误提示

  // 比大提示 ，比小提示中间清空
  const onHandleInput = (value: any) => {
    if (+value > max) {
      setSelfErrorTips(`${TLT(`最多购买XX件`).replace('XX', max)}`);
    } else if (+value < min) {
      setSelfErrorTips(`${TLT(`最少购买XX件`).replace('XX', min)}`);
    } else {
      setSelfErrorTips('');
    }
  };
  // 防抖输入
  const debouncedInput = (value: any) => {
    if (flag) {
      clearTimeout(flag);
      flag = null;
    }
    flag = setTimeout(() => {
      onHandleInput(value);
    }, 600);
  };
  useEffect(() => {
    setDefaultValue(value);
    setInputMax(max);
    setInputMin(min);
  }, [value, max, min]);

  useEffect(() => {
    // 5000 清空错误提示
    if (errorTips) {
      setSelfErrorTips(errorTips);
    }
  }, [errorTips]);
  useEffect(() => {
    // 5000 清空错误提示
    if (selfErrorTips !== '') {
      setTimeout(() => {
        setSelfErrorTips('');
      }, 5000);
    }
  }, [selfErrorTips]);
  return (
    <div className={styles.inputNumberPanel}>
      {/*提示语 5000ms消失*/}
      {selfErrorTips && (
        <div className={`${styles.tips} ${selfErrorTips && styles.showTips}`}>
          <i className={`iconFont icon-navbar_warn ${styles.warn}`}></i>
          <span>{selfErrorTips}</span>
        </div>
      )}
      {/*antd基础组件*/}
      <InputNumber
        min={inputMin}
        max={inputMax}
        value={defaultValue}
        style={{
          height: cusStyles.height,
          borderRadius: cusStyles.borderRadius,
        }}
        onStep={e => {
          console.log(e);
        }}
        onChange={value => {
          console.log(value);
          if (value !== null && +value <= inputMax && +value >= inputMin) {
            if (+value === inputMax) {
              setSelfErrorTips(`${TLT(`最多购买XX件`).replace('XX', inputMax)}`);
            }
            changeCallBack && changeCallBack(value);
          }
        }}
        onInput={value => {
          console.log('-------');
          if (/^\d+$/.test(value)) {
            debouncedInput(value);
          }
        }}
        onClick={() => {
          try {
            const currentPath = window.location.pathname;
            const meta = getRouteMetaInfo(currentPath);
            if (meta && meta.pageId && trackInfo) {
              customClickReport(meta.pageId, trackInfo.input);
            }
          } catch (e) {
            console.log(e);
          }
        }}
        controls={false}
        addonBefore={
          <MinusOutlined
            style={{
              fontSize: cusStyles.fontSize,
              cursor: 'pointer',
            }}
            onClick={() => {
              try {
                const currentPath = window.location.pathname;
                const meta = getRouteMetaInfo(currentPath);
                if (meta && meta.pageId && trackInfo) {
                  customClickReport(meta.pageId, trackInfo.decrease);
                }
              } catch (e) {
                console.log(e);
              }

              const newValue = defaultValue - 1;
              if (newValue !== null && +newValue <= inputMax && +newValue >= inputMin) {
                if (+newValue === inputMax) {
                  setSelfErrorTips(`${TLT(`最多购买XX件`).replace('XX', inputMax)}`);
                }
                changeCallBack && changeCallBack(newValue);
              }
            }}
          />
        }
        addonAfter={
          <PlusOutlined
            style={{
              fontSize: cusStyles.fontSize,
              cursor: 'pointer',
            }}
            onClick={() => {
              try {
                const currentPath = window.location.pathname;
                const meta = getRouteMetaInfo(currentPath);
                console.log(meta, meta?.pageId, trackInfo)
                if (meta && meta.pageId && trackInfo) {
                  customClickReport(meta.pageId, trackInfo.increase);
                }
              } catch (e) {
                console.log(e);
              }

              const newValue = defaultValue + 1;
              if (newValue !== null && +newValue <= inputMax && +newValue >= inputMin) {
                if (+newValue === inputMax) {
                  setSelfErrorTips(`${TLT(`最多购买XX件`).replace('XX', inputMax)}`);
                }
                changeCallBack && changeCallBack(newValue);
              }
            }}
          />
        }
        disabled={disabled}
      />
      {/*商品文案提示*/}
      {stock !== 0 && stock > 0 && stock <= 200 && (
        <div className={styles.message}>{TLT(`仅剩XX件`).replace('XX', stock)}</div>
      )}
    </div>
  );
};

export default InputNumberPanel;
