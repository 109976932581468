import React from 'react';
import styles from './index.module.scss';
import { getJdiOSFile } from '@/util/index';

interface TimeDisplayProps {
  text: string;
  dir: string;
  onClick: (...params: any[]) => any;
}

const DropText: React.FC<TimeDisplayProps> = ({ text, dir, onClick }) => {
  return (
    <span className={`${styles.personInfo} underText`} onClick={onClick}>
      {text}{' '}
      <img
        style={{ transform: `rotate(${dir === 'up' ? '180deg' : '0'})` }}
        className={styles.arrowIcon}
        src={getJdiOSFile('mubla1/196048986162778761.png')}
        alt="Arrow icon"
      />
    </span>
  );
};

export default DropText;
