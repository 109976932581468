import React, { useCallback, useEffect, useState } from 'react';
import { Anchor, message, ConfigProvider } from 'antd';
// import { useSelector } from 'react-redux';
// import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
// import { getPrice } from '@/util';
// import { addCarMku, addCarBefore } from '@/api/SkuDetail';
// import { getCookie } from '@/util';
// import Panel from '../Main/Summary/Basic/Panel';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { customClickReportSimple } from '@/dataPort';

const { Link } = Anchor;

// https://github.com/ant-design/ant-design/issues/30584
// getCurrentAnchor 的 bug 还有，所以只能自己 onChange 同步一下

const Index = () => {
  // const checkedService = useSelector((state: any) => {
  //   return state?.SkuDetail.checkedService;
  // });
  // const main = useRecoilValue('main');
  // const amount = useRecoilValue('amount');
  const { t } = useTranslation();
  const [active, setActive] = useState('#parameter');
  // const productSpecifications = TLT(`规格参数`);
  // const productDescription = TLT(`产品介绍`);
  // const [navs, setNavs] = useState([
  //   { id: '#parameter', title: productSpecifications },
  //   { id: '#graphic', title: productDescription },
  // ]);

  const onClick = (
    e: React.MouseEvent<HTMLElement>,
    link: {
      title: React.ReactNode;
      href: string;
    },
  ) => {
    e.preventDefault();
    if (link.href == '#parameter') {
      customClickReportSimple('ProductDetail_SpecParam');
      window.location.href = '#parameter';
    } else {
      customClickReportSimple('ProductDetail_ProductIntro');
      window.location.href = '#graphic';
    }
  };

  const getCurrentAnchor = (activeLink: string) => {
    return activeLink || '#parameter';
  };

  const onChange = useCallback(active => {
    setActive(active);
    getCurrentAnchor(active);
  }, []);

  const onChange2 = useCallback(active => {
    setActive(active);
    getCurrentAnchor(active);
  }, []);
  // const price = main?.price;
  // const unit = main?.propertyList?.find(item => item.key === '销售单位')?.value;
  //
  // //加入采购清单
  // const addCart = useCallback((mkuId: number, num: number) => {
  //   addCarMku({
  //     mkuId,
  //     num,
  //     purchaseListId: 1,
  //   }).then((res: any) => {
  //     if (res.success == true) {
  //       // window.setTimeout(function() {
  //       // window.location.href = '/cart/carts';
  //       // }, 100);
  //       message.success('加入采购清单成功');
  //     } else {
  //       message.warning(res.msg);
  //     }
  //   });
  // }, []);
  //
  // //加入购物之前需要验证商品是否可售
  // const addCarBe = useCallback(async () => {
  //   const mkuIds = main?.mkuId;
  //   if (!mkuIds || !amount) {
  //     return;
  //   }
  //   const result: any = await addCarBefore({
  //     mkuIds,
  //   });
  //   if (result.success == true && result.value && result.value.saleState) {
  //     const skuId = main?.skuId;
  //     addCart(mkuIds, amount);
  //     // log('isp_info', 'add_cart', skuId, getCookie('pin'), 'skuDetail');
  //   } else if (result.success == true && result.value && !result.value.saleState) {
  //     message.warning(result.value.saleMessage);
  //   } else {
  //     message.warning(result.msg);
  //   }
  // }, [main?.mkuId, main?.skuId, amount, checkedService]);

  return (
    <div className={styles.m}>
      <ConfigProvider
        theme={{
          components: {
            Anchor: {
              linkPaddingInlineStart: 40,
            },
          },
        }}
      >
        <Anchor
          items={[
            {
              key: 'parameter',
              href: '#parameter',
              title: TLT(`规格参数`),
            },
            {
              key: 'graphic',
              href: '#graphic',
              title: TLT(`产品介绍`),
            },
          ]}
          direction="horizontal"
          targetOffset={50}
          onChange={onChange}
          onClick={onClick}
          getCurrentAnchor={() => getCurrentAnchor(active)}
        ></Anchor>
      </ConfigProvider>
    </div>
  );
};

export default Index;
