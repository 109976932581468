import React from 'react';
import moment from 'moment';
import styles from './index.module.scss';

interface TimeDisplayProps {
  timestamp: number;
}

const TimeDisplay: React.FC<TimeDisplayProps> = ({ timestamp }) => {
  const formattedDate = moment(timestamp).format('YYYY-MM-DD');
  const formattedTime = moment(timestamp).format('HH:mm:ss');

  return (
    <div className={styles.timeDisplay}>
      <div className={styles.date}>{formattedDate}</div>
      <div className={styles.time}>{formattedTime}</div>
    </div>
  );
};

export default TimeDisplay;
