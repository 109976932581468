import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import styles from './index.module.scss';
import { getJdiOSFile } from '@/util/index';
import { getLatestWares, getRecommendWares, getBrowsingVisited } from '@/api/newApi/homePage';
import Des from '@/components/Atomics/Des/des';
import Price from '@/components/Atomics/Prices/price';
import { formatMoney } from '@/util/index';
import { cx } from '@/util/tools';
import { customClickReportSimple } from '@/dataPort';

function SampleNextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={`${className} ${styles.arrow}`} onClick={onClick}>
      <img src={getJdiOSFile('mubla1/133197804730896591.png')} alt="" />
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={`${className} ${styles.arrow}`} onClick={onClick}>
      <img src={getJdiOSFile('mubla1/23816875553794655.png')} alt="" />
    </div>
  );
}

const Responsive: React.FC<{
  title: string;
  type: string;
  swipe?: boolean;
}> = ({ title, type, swipe = true }) => {
  const [products, setProducts] = useState([]);

  const init = async () => {
    const methodFunc = type === 'auto' ? getLatestWares : type === 'recently' ? getBrowsingVisited : getRecommendWares;
    const result = await methodFunc();
    if (result.success) {
      setProducts(result.value?.wares || []);
    }
  };

  useEffect(() => {
    init();
  }, []);
  const settings = {
    dots: false,
    infinite: products.length > 5 ? swipe : false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: swipe,
    swipe,
    autoplaySpeed: 6000,
    initialSlide: 0,
    ...(swipe
      ? {
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        }
      : {}),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          infinite: products.length > 4 ? swipe : false,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //     initialSlide: 2,
      //   },
      // },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };

  const toDetail = product => {
    customClickReportSimple(
      type === 'auto'
        ? 'HomePage_PreferredProductsClk'
        : type === 'recently'
        ? 'HomePage_RecentlyProductsClk'
        : 'HomePage_RecommendedProductsClk',
      product,
    );
    window._openPage(`/sku/${product.sku}`, '_blank');
  };

  return products?.length ? (
    <div className={`${styles.sliderContainer} ${!swipe ? styles.canNotswipeAble : ''}`}>
      {swipe}
      <h2 className={styles.categoryTitle}>{title}</h2>
      {products.length ? (
        <Slider arrows {...settings}>
          {products.map(product => (
            <div key={product.sku} className={styles.productInner}>
              <div className={styles.productItem}>
                <div className={styles.productImageBox} onClick={() => toDetail(product)}>
                  <img className={styles.productImage} src={product.img} alt={product.name} />
                </div>
                <div className={styles.productInfo}>
                  <h3 className={cx(styles.productTitle, 'productNameText')} onClick={() => toDetail(product)}>
                    {product.wareName}
                  </h3>
                  <div className={styles.productMeta}>
                    <Des label={TLT('商品编码')} text={product.sku} fontSize="12px" />
                  </div>
                  <Price
                    symbol={product?.showCurrency?.symbol}
                    salePrice={formatMoney(product?.showCurrency?.salePrice, false)}
                    price={formatMoney(product?.showCurrency?.price, false)}
                    type={1}
                  ></Price>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        ''
      )}
    </div>
  ) : (
    ''
  );
};

export default Responsive;
