import React, { memo } from 'react';
import styles from './index.module.scss'
export default memo((props: any) => {
  const { title } = props;
  return (
    <div className={styles.breadcrumb}>
      { title }
    </div>
  );
});
