/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2023-12-04 15:09:35
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2025-01-10 20:19:56
 * @FilePath: /isp_International/src/pages/SkuDetailV2/components/Main/Summary/Basic/Panel/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';

import { Tooltip } from 'antd';
import Amount from './Amount';
import Buy from './Buy';
import Payment from './Payment';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import { getDeliveryDate } from '@api/newApi/skudetail';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { getJdiOSFile } from '@/util/index';

const Index = () => {
  const basicInfo = useRecoilValue('basicInfo');
  const specialAttrs = useRecoilValue('specialAttrs');
  const { t, i18n } = useTranslation();
  const [deliveryDate, setDeliveryDate] = useState(''); // 错误提示

  //后端特殊属性打标对应枚举
  const Special_Enum = {
    CrossBorder:{
      0: '本土',
      1: "跨境"
    },
    FulfilPromise:{
      1:'48小时达',
      2:'72小时达',
      3:'京准达',
    }
  }

  useEffect(() => {
    setDeliveryDate(basicInfo?.deliveryDate);
  }, [basicInfo?.deliveryDate]);

  console.log(specialAttrs, 'specialAttrs')

  const handleChangeDeliveryDate = (params: any) => {
    getDeliveryDate(params).then((res: any) => {
      console.log(params, res);
      if (res?.success) {
        setDeliveryDate(res?.value?.deliveryDate);
      }
    });
  };

  //特殊打标
  const renderSpecialAttrs = (ele: any)=>{
    const {key, value} = ele;
    return TLT(Special_Enum[key][value]);
  }
  
  return (
    <div className={styles.m}>
      <div className={styles.stock}>
        {/* {basicInfo?.sourceCountryCode === 'CN' && (
          <div className={styles.productTag}>
            <img className={styles.tagIcon} src={getJdiOSFile('mubla1/195354135364234883.png')} />
            <span className={styles.tagSection}>{TLT('跨境')}</span>
          </div>
        )} */}
        {specialAttrs && !!specialAttrs.length &&  <div className={styles.specialAttrs}>
          {specialAttrs.map((ele, idx)=>{
          {/* 跨境标且是跨境品 或其它标才展示 本土标不展示*/}
          return ((ele.key === 'CrossBorder' && ele.value == '1') || ele.key !== 'CrossBorder') && <div key={idx} className={ele.key == 'CrossBorder' ? [styles.item, styles.cross].join(' ') : [styles.item, styles.time].join(' ')}>
                  {/* 跨境标才展示图片*/}
                  {ele.key === 'CrossBorder' && <img className={styles.tagIcon} src={getJdiOSFile('mubla1/195354135364234883.png')} />}
                  <span >{renderSpecialAttrs(ele)}</span>
              </div>
          } )}
        </div>
        }
        {basicInfo?.remainNum !== 0 && deliveryDate && (
          <div className={styles.deliveryDate}>
           
           
            {deliveryDate}{' '}
            <Tooltip
              title={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'start',
                  }}
                >
                  <ExclamationCircleFilled
                    style={{ color: '#faad14', cursor: 'pointer', position: 'relative', top: '3px', marginRight: 7 }}
                  />
                  <div>{TLT('重大不可抗力因素或节假日可能会导致配送延迟')}</div>
                </div>
              }
            >
              <ExclamationCircleFilled style={{ color: '#7A7E97', cursor: 'pointer' }} />
            </Tooltip>
          </div>
        )}
      </div>

      {basicInfo?.sourceCountryCode == 'CN' && (
        <div className={styles.crossTip}>
          {TLT(`跨境商品，货期和报关方式与境内贸易商品不同，敬请留意。如有疑问，请随时联系我们的运营经理`)}
        </div>
      )}

      <div className={styles.mt}>
        <div className={styles.mc}>
          <Amount
            changeDeliveryDate={(params: any) => {
              handleChangeDeliveryDate(params);
            }}
          />
          <Buy />
        </div>
        <Payment />
      </div>
    </div>
  );
};

export default Index;
