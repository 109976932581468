import i18next from 'i18next';
import React from 'react';
import { removeLocal, getLocal, LocalNameEnum } from '@/util/storage';

function bytesToBase64(text: string) {
  const bytes = new TextEncoder().encode(text);
  const binString = Array.from(bytes, byte => String.fromCodePoint(byte)).join('');
  return btoa(binString);
}

const TLT = (text: string | number, params?: Record<string, string>) => {
  const key = bytesToBase64(String(text).trim());
  return i18next.exists(key) ? i18next.t(key, params || {}) : String(text).trim();
};

// TLT = translate language tools
window.TLT = TLT;

if (getLocal(LocalNameEnum.mulLangLocalTag) === 'showLangControllerTag') {
  removeLocal(LocalNameEnum.mulLangLocalTag);
  window.TLT = (text: string | number, params?: Record<string, string>) => {
    const key = bytesToBase64(String(text).trim());
    const nodes = i18next.exists(key) ? i18next.t(key, params || {}) : String(text).trim()
    // return `${nodes}$$${text}$$`
    return `$$&&${nodes}&&##${text}##$$`
  };
}
