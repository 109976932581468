import { ThemeTypes } from './themeManage';

const antdTheme = {
  activeShadow: 'none',
  controlOutline: 0
  // colorSplit: 'none',
};
const baseComponents = {
  Button: {
    defaultShadow: 'none',
    primaryShadow: 'none',
    controlHeightLG: 48,
    colorError: '#E1251B',
    colorErrorHover: '#C3150C',
    colorErrorBorderHover: '#C3150C',
    textHoverBg: 'none',
    colorBgTextActive: 'none',
    textTextColor: '#070E32',
    textTextActiveColor: '#00678B',
    textTextHoverColor: '#00678B',
    borderRadiusLG: 4,
    paddingInlineLG: 20
  },
  Input: {
    activeShadow: 'none'
  },
  InputNumber: {
    activeBorderColor: 'none',
    hoverBorderColor: 'none',
    activeShadow: 'inherit',
  },
  Anchor: {
    fontSize: '16px',
    lineHeight: 2,
    colorSplit: 'rgba(7, 14, 50, 0.2)',
  },
  Form: {
    itemMarginBottom: 13,
    labelColor: 'rgba(7, 14, 50, 0.8)',
    verticalLabelPadding: '0 0 2px',
    labelRequiredMarkColor: '#ff4d4f'
  },
  Tooltip: {
    colorBgSpotlight: '#070E32',
    colorText: '#FFF'
  },
  Steps: {
    colorTextDescription: 'rgba(7, 14, 50, 0.5)',
    colorText: '#00B960',
    colorPrimary: '#FFF'
  },
  Card: {
    boxShadowTertiary: 'none'
  }
};

export default {
  [ThemeTypes.red]: {
    token: {
      ...antdTheme,
      colorPrimary: '#E1251B',
    },
    components: {
      ...baseComponents,
    },
  },
  [ThemeTypes.blue]: {
    token: {
      ...antdTheme,
      colorPrimary: '#00678B',
    },
    components: {
      ...baseComponents,
    },
  },
  [ThemeTypes.yellow]: {
    token: {
      ...antdTheme,
      colorPrimary: '#FFD000',
    },
    components: {
      ...baseComponents,
    },
  },
  [ThemeTypes.dark]: {
    token: {
      ...antdTheme,
      colorPrimary: '#00678B',
    },
    components: {
      ...baseComponents,
    },
  },
};
