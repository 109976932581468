import { Drawer,Button,Tabs,Checkbox,Row,Col,message, Radio, Tooltip } from "antd";
import React,{ useEffect, useState } from "react";
// import {orderConfig, orderSaveConfig} from "@/api/newApi/order";
import {
  getOrderSettingMap,
  exportOrderRequest,
  saveConfiguration
} from '@/api/newApi/order';
// import preview from "@/assets/images/newOrder/preview.png";
// import Joy from '@/assets/images/joy.svg';
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
interface ExportOrderProps {
  show:boolean,
  setShow:Function,
  selectedOrderIds:Array<string>,
  exportSetting:any,
  setOrderConfigList:(params:any)=>void;
}
const sortTitle = [
    '订单信息', 
    // '商品信息', 
    // '支付信息', 
    // '收货信息', 
    // '审批信息', 
    // '发票信息', 
    '自定义信息'
];
const ExportOrder = (props:ExportOrderProps) => {
  const { show,setShow, selectedOrderIds, setOrderConfigList, exportSetting } = props;
  const [orders,setOrders] = useState<any>();
  const [s_order,setS_order] = useState([])//选中的order
  const [s_sku,setS_sku] = useState([])//选中的sku
  const [s_pay,setS_pay] = useState([])//选中的pay
  const [s_audit,setS_audit] = useState([])//选中的audit
  const [s_print,setS_print] = useState('')//选中屏幕展示方式
  const [data, setData] = useState<any>({});
  const [keys, setKeys] = useState<string[]>([]); // 板块儿标题
  const [tabType,setTabType] = useState(1);
  const { t } = useTranslation();

  // 弹出抽屉，body禁止滚动
  useEffect(() => {
    if (show) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'scroll';
    }
  }, [show]);
  
  //excel导出设置获取
  const getSetMap = async () => {
    try {
      const result:any = {};
      if(exportSetting){
        for(const key of exportSetting){
          result[key['orderInfoName']] = key['orderInfo']
        }
      }
     
      if(result){
        const keys = result && Object.keys(result);
        Object.values(result).forEach(d => {
            d.forEach(_d => {
              _d.flag = _d.selected ? 1 : 0;
            })
          })
        setData(result);
        setKeys(keys);
      }
    } catch (e) {
      console.log(e)
    }
  }
  //保存excel导出设置配置
  const save = async (result: []) => {
    try {
      setOrderConfigList(result);
    } catch (e) {
      message.error(TLT(`保存失败，请刷新页面重试`));
    }
  }
  //检查导出信息配置
  const checked = (title: string, subtitle: string, type: string | number) => {
    const _data = JSON.parse(JSON.stringify(data));
    try {
      _data[title][+subtitle].flag = +type === 1 ? 0 : 1
      setData(_data);
    } catch (e) {
      console.log(e)
    }
  }

  function processArray(arr) {
      return arr.filter(item => item.flag === 1).map(item => {
          item.selected = true;
          delete item.flag;
          return item;
      });
  }
  
  function processData(data) {
    const newData = JSON.parse(JSON.stringify(data)); // 深拷贝数据
    for (const key in newData) {
        if (Array.isArray(newData[key])) {
            newData[key] = processArray(newData[key]);
        }
    }

    const result = Object.keys(newData).map((i, index) => {
      const info = exportSetting.find(item => item.orderInfoName === i)
      return {
        orderInfo: newData[i],
        orderInfoKey: info.orderInfoKey,
        orderInfoName: i,
        sort: index + 1
      }
    })
    return result;
}

  //导出信息提交
  const submitResult = async () => {
    await saveConfiguration({
      exportConfigs: processData(data)
    })

    try {
      const result: any = {}
      Object.entries(data).forEach(d => {
        Object.entries(d[1]).forEach(_d => {
          if (+_d[1].flag === 1) {
            if(!result[d[0]]){
              result[d[0]] = [{
                  name:_d[1].name,
                  value:_d[1].value,
                  group:_d[1].group,
                }];
            }else{
              result[d[0]].push({
                name:_d[1].name,
                value:_d[1].value,
                group:_d[1].group,
              });
            }
          }
        })
      })

     //数据进行还原
     const curExportSetting = JSON.parse(JSON.stringify(exportSetting)); 
     for(const key in result){
      const selectedConfigItemIndex = curExportSetting.findIndex(item => item.orderInfoName === key);
      curExportSetting[selectedConfigItemIndex].orderInfo = result[key];
     }
    save(curExportSetting)
    } catch (e) {
      message.error(TLT(`请求错误，请刷新页面重试`));
    }
  }
  useEffect(()=>{
    getSetMap()//导出信息数据获取
  },[show])

  const CheckBox = (props: {group: any, title: string}) => {
    const { group, title } = props;
    const checkboxGroup = Object.keys(group);
    return (
      <>
        {
          checkboxGroup.map((e) => {
            return (
              <Col span={6} key={e} className={styles.settinglist_boxs_box}>
                <Tooltip title={group[e].name}>
                  <Checkbox style={{ maxWidth: '135px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} checked={+group[e].flag === 1} onChange={() => {checked(title, e, group[e].flag)}}>
                    {
                      group[e].name
                    }
                  </Checkbox>
                </Tooltip>
              </Col>
            )
          })
        }
      </>
    )
  };
  const CheckBoxMemo = React.memo(CheckBox);

  //订单打印保存
  const savePrintConfig = async() => {
    if(tabType===1) {//excel导出设置
      submitResult();
      setShow(false);
    }else{//打印订单设置
    
      try {
          //1.将所有筛选信息保存
      const obj = {
        order:s_order,
        sku:s_sku,
        pay:s_pay,
        audit:s_audit,
        print: [s_print]
      }
        const res:any = await orderSaveConfig(obj);
        if (res?.success) {
          message.success('保存成功');
        }
        setShow(false);
      } catch (e) {
        message.error('保存失败，请刷新页面重试');
      }
    }
  }

  //订单打印配置
  const printInfomation = () => (
    <div className={styles.order_info}>
      <div className={styles.order_info_item}>
        <div className={styles.title}>打印方式配置</div>
          <Radio.Group 
              className={styles.order_list}
              onChange={(ev:any)=>setS_print(ev.target.value)}
              value={s_print}
            >
            <Row>
            {
                orders?.order && orders.print.map((item:any)=>(
                  <Col key={item.operator} span={12}>
                    <Radio value={item.operator}>{item.displayName}</Radio>
                </Col>
                ))
              }
            </Row>
          </Radio.Group>
      </div>
      <div className={styles.order_info_item}>
        <div className={styles.title}>{TLT(`订单信息`)}</div>
          <Checkbox.Group 
            style={{ width: '100%' }} 
            onChange={(e:any)=>setS_order(e)}
            className={styles.order_list}
            defaultValue={s_order}
          >
            <Row>
              {
                orders?.order && orders.order.map((item:any)=>(
                  <Col key={item.key} span={6}>
                    <Checkbox value={item.operator}>{item.displayName} {item.operator === 121 ? <Tooltip placement="topRight" title={'选择后，已经取消的订单不会进行打印。'}> <i className={`iconFont icon-navbar_doubt ${styles.ic_navbar}`}></i></Tooltip> : '' } </Checkbox>
                </Col>
                ))
              }
            </Row>
          </Checkbox.Group>
      </div>

      <div className={styles.order_info_item}>
        <div className={styles.title}>商品信息</div>
          <Checkbox.Group 
            style={{ width: '100%' }} 
            onChange={(e:any)=>setS_sku(e)}
            className={styles.order_list}
            defaultValue={s_sku}
          >
            <Row>
              {
                orders?.sku && orders.sku.map((item:any)=>(
                  <Col key={item.key} span={6}>
                    <Checkbox value={item.operator}>{item.displayName}</Checkbox>
                </Col>
                ))
              }
            </Row>
          </Checkbox.Group>
      </div>

      <div className={styles.order_info_item}>
        <div className={styles.title}>支付信息</div>
          <Checkbox.Group 
            style={{ width: '100%' }} 
            onChange={(e:any)=>setS_pay(e)}
            className={styles.order_list}
            defaultValue={s_pay}
          >
            <Row>
              {
                orders?.pay && orders.pay.map((item:any)=>(
                  <Col key={item.key} span={6}>
                    <Checkbox value={item.operator}>{item.displayName}</Checkbox>
                </Col>
                ))
              }
            </Row>
          </Checkbox.Group>
      </div>

      <div className={styles.order_info_item}>
        <div className={styles.title}>审批信息</div>
          <Checkbox.Group 
            style={{ width: '100%' }} 
            onChange={(e:any)=>setS_audit(e)}
            className={styles.order_list}
            defaultValue={s_audit}
          >
            <Row>
              {
                orders?.audit && orders.audit.map((item:any)=>(
                  <Col key={item.key} span={6}>
                    <Checkbox  value={item.operator}>{item.displayName}</Checkbox>
                </Col>
                ))
              }
            </Row>
          </Checkbox.Group>
      </div>
      <div className={styles.preview}>
        <div className={styles.title}>打印生效区域预览</div>
        <img src={''} alt=""  onError={(e: any) => {
        e.target.onerror = null;
        e.target.src = Joy;
      }}/>
      </div>
    </div>
  )
  
  const DrawerFooter = (
    <div className={styles.footer_buttons}>
      <Button 
        onClick={()=>setShow && setShow(false)}
        style={{marginRight: '10px'}}
      >
        {TLT(`取消`)}
      </Button>
      <Button 
        type="primary"
        onClick={()=>{
          //订单打印
          savePrintConfig();
        }}
      >
        {TLT(`保存`)}
      </Button>
    </div>
  )
  return (
    (<Drawer
      open={show}
      title={TLT(`订单设置`)}
      rootClassName={styles.export_order}
      footer={DrawerFooter}
      onClose={()=>setShow && setShow(false)}
    >
      <Tabs defaultActiveKey="1" onChange={(e)=>{setTabType(Number(e))}}>
        <Tabs.TabPane tab={TLT(`excel导出设置`)}key="1">
          {
            //导出信息组件
            keys.map((e, index) => {
              return (
                <div key={index} className={styles.settinglist}>
                  <p className={styles.settinglist_title}>{e}</p>
                  <div className={styles.settinglist_boxs}>
                    <CheckBoxMemo group={data[e]} title={e}></CheckBoxMemo>
                  </div>
                </div>
              );
            })
          }
        </Tabs.TabPane>

        {/* <Tabs.TabPane tab="PDF打印设置" key="2">
          {printInfomation()}
        </Tabs.TabPane> */}
      </Tabs>
    </Drawer>)
  );
}
ExportOrder.defaultProps = {
  show:false
}
export default ExportOrder;