import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Categories from '@/pages/HomePage/components/categories/categories';
import Recomend from '@/pages/HomePage/components/Recomended';
import Des from '@/pages/HomePage/components/Des';
import Power from '@/pages/HomePage/components/Power';
import Banner from '@/pages/HomePage/components/Banner';
import { isGuest } from '@/util/commonUtils';
import { getHomeSort } from '@/api/newApi/homePage';

import { message } from 'antd';

const MyComponent = () => {
  const [modules, setModules] = useState([]);
  const initConfig = async () => {
    const result = await getHomeSort();
    if (result?.success) {
      setModules(result?.value || []);
    }
  };
  useEffect(() => {
    initConfig();
  }, []);
  return (
    <div className={styles.container}>
      {modules.map(moduleItem => {
        switch (moduleItem.key) {
          case 'banner':
            return <Banner />;
          case 'recommendedCategories':
            return <Categories title={moduleItem.name} />;
          // 自动抓取推荐商品
          case 'autoRecommendedProducts':
            return <Recomend title={moduleItem.name} type="auto" />;
          // 配置的推荐商品
          case 'configedRecommendedProducts':
            return <Recomend title={moduleItem.name} type="recommend" />;
          // 最近查看商品组件
          case 'recentlyViewed':
            return <Recomend title={moduleItem.name} type="recently" swipe={false} />;
        }
      })}

      <Des />

      <Power />

      <div className={styles.bottomDes}>
        {TLT(
          '京东工业是中国值得信赖的工业产品和耗材供应商。我们致力于为客户提供他们所需的基本产品和服务。我们为海外国家的大型企业、跨国国际企业以及出海的中资企业提供端到端的海外工业品供应链服务，为客户实现出海场景的降本、增效、保供、合规。我们提供 24/7 销售和售后服务，以确保客户得到最好的支持。',
        )}
      </div>

      <div className={styles.bottomDes2}>- {TLT('让工业品国际化采购更简单')} -</div>
    </div>
  );
};

export default MyComponent;
