/*
 * @Author: duchongchong1
 * @Date: 2022-09-19 10:19:43
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-02-28 09:45:10
 * @FilePath: /isp_International/src/components/NewFooter/index.tsx
 * @Description:
 */
import React from 'react';
import styled from './index.module.scss';
import getImg from '@/util/getImg';
import { useTranslation } from 'react-i18next';
import logo from '@/assets/images/logo/京东工采国际@2x.png';
import { isIE } from '@/util';
import { getJdiOSFile } from '@/util/index';

interface IAppProps {
  type?: any;
}

const NewFooter: React.FC<IAppProps> = (props: IAppProps) => {
  const { i18n, t } = useTranslation();
  return (
    <div className={`${styled.main}`}>
      <div className={`${styled.main_box}`}>
        {/* <img
          onClick={() => {
            if (isIE()) {
              window.location.href = '/';
            } else {
              window._openPage('/', '_self');
            }
          }}
          style={{ cursor: 'pointer' }}
          src={getJdiOSFile('d1/writeLogo.png')}
          alt="JD logo"
        /> */}
        <div
          className={styled.footerLogo}
          onClick={() => {
            window._openPage('/', '_self');
          }}
        ></div>
        <div>
          <p className={`${styled.copyright}`}>{TLT('© 版权所有京东工业2025。保留所有权利')}</p>
        </div>
      </div>
    </div>
  );
};
export default NewFooter;
// import React from 'react';
// import styles from './index.module.scss';
// import { useHistory } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// function Mod() {
//   const history = useHistory();
//   const scrollToheader = () => {
//     window.scrollTo(0, 0);
//   }

//   const toBatchCart = () => {
//     scrollToheader()
//     history.push({
//       pathname: '/cart/batchAdd',
//     });
//   };
//   const userType: any = useSelector((state: any) => {
//     return state?.User?.data[0]?.userType;
//   });
//   return (
//     <div className={styles.mod}>
//       <div className={styles.cnt_col}>
//         <div className={styles.cnt_row}>
//           <div className={styles.cnt_col1}>
//             <div className={styles.footerLogo} onClick={() => {
//               window._openPage('/', '_self');
//             }}></div>
//             <span className={styles.tit}>{TLT('让工业品国际化采购更简单')}</span>
//           </div>
//           <div className={styles.cnt_row1}>
//             <div className={styles.cnt_col2}>
//               <span className={styles.txt}>{TLT('联系方式')}</span>
//               <div className={styles.cnt_col3}>
//                 <span className={`${styles.line2} ${styles.txt1}`}>
//                   经海路
//                   <br />
//                   BDA，北京，中国
//                 </span>
//                 <span className={styles.email}>cusomter-service@jd.com</span>
//                 <span className={styles.txt2}>+86 400 618 8988</span>
//               </div>
//             </div>
//             <div className={styles.cnt_col4}>
//               {/* <span className={styles.txt}>{TLT('快速链接')}</span> */}
//               <div className={styles.cnt_col5}>
//                 <span
//                   className={styles.txt3}
//                   onClick={() => {
//                     scrollToheader()
//                     history.push({
//                       pathname: '/accountSettings',
//                     });
//                   }}
//                 >
//                   {TLT('我的工作台')}
//                 </span>
//                 <span className={styles.txt2} onClick={toBatchCart}>
//                   {TLT('批量加购')}
//                 </span>
//                 <span
//                   className={styles.txt2}
//                   onClick={() => {
//                     scrollToheader()
//                     history.push({
//                       pathname: '/cart/carts',
//                     });
//                   }}
//                 >
//                   {TLT('购物车')}
//                 </span>
//                 <span
//                   className={styles.txt2}
//                   onClick={() => {
//                     scrollToheader()
//                     history.push({
//                       pathname: userType === 6 ? '/approvalList' : '/orderList',
//                     });
//                   }}
//                 >
//                   {TLT('我的订单')}
//                 </span>
//               </div>
//             </div>
//             <div className={styles.cnt_col6}>
//               <span className={styles.txt4}>语言</span>
//               <div className={styles.cnt_row2}>
//                 <img
//                   className={styles.icon}
//                   src="//img20.360buyimg.com/ling/jfs/t1/253265/5/6711/1037/67728e6dFd68d8ca3/afd2ef533d5e845c.png"
//                 />
//                 <span className={styles.instruction}>EN</span>
//                 <img
//                   className={styles.img1}
//                   src="//img10.360buyimg.com/ling/jfs/t1/254988/10/6765/157/67728e6dFb548c990/b5b5fda20ce0e055.png"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className={styles.cnt_col7}>
//           <div className={styles.cnt_row3}>
//             <span className={styles.txt5}>{TLT('© 版权所有京东工业2025。保留所有权利')}</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
// export default Mod;
