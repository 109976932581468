import React from 'react';
import { Pagination as AntPagination, PaginationProps } from 'antd';
import { customClickReport } from '@/dataPort';
import { getRouteMetaInfo } from '@/router/router';

interface CustomPaginationProps extends PaginationProps {
  trackInfo?: {
    eventId: string;
    eventParam?: Record<string, any>
  };
}

const CustomPagination: React.FC<CustomPaginationProps> = (props) => {
  const { trackInfo, onChange, ...restProps } = props;
  const currentPath = window.location.pathname;

  const meta = getRouteMetaInfo(currentPath);

  const handleChange: PaginationProps['onChange'] = (page, pageSize) => {
    // 如果传入了 onChange，则调用它
    if (onChange) {
      onChange(page, pageSize);
    }

    if (trackInfo && meta && meta.pageId) {
      customClickReport(meta.pageId, trackInfo.eventId, trackInfo.eventParam)
    }
  };

  return <AntPagination {...props} {...restProps} onChange={handleChange} />;
};

export default CustomPagination;
