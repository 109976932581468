import http from '../axios';

export function getBannerInfo(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/banner/get', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getRecommend(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/category/recommend', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getLatestWares(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', '/jdig/search/latestWares', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getRecommendWares(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('post', '/jdig/recommend/mku/list', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getHomeSort(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/settings/homepage', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

export function getBrowsingVisited(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/browsing/get', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}