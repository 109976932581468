/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-05-23 13:52:53
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-05 11:38:04
 * @FilePath: /isp_International/src/pages/Login/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import Banner from './components/banner';
import Ability from './components/ability';
import Carousel from './components/carousel';
import Foot from './components/foot';
import PIC_ENMU from './enum';

import { Affix } from 'antd';
import LazyLoad from 'react-lazyload';

interface IAppProps {}
interface BaseIF {
  title: string;
  img: any;
  subtitle: string;
  subtitle2?: string;
}
const Login: React.FC<IAppProps> = () => {
  const [isShowFix, setIsShowFix] = useState(false);
  useEffect(()=>{
    window?.log && window?.log('gc', 'login_browse');
  },[])
  const Serve = () => {
    const serveMap: BaseIF[] = [
      {
        title: '仓配一体化履约服务',
        img: PIC_ENMU.serve.serve_1,
        subtitle: '搭建企配中心，为企业客户提供工业品To B配送最后一公里交付能力。',
        subtitle2: '企业客户订单在此实现集货，并提供客户所需的个性化收货、入库等履约配送增值服务。',
      },
      {
        title: '覆盖全方位金融服务',
        img: PIC_ENMU.serve.serve_2,
        subtitle: '为优质企业客户提供的“信用赊购、分期付款、账期管理”等服务的金融产品。',
        subtitle2: '为企业评定赊销额度后，满足企业“先采购、后付款”的购物体验。',
      },
      {
        title: '闭环全链路运营服务',
        img: PIC_ENMU.serve.serve_3,
        subtitle: '打造企业专属高效的智能客服服务体系，为企业提供全方位、高效和专业的企业服务和支持。',
        subtitle2: '基于不同的企业类型和场景需求，围绕“场景+服务”提供定制化的专业售后服务。',
      },
      {
        title: '多维度智能数据看板',
        img: PIC_ENMU.serve.serve_4,
        subtitle: '从客户的组织、订单、商品等多维度提供海量数据的智能分析，精准计算结算周期内的采购成本节约情况。',
        subtitle2: '通过采购频次、采购品类，为采购人员提供精准推送，提高选品效率。',
      },
    ];
    return (
      <div className={`${style.login_serve}`}>
        <p className={`${style.login_serve_title}`}>
          <a id={`d3`} className={`${style.login_serve_title_a}`}></a>
          全方位增值服务 保障客户落地支撑
        </p>
        <div className={`${style.login_serve_body}`}>
          {serveMap.map((e, index) => {
            return (
              <div className={`${style.serve_box}`} key={index}>
                <LazyLoad offset={100}>
                  <img src={e.img} className={`${style.serve_box_img}`} />
                </LazyLoad>
                <div className={`${style.serve_box_right}`}>
                  <img src={PIC_ENMU.serve[`serve_num${index + 1}`]} className={`${style.serve_box_right_number}`} />
                  <div className={`${style.serve_box_right_title}`}>{e.title}</div>
                  <div className={`${style.serve_box_right_subtitle}`}>
                    {e.subtitle}
                    <span className={`${style.serve_box_right_subtitle2}`}>{e.subtitle2}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const Case = () => {
    const caseeMap: BaseIF[] = [
      {
        title: '中建八局',
        img: PIC_ENMU.case.case_top_1,
        subtitle:
          '通过多级审批账号体系支撑订单管理、订单审批、角色权限等产品能力，并提供海量商品、金融账期、企配仓等服务，辅助中建八局降本增效。',
      },
      {
        title: '长安汽车',
        img: PIC_ENMU.case.case_top_2,
        subtitle:
          '全面分析长安汽车采购流程，优选上万种商品，助力长安汽车MRO和办公集采实现电商化采购，以及采购数据可视化。',
      },
      {
        title: '铁法煤业',
        img: PIC_ENMU.case.case_top_3,
        subtitle: '根据铁法煤业的电商化采购需求，帮助企业简化涉及订单审批，订单管理，商品选型，供应链管理等采购流程。',
      },
      { title: '', img: PIC_ENMU.case.case_1, subtitle: '' },
      { title: '', img: PIC_ENMU.case.case_2, subtitle: '' },
      { title: '', img: PIC_ENMU.case.case_3, subtitle: '' },
      { title: '', img: PIC_ENMU.case.case_4, subtitle: '' },
      { title: '', img: PIC_ENMU.case.case_5, subtitle: '' },
      { title: '', img: PIC_ENMU.case.case_6, subtitle: '' },
      { title: '', img: PIC_ENMU.case.case_7, subtitle: '' },
      { title: '', img: PIC_ENMU.case.case_8, subtitle: '' },
      { title: '', img: PIC_ENMU.case.case_9, subtitle: '' },
      { title: '', img: PIC_ENMU.case.case_10, subtitle: '' },
    ];
    return (
      <div className={`${style.login_case}`}>
        <div className={`${style.login_case_title}`}>
          <a id={`d4`} className={`${style.login_case_title_a}`}></a>
          服务客户行业案列
        </div>
        <div className={`${style.login_case_cases}`}>
          <div className={`${style.cases_boxs}`}>
            {caseeMap.map((e, index) => {
              return (
                <div className={`${style.boxs_box}`} key={index}>
                  <LazyLoad offset={100}>
                    <img src={e.img} className={`${style.box_img}`} />
                  </LazyLoad>
                  <div className={`${style.box_title}`}>{e.subtitle}</div>
                  <i className={`${style.box_i}`} style={PIC_ENMU.case.case_icon}></i>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const showFixTitle = (affixed) => {
    setIsShowFix(affixed);
  };

  return (
    <div className={`${style.app} ${style.login}`}>
      <Banner />
      {/*<Affix*/}
      {/*  offsetTop={0}*/}
      {/*  onChange={affixed => showFixTitle(affixed)}*/}
      {/*  style={{ position: 'absolute', top: 540, left: 0, width: '100%', height: '70px' }}*/}
      {/*>*/}
      {/*  {*/}
      {/*    <div*/}
      {/*      className={`${style.login_addixed_title}`}*/}
      {/*      style={{*/}
      {/*        overflow: isShowFix ? '' : 'hidden',*/}
      {/*        height: isShowFix ? '70px' : 0,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <div className={`${style.title_box}`}>*/}
      {/*        <div className={`${style.title_box_name}`}>*/}
      {/*          <img src={PIC_ENMU.banner.banner_big_title_blue} />*/}
      {/*        </div>*/}
      {/*        <div className={`${style.title_box_afix}`}>*/}
      {/*          <a href="#d1">产品能力</a>*/}
      {/*          <a href="#d2">商品能力</a>*/}
      {/*          <a href="#d3">服务优势</a>*/}
      {/*          /!*<a href="#d4">客户案例</a>*!/*/}
      {/*        </div>*/}
      {/*        <div className={`${style.title_box_more}`}>*/}
      {/*          <div className={`${style.title_box_more_scancode}`}>*/}
      {/*            <i className='iconFont icon-scanning' style={{marginRight: '8px'}}/>*/}
      {/*            <span style={{width: '104px'}}>*/}
      {/*              /!*京东工采APP*!/*/}
      {/*            </span>*/}
      {/*            <div className={`${style.scancode_QRcode}`}>*/}
      {/*              <img src={PIC_ENMU.common.QRcode} />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className={`${style.title_box_more_telephone}`}>*/}
      {/*            <img src={PIC_ENMU.banner.banner_title_telephone} />*/}
      {/*            400-618-8998*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*</Affix>*/}


      <Ability />
      {/*<Carousel />*/}
      {/*<Serve />*/}
      {/*<Case />*/}
      <Foot />
    </div>
  );
};
export default Login;
