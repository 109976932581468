/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-07-16 16:05:57
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-20 20:51:09
 * @FilePath: /isp_International/src/pages/OrderDetailNew/components/Goods/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState } from 'react';
import { message, Button, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { dispatchShopCarNum } from '@/redux/action/index';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { formatMoney, cnyMoney } from '@/util/index';
import styles from './index.module.scss';
import { addProducts } from '@api/newApi/orderdetail';
import { formatOrderDetail } from '@/util/formatServiceStuff';
import ServiceStuff from '@/components/ServiceStuff';
import cookies from 'react-cookies';
import Des from '@/components/Atomics/Des/des';
import Price from '@/components/Atomics/Prices/price';
import Tag from '@/components/Atomics/Tags/tags';
import Big from 'big.js'

interface IAppProps {
  goodsList: any;
  hasSplitFreight: boolean;
  serviceSkus: any;
}
const Goods: React.FC<IAppProps> = (props: any) => {
  const { t } = useTranslation();
  const { goodsList } = props;
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0'); // 当前站点是否本土

  const store: any = useSelector(state => {
    return state;
  });

  // 获取用户操作权限
  const userLimit: any = useSelector((state: any) => {
    return state?.UserLimit;
  });
  const pin = store?.User?.data[0]?.pin ?? '';
  const dispatch = useDispatch();

  const formatGoodsList = formatOrderDetail(goodsList);

  //加入购物
  const addCarBe = async (num: any, item: any) => {
    if (num === 1) {
      const result: any = await addProducts([
        {
          sku: item.sku,
          num: 1,
          price: item.price,
        },
      ]);
      if (result.success == true) {
        message.success(TLT(`加入采购清单成功`));
        dispatch(dispatchShopCarNum(result.value.totalKindCount));
      } else {
        message.error(result.msg);
      }
    } else {
      message.info(TLT(`该商品不可售`));
    }
  };

  return (
    <div className={styles.goodsList}>
      <div className={styles.title}>{TLT(`商品清单`)}</div>
      <div className={styles.boxs}>
        <div className={styles.productListHeader}>
          <span className={`${styles.productInfo} ${styles.col1}`}>{TLT(`商品`)}</span>
          <span className={`${styles.price} ${styles.col2}`}>{TLT(`价格`)}</span>
          <span className={`${styles.quantity} ${styles.col3}`}>{TLT(`数量`)}</span>
          <span className={`${styles.total} ${styles.col4}`}>{TLT(`小计`)}</span>
          <span className={`${styles.action} ${styles.col5}`}>{TLT(`操作`)}</span>
        </div>

        {/* <div className={`${styles.gList} ${styles.top}`}>
          <div className={`${styles.a} ${styles.item}`}>{TLT(`商品`)}</div>
          <div className={`${styles.c} ${styles.item}`}>{TLT(`价格`)}</div>
          {isLocalPro && <div className={`${styles.c} ${styles.item}`}>{TLT(`是否报关`)}</div>}
          <div className={`${styles.g} ${styles.item}`}>{TLT(`数量`)}</div>
          {userLimit?.limitData?.enableMaterial == 1 && (
            <div className={`${styles.e} ${styles.item}`}>{TLT(`物料编码`)}</div>
          )}
          <div className={`${styles.d} ${styles.item}`}>{TLT(`单位`)}</div>
          <div className={`${styles.e} ${styles.item}`}>{TLT(`小计`)}</div>
          <div className={`${styles.f} ${styles.item}`}>{TLT(`操作`)}</div>
        </div>
         */}

        <div className={styles.goodsInfo}>
          {formatGoodsList?.map((item: any, index: number) => {
            return (
              <div className={`${styles.gList} ${styles.bottom}`} key={index}>
                <div className={`${styles.a} ${styles.item} ${styles.col1}`}>
                  <div className={styles.top}>
                    <img src={item.skuImg} className={styles.img} />

                    <div className={styles.right}>
                      <div
                        className={styles.name}
                        // href={`/sku/${item.sku}`}
                        title={item.name}
                        onClick={e => {
                          if (!item.sku) {
                            e.preventDefault();
                            message.error(`${TLT(`商品`)}${item.thirdSkuId}${TLT(`不在商品池`)}`);
                          }
                        }}
                      >
                        {`${item.skuName}`}
                      </div>

                      <div className={styles.dess}>
                        <Des label={TLT(`商品编号`)} text={item.sku}></Des>
                        {userLimit?.limitData?.enableMaterial == 1 && (
                          <Des label={TLT(`物料编码`)} text={item?.mkuMaterial?.materialId || '-'}></Des>
                        )}

                        <Des label={TLT(`单位`)} text={item?.saleUnit} copy={false}></Des>
                        {/* <Des
                            label={TLT(`未税价`)}
                            text={item.symbol + cnyMoney(item.sellingPrice, false)}
                            copy={false}
                          ></Des>
                          <Des
                            label={TLT(`含税价`)}
                            text={item?.symbol + formatMoney(item?.taxSalePrice, false)}
                            copy={false}
                          ></Des> */}
                        <Des
                          label={TLT(`税率`)}
                          text={item?.valueAddedTaxRate ? new Big(item?.valueAddedTaxRate || 0).times(100) + '%' : '0'}
                          copy={false}
                        ></Des>
                        {isLocalPro && (
                          <Des
                            label={TLT(`是否报关`)}
                            text={
                              isLocalPro && item?.customsClearance == 1
                                ? TLT(`是`)
                                : item?.customsClearance == 0
                                ? TLT(`否`)
                                : '-'
                            }
                            copy={false}
                          ></Des>
                        )}
                        {item?.sourceCountryCode === 'CN' ? <Tag type="cross" /> : ''}
                      </div>
                    </div>
                  </div>

                  {/* <div className={styles.bottom}>
                      <ServiceStuff data={item?.gift} />
                    </div> */}
                </div>
                {/* <div className={`${styles.b} ${styles.item}`}>
                    <p className={`${styles.single} ${styles.s}`} title={item.specifications}>
                      {item.specifications}
                    </p>
                  </div> */}
                {/* <div className={`${styles.c} ${styles.item}`}>
                    <p className={`${styles.single} ${styles.s} ${styles.b}`}>
                      <div>
                        {' '}
                        {isLocalPro && TLT(`未税价`)} {item.symbol + cnyMoney(item.sellingPrice, false)}
                      </div>
                      {isLocalPro && (
                        <div>
                          {TLT(`含税价`)} {item?.symbol + formatMoney(item?.taxSalePrice, false)}
                        </div>
                      )}
                      {isLocalPro && (
                        <div>
                          {TLT(`税率`)} {item?.valueAddedTaxRate ? item?.valueAddedTaxRate * 100 + '%' : 0}
                        </div>
                      )}
                    </p>
                  </div> */}
                {/* {isLocalPro && (
                    <div className={`${styles.c} ${styles.item}`}>
                      <p className={`${styles.single} ${styles.s} ${styles.c}`}>
                        {isLocalPro && item?.customsClearance == 1
                          ? TLT(`是`)
                          : item?.customsClearance == 0
                          ? TLT(`否`)
                          : '-'}
                      </p>
                    </div>
                  )} */}
                <div className={`${styles.col2}`}>
                  <Price
                    symbol={item?.symbol}
                    salePrice={formatMoney(item?.taxSalePrice, false)}
                    price={cnyMoney(item.sellingPrice, false)}
                    type={3}
                  ></Price>
                </div>

                <div className={`${styles.col3}`}>
                  <p className={styles.price}>x {item.skuNum}</p>
                </div>

                {/* {userLimit?.limitData?.enableMaterial == 1 && (
                    <div className={`${styles.e} ${styles.item}`}>
                      <p className={`${styles.single} ${styles.s} ${styles.b}`}>
                        {item?.mkuMaterial?.materialId || '-'}
                      </p>
                    </div>
                  )} */}
                {/* <div className={`${styles.d} ${styles.item}`}>
                    <p className={`${styles.single} ${styles.s} ${styles.b}`}>{item?.saleUnit}</p>
                  </div> */}
                <div className={`${styles.col4}`}>
                  <Price
                    symbol={item?.symbol}
                    salePrice={cnyMoney(item.totalPrice, false)}
                    price={cnyMoney(item.untaxedTotalPrice, false)}
                    type={3}
                  ></Price>
                </div>
                <div className={`${styles.col5}`}>
                  <Button
                    color="primary"
                    variant="outlined"
                    shape="round"
                    className='wrapBtn'
                    onClick={() => {
                      addCarBe(1, item);
                    }}
                  >
                    {TLT(`加入购物车`)}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Goods;
