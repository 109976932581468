import http, { messageHttp } from '../axios';

export function getUserConfig(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/settings/page', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}