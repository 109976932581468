/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-04-15 18:04:25
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-22 10:18:54
 * @FilePath: /isp_International/src/pages/Approval/List/Select/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next';
type IAppProps = {
  options:any,
  onChange:any,
  defaultValue?:any
}

// 单选选择框组件
const Index: React.FC<IAppProps> = (props) => {
  const { options, onChange,defaultValue } = props;
  const [showSelect,setShowSelect] = useState(false); //是否展示下拉框
  const [checked,setChecked] = useState(''); // 单选选中的选项
  const { t } = useTranslation();


  // 单击选择
  const onSelect = (item:any)=>{
    // 更新选中项
    setChecked(item.label);
    // 调用回调函数
    onChange(item.value);
    // 隐藏下拉框
    setShowSelect(false)
  }

  // 初始化选项
  useEffect(()=>{
    defaultValue && onSelect(defaultValue);
  },[])

  return (
    <div className={styles.m}>
      {/*输入框*/}
      <Input
        className={styles.select}
        autoFocus={false}
        placeholder={TLT(`请选择`)}
        value={checked}
        allowClear={false}
        onClick={()=>setShowSelect((state)=>{ return !state })}
        suffix={showSelect ? <i className='iconFont icon-navbar' style={{color:'#6C727C', fontSize:"12px"}}/> : <i className='iconFont icon-navbar_expand' style={{color:'#6C727C', fontSize:"12px"}}/>}
      />
      {/*下拉框*/}
      <div className={styles.list} style={{display:showSelect ? 'block' : 'none'}}>
        {options && options.map((item:any)=>{
          return (
            <div className={`${styles.item} ${checked == item.label && styles.item_checked}`} key={item.value} onClick={()=>onSelect(item)}>
              <span>{item.label}</span>
              <span>{checked == item.label && <i className='iconFont icon-navbar_chosen' style={{color:'#00678B', fontSize:"12px"}}/> }</span>
            </div>
          )
        })}
      </div>
    </div>)
};

export default Index