/*
 * @Author: your name
 * @Date: 2021-11-01 13:44:29
 * @LastEditTime: 2025-01-03 19:51:42
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: In User Settings Edit
 * @FilePath: /isp_International/src/components/NavBar/index.tsx
 */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import styled from './index.module.scss';

import Drawers from './Drawer/index';

// import Drawers from './NewDrawer/index';
import cookies from 'react-cookies';
import { GetRequest, isIE } from '@/util';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { domainCookieUrl } from '@/enum/config';
import { isGuest } from '@/util/commonUtils';
import moment from 'moment';
import data from './setting.json';
import { dispatchShopCar, dispatchUser, dispatchUserLimit } from '@/redux/action';
import { getUser, logout } from '@/api/header';
import { getAd, getAuthority, getChangeEnv, getChangeStation, getQuickButtons } from '@/api/newApi/header';
import LazyLoad from 'react-lazyload';
import Search from './search';
import 英国 from '@/assets/images/nationalFlag/英国@2x.png';
import Vnpic from '@/assets/images/nationalFlag/vn.png';
import Magyarpic from '@/assets/images/nationalFlag/匈牙利@2x.png';
import { logoutHandler } from '@/util/login';
import { useOpenDir } from '@/util/hooks/openPage';
import { usePermissions } from '@/util/hooks/checkPermissions';
import { message, Affix } from 'antd';
import { getJdiOSFile } from '@/util/index';
import { customClickReportSimple } from '@/dataPort';

export interface IAppProps {
  location?: any;
}

//国旗图片
const nationLogoMap = {
  zh: getJdiOSFile('mubla1/192653958987833483.png'),
  vi: Vnpic,
  en: 英国,
  hu: Magyarpic,
  'pt-BR': getJdiOSFile('mubla1/18559604890965297.png'),
  th: getJdiOSFile('mubla1/127940534056366544.png'),
  id: getJdiOSFile('mubla1/18559604882206354.png'),
  zh_Hant: getJdiOSFile('mubla1/192653958987833483.png'),
};

const Menu: React.FC<IAppProps> = (props: IAppProps) => {
  const { func, style } = data;
  const { location } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [userInfo, setUserInfo] = useState<any>({});
  const [ad, setAdData] = useState<any>([]);
  const [showExit, setShowExit] = useState<boolean>(false);
  const [showShopType, setShowShopType] = useState<boolean>(false); //商品类型
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0'); // 当前站点是否本土
  const [show_ZH, setShow_ZH] = useState<boolean>(false);
  const [languageList, setLanguageList] = useState(null); //语言列表
  const [quickButtons, setQuickButtons] = useState<
    Array<{
      name: string;
      jumpUrl: string;
      openType: number;
    }>
  >([]); //语言列表
  const [countryNameMap, setcountryNameMap] = useState<any>(null); //站点名称
  const dispatch = useDispatch();
  const url: any = GetRequest();
  const openDir = useOpenDir();

  const { checkPermission } = usePermissions();

  // 语言切换
  const changeLanguage = async (type: any) => {
    if (i18n.language == type) return;
    try {
      //巴西语后端使用切换下划线
      const userData: any = (await getChangeEnv({ env: type == 'pt-BR' ? 'pt_BR' : type })) as any;
      if (userData.success) {
        window.localStorage.setItem('_language', type);
        // 切中英文，清空搜索关键词，产品定的
        window.localStorage.setItem('iv', '');
        window.location.reload()
      }
    } catch (error) {
      console.log(error, '0000');
    }
  };

  /**
   * 保存cookie
   */
  const saveCookie = (key: string, value: string) => {
    cookies.save(key, value, {
      domain: domainCookieUrl,
      path: '/',
      // sameSite:'none',
      expires: moment()
        .add(1, 'months')
        .toDate(),
    });
  };

  /**
   * 切换站点 type 0 本土 跨境
   */
  const changeStationOper = async (type: string) => {
    const resData = await getChangeStation({
      stationType: type,
    });
    saveCookie('stationType', type);
    //切换站点 清空历史记录
    window.localStorage.setItem('iv', '');
    window?.open('/', '_self');
  };

  // 获取广告数据
  const fetchData = async () => {
    const adData: any = (await getAd()) as any;
    setAdData(adData.value);
  };
  const global_data: any = useSelector(state => {
    return state;
  });
  //是否是IE白名单
  const clientOpenWindow: any = useSelector((state: any) => {
    return state?.User?.data[0]?.clientOpenWindow;
  });
  // 获取用户类型
  const userType: any = useSelector((state: any) => {
    return state?.User?.data[0]?.userType;
  });

  //用户可访问站点列表
  const stationsList = useSelector((state: any) => {
    return state?.User?.data[0]?.stations || [];
  });

  //获取用户操作权限
  const getUserLimit = async () => {
    const resData = await getAuthority();
    if (resData?.success) {
      dispatch(dispatchUserLimit(resData?.value));
    } else {
      message.error(resData?.message);
    }
  };

  // 获取用户信息
  const getUserInfo = async () => {
    let station_list = [];
    const stationType = cookies.load('stationType');
    const userData: any = (await getUser()) as any;

    if (userData.success) {
      station_list = userData?.value?.stations;
      setLanguageList(userData?.value?.language);
      setcountryNameMap(userData?.value?.countryNameMap);
      setUserInfo(userData.value);
      dispatch(dispatchUser(userData.value));

      //当前语言是否在登陆的客户语言列表里
      const isNeedLoadLanguage = userData?.value?.language && !userData?.value?.language[i18n.language];
      console.log(!stationType || station_list.indexOf(+stationType) === -1, 'shshjshdjd');
      console.log(isNeedLoadLanguage, 'isNeedLoadLanguage');
      //登陆账号站点跟当前缓存站点不匹配就再次重置页面
      if (!stationType || station_list.indexOf(+stationType) === -1) {
        saveCookie('stationType', station_list[0]);
        window.localStorage.setItem('iv', '');
        window?.open('/', '_self');
        return;
      }

      //重新载入语言
      if (isNeedLoadLanguage) {
        window.localStorage.setItem('_language', 'zh');
        window?.open(window?.location?.href, '_self');
      }
    } else {
      setUserInfo({});
    }
  };

  const initQuickButtons = async () => {
    const result = await getQuickButtons();
    console.log(result);
    if (result?.success) {
      setQuickButtons(result?.value || []);
    }
  };
  // 初始化获取用户信息
  useEffect(() => {
    initQuickButtons();
    getUserInfo();
    // 获取用户操作权限
    if (!isGuest()) {
      getUserLimit();
    }
  }, []);
  //退出登录
  const logoutFn = () => {
    logout().then(res => {
      console.log(res);
      window.location.href = WISP_LOGIN_URL;
      // logoutHandler(() => {
      //   setShowExit(false);
      // });
    });
  };
  // 展开组件
  const showDrawer = () => {
    setVisible(true);
    log('jdisp', 'TopBar_Cat');
  };
  useEffect(() => {
    if (!isGuest()) {
      dispatch(dispatchShopCar());
    }
  }, []);
  // 关闭组件
  const onClose = () => {
    setVisible(false);
  };

  const toBatchCart = () => {
    history.push({
      pathname: '/cart/batchAdd',
    });
  };

  const userTypeText: Record<number, string> = {
    4: '管理员',
    5: '采购员',
    6: '审批员',
  };

  console.log(languageList, 'languageList');
  return (
    <>
      <div className={`${styled.nav}`} id="__position">
        <div className={`${styled.navContent}`}>
          <ul>
            {/* 商品分类 */}
            {/* <li className={`${styled.category}`} onClick={showDrawer}>
            <i className={`${styled.allcate}`}></i>
            <span>{TLT(`商品分类`)}</span>
          </li> */}
            <li
              className={styled.logo}
              data-track={JSON.stringify({
                eventId: 'Logo',
              })}
              onClick={() => {
                window.location.href = '/';
              }}
            >
              {/* <LazyLoad height={20}>
              <img
                className={styled.logo}
                src={getJdiOSFile('d1/writeLogo.png')}
                alt="企业logo"
                onClick={() => {
                  window.location.href = '/';
                }}
              ></img>
            </LazyLoad> */}
            </li>
            {location.pathname === '/' ? (
              isLocalPro ? (
                ''
              ) : (
                <>
                  {ad.map((item: any, index: any) => {
                    if (item) {
                      return (
                        <li key={index}>
                          <a
                            key={index}
                            href={item.httpUrl}
                            target={isIE() && clientOpenWindow ? '_self' : '_blank'}
                            rel="noreferrer"
                            onClick={() => {
                              log('jdisp', 'TopBar_Ad' + index + 1);
                            }}
                          >
                            {item.adName}
                          </a>
                        </li>
                      );
                    }
                  })}
                </>
              )
            ) : (
              <>
                {/* {location.pathname === '/cart/carts' && (
                <>
                  <li className={`${styled.spacer}`}></li>
                  <li className={`${styled.category_name}`}>
                    <span
                      onClick={() => {
                        history.push({
                          pathname: '/accountSettings',
                        });
                      }}
                    >
                      {TLT(`采购清单`)}
                    </span>
                  </li>
                </>
              )}
              {location.pathname === '/order/goOrderInfo' && (
                <>
                  <li className={`${styled.spacer}`}></li>
                  <li className={`${styled.category_name}`}>
                    <span
                      onClick={() => {
                        history.push({
                          pathname: '/accountSettings',
                        });
                      }}
                    >
                      {TLT(`结算`)}
                    </span>
                  </li>
                </>
              )}
              {!['/page/list', '/order/goOrderInfo', '/cart/carts'].includes(location.pathname) && (
                <>
                  <li className={`${styled.spacer}`}></li>
                  <li className={`${styled.category_name}`}>
                    <span
                      onClick={() => {
                        history.push({
                          pathname: '/accountSettings',
                        });
                      }}
                    >
                      {TLT(`我的工作台`)}
                    </span>
                  </li>
                </>
              )} */}
              </>
            )}
          </ul>
          <div className={`${styled.con_search}`}>
            <Search clientOpenWindow={clientOpenWindow} {...func} {...style} {...props}></Search>
          </div>

          <div className={`${styled.shop}`}>
            <div className={`${styled.fr}`}>
              {!isGuest() && (
                <ul className={`${styled.frUl}`}>
                  <li
                    className={`${styled.exit_box}`}
                    onMouseEnter={() => {
                      setShow_ZH(true);
                    }}
                    onMouseLeave={() => {
                      setShow_ZH(false);
                    }}
                  >
                    <span
                      onClick={() => {
                        setShow_ZH(!show_ZH);
                      }}
                    >
                      <a target="_blank" className={styled.flagContainer} rel="noreferrer">
                        {languageList && (
                          <>
                            <img className={styled.flagImage} src={nationLogoMap[i18n.language] || ''} />
                            {languageList[i18n.language]}
                          </>
                        )}
                      </a>
                    </span>
                    <i
                      className={`${styled.user}`}
                      onClick={() => {
                        setShow_ZH(!show_ZH);
                      }}
                    ></i>
                    <ul
                      className={`${styled.languageMenu}`}
                      style={{ display: show_ZH ? 'block' : 'none' }}
                      onMouseEnter={() => {
                        setShow_ZH(true);
                      }}
                    >
                      {languageList &&
                        Object.keys(languageList)
                          .reverse()
                          .map((item, idx) => {
                            return (
                              <li
                                key={idx}
                                className={`${styled.exit}`}
                                onClick={() => {
                                  setShow_ZH(false);
                                  changeLanguage(item);
                                }}
                              >
                                <img src={nationLogoMap[item] || ''} />
                                {languageList[item]}
                              </li>
                            );
                          })}
                    </ul>
                  </li>

                  <li className={`${styled.spacer}`}></li>

                  <li
                    className={`${styled.exit_box}`}
                    onMouseEnter={() => {
                      setShowExit(true);
                    }}
                    onMouseLeave={() => {
                      setShowExit(false);
                    }}
                  >
                    <span
                      onClick={() => {
                        setShowExit(!showExit);
                        log('jdisp', 'TopBar_Pin');
                      }}
                    >
                      <a rel="noreferrer">{userInfo.nickName}</a>
                    </span>
                    <i
                      className={`${styled.user}`}
                      onClick={() => {
                        setShowExit(!showExit);
                      }}
                    ></i>
                    <ul
                      className={`${styled.userList}`}
                      style={{ display: showExit ? 'block' : 'none' }}
                      onMouseEnter={() => {
                        setShowExit(true);
                      }}
                    >
                      <li className={`${styled.exit}`}>
                        <span
                          data-track={JSON.stringify({
                            eventId: 'Workspace',
                          })}
                          onClick={() => {
                            history.push({
                              pathname: '/accountSettings',
                            });
                            log('jdisp', 'TopBar_Workbench');
                          }}
                        >
                          {TLT(`我的工作台`)}
                        </span>
                      </li>

                      {/* 批量加购物车 */}
                      <li
                        className={`${styled.exit}`}
                        data-track={JSON.stringify({
                          eventId: 'BatchAddCart',
                        })}
                        onClick={toBatchCart}
                      >
                        {TLT(`批量加购`)}
                      </li>

                      {checkPermission('displayLogout') && (
                        <li
                          className={`${styled.exit}`}
                          data-track={JSON.stringify({
                            eventId: 'Logout',
                          })}
                          onClick={() => {
                            logoutFn();
                          }}
                        >
                          {TLT(`退出登录`)}
                        </li>
                      )}
                    </ul>
                  </li>
                  <li className={`${styled.spacer}`}></li>
                  <li
                    onClick={() => {
                      history.push({
                        pathname: userType === 6 ? '/approvalList' : '/orderList',
                      });
                      log('jdisp', 'TopBar_OrderCenter');
                    }}
                  >
                    <span
                      data-track={JSON.stringify({
                        eventId: 'OrderManagement',
                      })}
                    >
                      {TLT(`订单中心`)}
                    </span>
                  </li>
                  <li className={`${styled.spacer}`}></li>
                  {/* 购物车 */}
                  <li className={styled.shopCar}>
                    <div
                      className={`${styled.btnIcon_1} ${styled.order}`}
                      onClick={() => {
                        customClickReportSimple('Cart');
                        history.push({
                          pathname: '/cart/carts',
                        });
                        log('jdisp', 'TopBar_Cart');
                      }}
                    >
                      <i className={`${styled.shopIcon_1}`}></i>
                      <i className={`${styled.ci_count}`}>{global_data.User && global_data.User.carNum}</i>
                    </div>
                  </li>
                </ul>
              )}
              {/* {对客展示内容} */}
              {isGuest() && (
                <ul>
                  <li
                    className={`${styled.exit_box}`}
                    onMouseEnter={() => {
                      setShow_ZH(true);
                    }}
                    onMouseLeave={() => {
                      setShow_ZH(false);
                    }}
                  >
                    <span
                      onClick={() => {
                        setShow_ZH(!show_ZH);
                      }}
                    >
                      <a target="_blank" rel="noreferrer">
                        {languageList && languageList[i18n.language]}
                      </a>
                    </span>
                    <i
                      className={`${styled.user}`}
                      onClick={() => {
                        setShow_ZH(!show_ZH);
                      }}
                    ></i>
                    <ul
                      className={`${styled.languageMenu}`}
                      style={{ display: show_ZH ? 'block' : 'none' }}
                      onMouseEnter={() => {
                        setShow_ZH(true);
                      }}
                    >
                      {languageList &&
                        Object.keys(languageList)
                          .reverse()
                          .map((item, idx) => {
                            return (
                              <li
                                key={idx}
                                className={`${styled.exit}`}
                                onClick={() => {
                                  setShow_ZH(false);
                                  changeLanguage(item);
                                }}
                              >
                                <img src={nationLogoMap[item] || ''} />
                                {languageList[item]}
                              </li>
                            );
                          })}
                    </ul>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <Drawers visible={visible} onClose={onClose} {...props}></Drawers>
      </div>

      <div className={styled.container}>
        <div className={styled.containerInfo}>
          <div className={styled.quickBtns}>
            <span className={styled.containerInfoItem} onClick={showDrawer}>
              <img
                className={styled.categoriesIcon}
                src="//img13.360buyimg.com/ling/jfs/t1/238616/23/28362/136/675fd300Fd23045cc/44c9fab8b5d6afc4.png"
                alt="Categories"
              />
              <span
                className={styled.categoriesText}
                data-track={JSON.stringify({
                  eventId: 'CategoryButton',
                })}
              >
                {TLT(`商品分类`)}
              </span>
            </span>
            {quickButtons.map((button, index) => {
              return (
                <span key={index}>
                  <span className={styled.spacer}></span>
                  <span
                    className={styled.containerInfoItem}
                    onClick={() => {
                      openDir(button);
                    }}
                  >
                    {button.name}
                  </span>
                </span>
              );
            })}
          </div>

          {!isGuest() && userInfo.nickName && userTypeText[userInfo.userType] && (
            <div className={styled.rightTextColor}>
              <span className={styled.greetingText}>
                {TLT('嗨')}, {userInfo.nickName}
              </span>
              <span className={styled.spacer}></span>
              <span className={styled.accountText}>{TLT(userTypeText[userInfo.userType])}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Menu;
