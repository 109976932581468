export default {
  /**
   * Get the base class for Subline's exposure log
   * @returns {Promise<() => void>} window.expLogJSON
   */
  getExpLogJSON: () => {
    return new Promise((resolve) => {
      if ((window as any).expLogJSON) {
        resolve((window as any).expLogJSON);
      } else {
        const interval = setInterval(() => {
          if ((window as any).expLogJSON) {
            clearInterval(interval);
            resolve((window as any).expLogJSON);
          }
        }, 50);
      }
    });
  },
  /**
   * Get the log function for Subline's normal log
   * @returns {Promise<() => void>} window.log
   */
  getLog: () => {
    return new Promise((resolve) => {
      if ((window as any).log) {
        resolve((window as any).log);
      } else {
        const interval = setInterval(() => {
          if ((window as any).log) {
            clearInterval(interval);
            resolve((window as any).log);
          }
        }, 50);
      }
    });
  },

  /**
   * Get the logJSON function for Subline's normal log
   * @returns {Promise<() => void>} window.logJSON
   */
  getLogJSON: () => {
    return new Promise((resolve) => {
      if ((window as any).logJSON) {
        resolve((window as any).logJSON);
      } else {
        const interval = setInterval(() => {
          if ((window as any).logJSON) {
            clearInterval(interval);
            resolve((window as any).logJSON);
          }
        }, 50);
      }
    });
  },
};
