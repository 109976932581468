/*
 * @Author: your name
 * @Date: 2021-12-29 16:44:16
 * @LastEditTime: 2024-11-11 10:21:36
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_International/src/index.tsx
 */
import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Router from './router/router';
import store from './redux/stroe';
import './assets/style/global.scss';

import './assets/iconfont/RelayIcon.css';
import initI18n from './i18n';
import { setVnStyle } from '@/util/init.js';
import '@/util/langs';
import { openPage } from '@/util/userProfile';

window._openPage = openPage;

window.log = () => {
  return '';
};
setVnStyle();

initI18n(() => {
  ReactDOM.render(
    <Provider store={store}>
      <Router></Router>
    </Provider>,

    document.getElementById('root'),
  );
});
