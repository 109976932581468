// nonReactComponent.js
import store from '@/redux/stroe';
import { LoginType } from '@/types/user.d';
import { checkPermission } from '@/util/hooks/checkPermissions'

interface User {
  pin: string;
  companyType: string;
  loginType: string;
  nickName: string;
  language: Record<string, string>;
  userType: number;
  stations: number[];
  showCurrency: string;
  countryNameMap: Record<string, string>;
}

let userInfo: Partial<User> = {}

// 获取当前 state
function currentState() {
  const storeInfo = store.getState()
  userInfo = storeInfo.User.data?.[0] || {}
}

// 订阅 state 变化
store.subscribe(() => {
  currentState()
});

interface DataItem {
  [key: string]: string;
}

export function createForm(data: DataItem[], action: string) {
  try {
    // 创建一个form元素
    const form = document.createElement('form');
    form.style.display = "none";
    form.method = 'POST';
    form.action = action; // 替换为你的action URL

    // 遍历数据并动态添加输入字段
    data?.forEach((formItem) => {
      Object.keys(formItem).forEach(item => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = item;
        input.value = formItem[item];
        form.appendChild(input);
      });
    })

    // 将表单添加到body中
    document.body.appendChild(form);

    // 提交表单
    form.submit();
    document.body.removeChild(form);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

const selfPage: string[] = [LoginType.JP]

export const openPage = (url: string, modal = '_self') => {

  if (checkPermission && !checkPermission('isOpenInNewWindow')) {
    modal = '_self'
  }

  window.open(url, modal)
}
