import React, { useState } from 'react';
import { Button, Input, InputNumber, Pagination, PaginationProps } from 'antd';
import { useTranslation } from 'react-i18next';
import Style from './index.module.scss';
import { customClickReport } from '@/dataPort';
import { getRouteMetaInfo } from '@/router/router';

interface Props {
  current: number;
  pageSize: number;
  total: number;
  pageSizeOptions?: string[];
  hideOnSinglePage?: boolean;
  showSizeChanger?: boolean;
  onChange?: (page: number, pageSize: number) => void;
  onShowSizeChange?: (current: number, size: number) => void;
  trackInfo?: {
    eventId: string;
    eventParam?: Record<string, any>
  };
}
const PaginationJD = ({ current, pageSize, total = 0, ...props }: Props) => {
  const { i18n, t } = useTranslation();
  const [jumpPage, setJumpPage] = useState('');
  const onChange: PaginationProps['onChange'] = (page, pageSize) => {
    const { trackInfo, onChange } = props
    const currentPath = window.location.pathname;
    const meta = getRouteMetaInfo(currentPath);

    if (onChange) {
      onChange(page, pageSize);
    }

    if (trackInfo && meta && meta.pageId) {
      customClickReport(meta.pageId, trackInfo.eventId, trackInfo.eventParam)
    }
  }
  return (
    <div className={Style.pagination}>
      <Pagination
        onChange={onChange}
        onShowSizeChange={props.onShowSizeChange}
        current={current}
        total={total}
        showSizeChanger={props.showSizeChanger}
        pageSize={pageSize}
        itemRender={(_, type, originalElement) => {
          if (type === 'prev') {
            return <Button>{TLT(`上一页`)}</Button>;
          }
          if (type === 'next') {
            return <Button>{TLT(`下一页`)}</Button>;
          }
          return originalElement;
        }}
      ></Pagination>
      <span>
        {`${TLT(`共`)} ${Math.ceil(total / pageSize)} ${TLT(`页`)}${(i18n.language === 'en' && total / pageSize > 1 && 's') || ''}`}
      </span>
      <div>
        {TLT(`跳转到页面`)}
        <Input
          value={jumpPage}
          onChange={e => {
            // const value = e.target.value;
            // if (value && /^[1-9]\d{0,}$/.test(value)) setJumpPage(value);
              setJumpPage(e.target.value.replace(/[^\d]/g,''))
          }}
          onPressEnter={()=>{
              if (!jumpPage) return;
              const cur = +jumpPage;
              if (current === cur) {
                  setJumpPage('');
                  return;
              }
              const pageNum = Math.ceil(total / pageSize);
              onChange?.(cur > pageNum ? pageNum : cur, pageSize);
              setJumpPage('');
          }
          }
          style={{ width: '35px', margin: '0 5px' }}
        />
        <Button
          className={Style.button}
          onClick={() => {
            if (!jumpPage) return;
            const cur = +jumpPage;
            if (current === cur) {
              setJumpPage('');
              return;
            }
            const pageNum = Math.ceil(total / pageSize);
            onChange?.(cur > pageNum ? pageNum : cur, pageSize);
            setJumpPage('');
          }}
        >
          Go
        </Button>
      </div>
    </div>
  );
};

export default PaginationJD;
