import { customClickReport } from '@/dataPort';
import { getRouteMetaInfo } from '@/router/router';

interface TrackableElement extends HTMLElement {
  dataset: {
    track?: string;
  };
}
const toReport = (e: Event) => {
  const target = e.target as TrackableElement | null;
  if (target && target.dataset.track) {
    try {
      const currentPath = window.location.pathname;
      const meta = getRouteMetaInfo(currentPath);
      if (meta && meta.pageId) {
        // 执行埋点逻辑
        console.log('Track:', JSON.parse(target.dataset.track));
        const { eventId, eventParam } = JSON.parse(target.dataset.track);
        customClickReport(meta.pageId, eventId, eventParam)
      }
    } catch (e) {
      console.log(e);
    }
  }
};

export { toReport };