import React, { useState } from 'react';
import styles from './index.module.scss';

const Mark: React.FC<any> = ({ orderDetails }) => {
  const orderRes = orderDetails?.orderRes;
  const remarks = orderRes?.orderExtInfo ? JSON.parse(orderRes?.orderExtInfo) : {};
  remarks?.settleInfo &&
    orderRes?.thirdOrderId &&
    remarks?.settleInfo.unshift({
      id: -1,
      category: TLT('客户采购单号'),
      value: orderRes?.thirdOrderId || '',
    });
  return (
    <div className={styles.remarksContent}>
      <div className={styles.customerRemarkSection}>
        <h3 className={styles.sectionTitle}>{TLT(`备注`)}</h3>
        <div className={styles.customerRemarkDetails}>
          {remarks?.settleInfo &&
            remarks?.settleInfo.map((item, idx) => {
              return (
                <div className={styles.remarkRow} key={idx}>
                  <span className={styles.remarkLabel}>{item.category}</span>
                  <span className={styles.remarkValue}>{item.value}</span>
                </div>
              );
            })}
        </div>
      </div>
      {orderRes?.remark && (
        <div className={styles.orderRemarkSection}>
          <h3 className={styles.sectionTitle}>{TLT(`订单备注`)}</h3>
          <p className={styles.orderRemarkText}>{orderRes?.remark ?? ''}</p>
        </div>
      )}
    </div>
  );
};
export default Mark;
