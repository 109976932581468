/*
 * @Author: duchongchong1
 * @Date: 2022-10-31 19:15:24
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2025-01-09 17:06:05
 * @FilePath: /isp_International/src/api/newApi/home.ts
 * @Description:
 */
/* eslint-disable no-undef */
import http from '../axios';

//查询精选类目
export function getHotCate(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/ad/getHotCate', params, false, true).then(
      res => {
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/**
 * 购物车数量
 */
export function shopCar() {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/cart/getCartNum', '', false, true).then(
      res => {
        // console.log('jdig/cart/getCartNum', res);
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

/**
 * 获取打标
 */
export function querySpecialAttr(params: any) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/pro/querySpecialAttr', params, false, true).then(
      res => {
        // console.log('jdig/cart/getCartNum', res);
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

