/*
 * @Author: weiyanna weiyanna@jd.com
 * @Date: 2022-05-20 17:50:23
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-06-18 10:38:10
 * @FilePath: /isp_International/src/pages/SearchList/components/Carousel/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';
import cookies from 'react-cookies';
import { getBusinessCardInfo } from '@/api/newApi/search';
import getImg from '@/util/getImg';
import DefaultBig from '@/assets/images/searchList/default-big.png';
import DefaultSmall from '@/assets/images/searchList/default-small.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import style from './index.module.scss';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updatePathWithParams } from '@/util/getImg';
import { prop } from 'ramda';

interface IAppProps {
  mainData: any;
  chooseGood: Function;
  userInfo?: any;
  type?: any;
  logData?: any;
  noStock?: boolean;
  hideSmallImage?: boolean;
}

const Carousel: React.FC<IAppProps> = (props: any) => {
  const [goodParams, setGoodParams] = useState([]);
  const [isShowParameterBox, setIsShowParameterBox] = useState('none');
  const [imglist, setMainImgList] = useState([]);
  const [imgIndex, setImgIndex] = useState(0); // 图片切换的次数
  const [curIndex, setCurrentIndex] = useState(0); //选中第几个
  const [imgLeft, setImgLeft] = useState(0); //图片切换的次数
  const [maxImgNumber, setMaxImgNumber] = useState(1); // 最大能点多少次
  const [rightColor, setRightColor] = useState('#999999');
  const [leftColor, setLeftColor] = useState('#999999');
  const [isLocation, setIslocation] = useState(cookies.load('stationType') == '0'); // 是否本土商品 0本土 1 跨境
  const history = useHistory();
  const fixedWidth = 50; //固定宽度
  const sigleWidth = 50; //每个图片宽度
  const showNumber = 4; //展示数量
  const { t, i18n } = useTranslation();
  // 上报方法
  useEffect(() => {
    const slaveWares = props.mainData && props.mainData.slaveWares ? props.mainData.slaveWares.concat() : [];
    // console.log(props?.mainData, 'props?.mainData');
    slaveWares.unshift({
      img: props?.mainData?.img,
      sku: props?.mainData?.sku,
      price: props?.mainData?.showCurrency?.salePrice,
      wareName: props?.mainData?.wareName,
      stockState: props?.mainData?.stockState,
      color: props?.mainData.color,
      saleState: props?.mainData.saleState,
      // sku: props?.mainData?.sku,
    });
    setMainImgList(slaveWares);
  }, [props.mainData]);
  const prevImg = () => {
    if (imgIndex === 0) {
      return;
    } else {
      setImgIndex(imgIndex - 1);
    }
    const imgLeft = (imgIndex - 1) * fixedWidth;
    setImgLeft(imgLeft);
  };
  // 切换下一张
  const nextImg = () => {
    let index = imgIndex;
    const steps = maxImgNumber;
    let imgLeftW = 0;
    if (index === steps) {
      return;
    } else {
      index++;
      imgLeftW = index * (showNumber * sigleWidth);
    }
    if (index === steps) {
      const lengthImg = imglist.length;
      imgLeftW = (index - 1) * (showNumber * sigleWidth) + (lengthImg % showNumber) * sigleWidth;
      if (lengthImg === 8) {
        //特殊处理
        imgLeftW = 185;
      }
    }
    setImgIndex(index);
    setImgLeft(imgLeftW);
  };
  // 切换商品图片及数据
  const choseIndex = (index: any) => {
    setCurrentIndex(index);
    props.chooseGood(imglist, index);
  };
  // 获取商卡参数
  const getBusinessCard = (data: any) => {
    setIsShowParameterBox('block');
    const arr: any = goodParams.filter((item: { sku: any }) => item.sku == data.sku);
    const getData = async () => {
      const result: any = (await getBusinessCardInfo({ skuId: data.sku, brandId: props.mainData.brand })) as any;
      if (result.success) {
        const newGoodParams: any = [
          ...goodParams,
          {
            sku: data.sku,
            valArr: [
              // {
              //   value: result.value.materialCode,
              //   key: '物料编码',
              // },
              {
                value: result.value.brand,
                key: `${TLT(`品牌`)}`,
              },
              {
                value: props.mainData.mode?.substr(0, 12),
                key: `${TLT(`商品型号`)}`,
              },
              {
                value: result.value?.wareQD?.substr(0, 20),
                key: `${TLT(`包装清单`)}`,
              },
            ],
          },
        ];
        setGoodParams(newGoodParams);
      }
    };
    if (!arr[0]) {
      getData();
    }
  };
  useEffect(() => {
    if (imglist && imglist.length > 0) {
      const steps = Math.ceil(imglist && imglist.length / showNumber) - 1;
      setMaxImgNumber(steps);
    }
  }, [imglist]);
  // const searchType = location.pathname.indexOf('listCableV2') > -1 ? 2 : 1; //1 电线电缆 2 中低压配件
  return (
    <div className={style.imgZoomPreview}>
      <div className={style.imgContainer}>
        {imglist?.[0] && (
          <div className={style.mainImg}>
            {props.children}
            <img
              style={{ opacity: props.noStock ? '0.5' : 1 }}
              src={updatePathWithParams(imglist[curIndex]?.img, { 'x-oss-process': 'img/shb/240/228' }) || DefaultBig}
              data-track={JSON.stringify({
                eventId: 'ProductList_ProductPicClk',
                eventParam: {
                  mku: imglist[curIndex].sku
                }
              })}
              onClick={() => {
                setTimeout(() => {
                  window._openPage('/sku/' + imglist[curIndex].sku, '_black');
                }, 100);
              }}
            />
          </div>
        )}
      </div>
      {!props.hideSmallImage && (
        <div className={style.listImgWrap}>
          {imglist.length > 0 && (
            <>
              {imglist.length >= 5 && (
                <LeftOutlined
                  style={{
                    marginTop: '12px',
                    marginLeft: '5px',
                    color: leftColor,
                    cursor: imgIndex !== 0 ? 'pointer' : 'no-drop',
                  }}
                  onMouseOver={() => {
                    if (imgIndex !== 0) {
                      setLeftColor('#0D47A0');
                    }
                  }}
                  onMouseOut={() => {
                    setLeftColor('#999999');
                  }}
                  onClick={prevImg}
                />
              )}
              <div className={style.swpierContenter}>
                <div
                  className={style.swiperSilder}
                  style={{
                    width: imglist.length * fixedWidth,
                    left: -imgLeft,
                  }}
                >
                  {imglist.map((item: any, index: any) => {
                    return (
                      <div
                        key={item.sku}
                        className={`${style.list} ${index === curIndex ? style.cur : ''}`}
                        onClick={() => choseIndex(index)}
                        onMouseOver={() => choseIndex(index)}
                      >
                        <img src={item.img || ''} title={item && item.color ? item.color : ''} />
                      </div>
                    );
                  })}
                </div>
              </div>
              {imglist.length >= 5 && (
                <RightOutlined
                  style={{
                    marginTop: '12px',
                    marginRight: '5px',
                    color: rightColor,
                    cursor: imgIndex !== maxImgNumber ? 'pointer' : 'no-drop',
                    float: 'right',
                  }}
                  onClick={() => nextImg()}
                  onMouseOver={() => {
                    if (imgIndex !== maxImgNumber) {
                      setRightColor('#0D47A0');
                    }
                  }}
                  onMouseOut={() => {
                    setRightColor('#999999');
                  }}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Carousel;
