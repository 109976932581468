import React from 'react';
import styles from './index.module.scss';

const Container: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.containerBox}>{children}</div>
    </div>
  );
};

export default Container;
