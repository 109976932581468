import React from 'react';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import styles from './index.module.scss';

const Index = () => {
  // const main = useRecoilValue('main');
  const history = useHistory();
  const productCategory: Array<{ id: number; name: string }> = useRecoilValue('productCategory');
  // if (!main?.breadCrumb) {
  //   return null;
  // }
  // if (!productCategory?.breadCrumb) {
  //   return null;
  // }
  const length = productCategory?.length - 1;

  //获取类目ids
  const getCatIds = (id: number)=>{
    let i = 0;
    const catIds = [];
    const idx = productCategory.findIndex(item=>item.id === id);

    while(i <= idx){
      catIds.push(productCategory[i].id);
      i++;
    }
    
    return catIds
  }
  const handle = (item: any) => {
    const cataIdList = getCatIds(item.id).join();
    const path = {
      cat: item.id,
      cataIdList,
      pathname: '/page/list',
      query: item.name,
    };
   
    history.push(path);
  };
  return (
    <div className={styles.m}>
      <Breadcrumb separator=">">
        {/*{main?.breadCrumb.map((item, index) => (*/}
        {/*  <Breadcrumb.Item key={item.name}>*/}
        {/*    {index === length ? (*/}
        {/*      <span className={styles.m_laster}>{item.name}</span>*/}
        {/*    ) : item.url ? (*/}
        {/*      <a href={item.url}>{item.name}</a>*/}
        {/*    ) : (*/}
        {/*      item.name*/}
        {/*    )}*/}
        {/*  </Breadcrumb.Item>*/}
        {/*))}*/}
        {/**/}
        {productCategory?.map((item, index) => (
          <Breadcrumb.Item key={item.name}>

            {index === length ? (
              <span className={styles.m_laster}>{item.name}</span>
            ) : (
              <a className='underText' onClick={() => handle(item)}>{item.name}</a>
            )}
          </Breadcrumb.Item>
        ))}
        {/**/}
      </Breadcrumb>
    </div>
  );
};

export default Index;
