import React, { useState } from 'react';
import InputNumber from 'rc-input-number';
import { useDispatch } from 'react-redux';
import { dispatchOneChecked, dispatchOneNum } from '@/redux/action';
import InputNumberPanel from '@/components/InputNumberPanel';
import { Button, Select, Input ,Modal, message, Col } from 'antd';
import DefaultKongYe from '@/assets/images/searchList/kongye.png';
import styles from './index.module.scss';
import { replenishment } from '@/api/newApi/orderdetail';
import { useTranslation } from 'react-i18next';
import { customClickReportSimple } from '@/dataPort';

export interface IAppProps {
  shopInfo: any;
  setIsVisible:()=>void;
}
const Replenishment: React.FC<IAppProps> = (props: any) => {
    const { isVisible, shopInfo, setIsVisible } = props;
    const [email, setEmail] = useState(''); //邮箱
    const[deliveryDate, setDeliveryDate] = useState('');//发货时间
    const[num, setNum] = useState(1);//数量
    const { t } = useTranslation();

    const deliveryOptions = [{value: '1', label: TLT(`尽快`)},{value: '2', label:  TLT(`一周内`)},{value: '3', label:  TLT(`一个月内`)},{value: '4', label:  TLT(`一季度内`)}]

    const clearInfo = ()=>{
        setEmail('');
        setDeliveryDate('');
        setNum('');
    }
    return <div >
       <Modal 
        width={700} 
        title={<div>{TLT(`申请补货`)}<span className={styles.subtit}>{TLT(`当前商品无货,工采可为您联系供应商进行本商品的补货,并在到货后及时告诉您`)}</span></div>} 
        open={isVisible} 
        className={styles.index}
        okText={TLT(`立即申请`)}
        cancelText={TLT(`取消`)}
        onOk={
            async()=>{
                customClickReportSimple('ProductDetail_ApplyInventoryConfirm')
                
                if(!num){
                    message.error(TLT(`请输入申请数量 `));
                    return;
                }
                if(!deliveryDate){
                    message.error(TLT(`请选择日期`));
                    return;
                }
                if(!email){
                    message.error(TLT(`请输入邮箱`));
                    return;
                }
                const resData = await replenishment({
                    mkuId:shopInfo?.mkuId,
                    applyCnt:num,
                    expectedDeliveryDate:deliveryDate,
                    email
                })
                
                if(resData?.success){
                    clearInfo();
                    setIsVisible(false)
                    message.success(resData?.msg);
                }else{
                    message.error(resData?.msg);
                }
                }
            }
            onCancel={()=>{
                clearInfo();
                setIsVisible(false)
            }}
       >
       <div className={styles.product}>
          <div className={styles.pic}>
            <img src={shopInfo?.img || ''} alt="" />
          </div>
          <div className={styles.con}>
            <div className={styles.name}>{shopInfo?.productName}</div>
           {/* <div className={styles.model}>
                <span>产品型号</span>
                <span></span>
           </div> */}
           <div className={styles.name}>
                <span>{TLT(`商品编号`)}</span>
                <span style={{
                    marginLeft:10,
                }}>{shopInfo?.mkuId || ''}</span>
           </div>
          </div>
       </div>

       <div className={styles.line}>
            <span className={styles.label}>{TLT(`申请数量`)}</span> 
            <div className='form-item'>
                <InputNumberPanel min={1} max={999999999} value={num} changeCallBack={(params)=>{
                   setNum(params)
                }}></InputNumberPanel>
            </div>
       </div>
       <div className={styles.line}>
            <span className={styles.label}>{TLT(`期望发货时间`)}</span> 
            <div className='form-item'>
                <Select options={deliveryOptions} className={styles.deliveryDate} value={deliveryDate} onChange={(val)=>{
                    setDeliveryDate(val)
                }}/>
            </div>
       </div>
       <div className={styles.line}>
            <span className={styles.label}> {TLT(`联系邮箱`)}</span>
            <div className='form-item'>
                <Input value={email} onChange={(e)=>{
                    setEmail(e.target.value)
                }}></Input>
            </div>
       </div>       
       </Modal>
      
    </div>
}

export default Replenishment;