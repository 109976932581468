import React from 'react';
import styles from './index.module.scss';
import { getJdiOSFile } from '@/util/index';
import { ReactComponent as IconPrint } from '@/assets/images/orderList/icon_print1.svg';
interface ProductTagProps {
  type: 'cross' | 'print';
  align?: 'start' | 'end';
  onClick?: () => void;
}

const ProductTag: React.FC<ProductTagProps> = ({ type, onClick, align = 'start' }) => {
  return (
    <div className={styles.tags} style={{ justifyContent: align }}>
      {type === 'cross' && (
        <div className={`${styles.crossTag} ${styles.tagItem}`}>
          <img className={styles.crossIcon} src={getJdiOSFile('mubla1/195354135364234883.png')} alt="cross Tag" />
          <span className={styles.crossSection}>{TLT('跨境')}</span>
        </div>
      )}
      {type === 'print' && (
        <div className={`${styles.prientTag} underText`} onClick={onClick}>
          <IconPrint
            style={{
              width: '12px',
              height: '12px',
              color: 'rgb(153, 153, 153)',
              fill: 'currentcolor',
            }}
            className={styles.prientIcon}
          />
          {TLT('订单打印')}
        </div>
      )}
    </div>
  );
};

export default ProductTag;
