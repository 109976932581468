import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Button, message, Tooltip, Modal } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import SlideProducts from './slideProducts';
import { useRecoilState, useSetRecoilState } from '@/pages/BatchCarts/components/Carts/hooks';
import { formatMoney } from '@/util/index';
import { getAddProducts, batchToBuy } from '@api/newApi/carts';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import ParentContext from '@/pages/BatchCarts/context';

import styles from '../../index.module.scss';

const search = queryString.parse(window.location.search);

const placeOrder = () => {
  const { t } = useTranslation();
  const [totalInfos, setTotalInfos] = useRecoilState('totalInfos');
  const [skuList, setSkuList] = useRecoilState('skuList');
  const [showSlide, setShowSlide] = useRecoilState('showSlide');
  const [selected, setSelected] = useRecoilState('selected');
  const [selectedSkuList, setSelectedSkuList] = useRecoilState('selectedSkuList');
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isCanPlaceOrder, setIsCanPlaceOrder] = useRecoilState('isCanPlaceOrder');
  const { deleteHandler } = useContext(ParentContext);
  const [successProduct] = useRecoilState('successProduct');
  const [errorProduct] = useRecoilState('errorProduct');

  const { confirm } = Modal;

  useEffect(() => {
    let selectSku = 0;
    skuList?.map(item => {
      if (item?.saleState?.saleState === 1 && item?.inPool && item?.remainNum !== 0) {
        selectSku += 1;
      }
    });
    const temp = selected.filter(s => {
      return s && s.trim();
    });
    if (selectSku === temp.length && temp.length > 0) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  }, [selected, skuList]);

  const handleToggle = useCallback(
    event => {
      event.persist();
      const { currentTarget } = event;
      const { dataset, checked } = currentTarget;
      const skus = dataset.id.split(',');
      const multiple = skus.length > 1;
      const copy = selected.map(item => item);
      console.log(copy, 'dataset');
      // inPool 是否入池
      //入池商品 且 库存不为0选中
      const inPoorSkus = skuList.filter(ele => ele?.inPool && ele?.remainNum !== 0 && skus.includes(ele.sku));
      console.log(inPoorSkus, 'inPoorSkus');
      let selectedItems = [];
      if (checked) {
        if (multiple) {
          selectedItems = inPoorSkus.map(ele => ele.sku) || [];
          setSelected(selectedItems);
          setSelectedSkuList(inPoorSkus);
        } else {
          selectedItems = copy.concat(inPoorSkus);
          setSelected(selectedItems);
        }
      } else {
        if (multiple) {
          selectedItems = [];
          setSelected(selectedItems);
          setSelectedSkuList([]);
        } else {
          selectedItems = copy.splice(selected.indexOf(skus[0]), 1);
          setSelected(selectedItems);
        }
      }
      console.log(selectedItems);
    },
    [selected, selectedSkuList, skuList],
  );

  const deleteAll = () => {
    console.log(selected?.length);
    if (selected?.length > 0) {
      deleteHandler({
        type: 'batch',
        state: 'success',
        sku: selected,
      });
    } else {
      message.warning(TLT(`请选择要删除的商品`));
    }
  };

  const deleteAllValidateProduct = () => {
    const remainNum = successProduct.cartWares?.filter(i => i.remainNum === 0).length;
    const failItemsNum = errorProduct?.length;
    confirm({
      title: TLT('确认清空{{tag_0}}个不可购买商品吗?', { tag_0: remainNum + failItemsNum }),
      content: (
        <div>
          <p>{TLT(`清空后可尝试修改关键词重新添加`)}</p>
        </div>
      ),
      okText: TLT(`清空`),
      cancelText: TLT(`取消`),
      onOk: () => {
        deleteHandler({
          type: 'available',
          state: 'normal',
        });
      },
    });
  };

  const addBuyer = async () => {
    if (!selected.length) {
      message.warning(TLT(`小于最小起订量`));
      return false;
    }

    const skus = skuList
      ?.filter(item => selected.includes(item.sku))
      .map(item => ({
        sku: item.sku,
        num: item.skuNum,
        price: item.price,
      }));
    const response = await getAddProducts(skus);
    if (response.success) {
      message.success(TLT(`加入购物车`));
      window._openPage('/cart/carts', '_blank');
      return true;
    }
    return response.success;
  };

  const goToFillOrder = async () => {
    // 是否有某些商品购买数量小于最小起订量
    const result = await batchToBuy(
      skuList
        ?.filter(item => selected.includes(item.sku))
        .map(item => ({
          sku: item.sku,
          num: item.skuNum,
          skuPrice: item.price,
        })),
    );
    if (result) {
      window.location.href = '/order/goOrderInfo?tradeModel=2';
    }
  };

  return (
    <div className={`${styles.placeOrder}`}>
      <div className={styles.left}>
        <div className={styles.selectAll}>
          <input
            data-track={JSON.stringify({
              eventId: 'BatchAddCart_SelectAllCheckbox',
            })}
            type="checkbox"
            checked={isSelectAll}
            data-id={skuList?.map(item => {
              if (item?.saleState?.saleState === 1) {
                return item.sku;
              }
            })}
            onChange={handleToggle}
          />{' '}
          {TLT(`全选`)}
        </div>
        <div
          className={styles.deleteAll}
          data-track={JSON.stringify({
            eventId: 'BatchAddCart_BatchDelete',
          })}
          onClick={deleteAll}
        >
          {/* 删除已选 */}
          {TLT(`删除已选`)}
        </div>
        <div className={styles.deleteAll} style={{ color: 'rgba(0, 3, 20, 0.45)' }} onClick={deleteAllValidateProduct}>
          {/* 清空不可购买商品 */}
          {TLT(`清空不可购买商品`)}
        </div>
      </div>
      <div className={styles.center}>
        <div className={styles.pieces}>
          <span>
            {/*Selected {totalInfos?.totalCheckKindCount} kinds, a total of {totalInfos?.totalCheckCount} pieces:{' '}*/}
            {TLT(`已选{kind}件/共{check}件`)
              .replace('{kind}', totalInfos?.totalCheckKindCount)
              .replace('{check}', totalInfos?.totalCheckCount)}
          </span>
          {!showSlide && selected.length > 0 && (
            <CaretDownOutlined
              style={{ cursor: 'pointer' }}
              onClick={event => {
                event.stopPropagation();
                setShowSlide(true);
              }}
            ></CaretDownOutlined>
          )}
          {showSlide && selected.length > 0 && (
            <CaretUpOutlined
              style={{ cursor: 'pointer' }}
              onClick={event => {
                event.stopPropagation();
                setShowSlide(false);
              }}
            ></CaretUpOutlined>
          )}
        </div>
        <div className={styles.price}>
          <span className={styles.title}>{TLT(`应付总金额`)}</span>
          <span className={styles.num}>
            <em>{totalInfos?.totalPriceCurrencies?.showCurrency?.symbol}</em>
            {formatMoney(totalInfos?.totalPrice, false)}
          </span>
        </div>
      </div>
      <div className={styles.right}>
        <Button
          type="primary"
          style={{ width: '140px', marginRight: 10, height: '48px', fontSize: '18px' }}
          onClick={addBuyer}
          data-track={JSON.stringify({
            eventId: 'BatchAddCart_AddToCart',
          })}
        >
          {TLT(`加入购物车`)}
        </Button>

        {isCanPlaceOrder ? (
          <Button
            type="primary"
            data-track={JSON.stringify({
              eventId: 'BatchAddCart_Checkout',
            })}
            style={{ width: '140px', height: '48px', fontSize: '18px' }}
            onClick={goToFillOrder}
          >
            {TLT(`去下单`)}
          </Button>
        ) : (
          <Tooltip title="The administrator cannot place orders" placement="topRight" overlayStyle={{ color: 'red' }}>
            <Button type="primary" disabled={true} style={{ width: '140px', height: '48px', fontSize: '18px' }}>
              {TLT(`去下单`)}
            </Button>
          </Tooltip>
        )}
      </div>
      {showSlide && selected.length > 0 && <SlideProducts></SlideProducts>}
    </div>
  );
};

export default placeOrder;
