import React, { ReactElement, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './index.module.scss';
import iconCopy from '@/assets/images/skuDetail/copy.png';
import { Tooltip, message } from 'antd';
import { getJdiOSFile } from '@/util/index';

interface ProductInfoComponentProps {
  preSplitLine?: boolean;
  copy?: boolean;
  autoSize?: boolean;
  label: string;
  text: string | ReactElement | ReactElement[];
  className?: string;
  fontSize?: string;
  columnFlex?: boolean;
  textEnd?: boolean;
  tooltip?: any;
  copyCb?: () => void
}

const ProductInfoComponent: React.FC<ProductInfoComponentProps> = ({
  preSplitLine = false,
  label,
  text,
  tooltip,
  className,
  copy = true,
  columnFlex = false,
  textEnd = false,
  autoSize = false,
  fontSize = '14px',
  copyCb
}) => {
  const [copyIconState, setCopyIconState] = useState('initial');
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (copyIconState === 'clicked') {
      timer = setTimeout(() => {
        setCopyIconState(isMouseOver ? 'hover' : 'initial');
      }, 1500);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [copyIconState, isMouseOver]);

  const getIconSrc = () => {
    switch (copyIconState) {
      case 'hover':
        return getJdiOSFile('mubla1/196630525590423003.png');
      case 'clicked':
        return getJdiOSFile('mubla1/24147179402316312.png');
      default:
        return getJdiOSFile('mubla1/196630525590410192.png');
    }
  };

  const handleMouseEnter = () => {
    setIsMouseOver(true);
    if (copyIconState === 'initial') {
      setCopyIconState('hover');
    }
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
    if (copyIconState !== 'clicked') {
      setCopyIconState('initial');
    }
  };
  return (
    <div className={`${autoSize ? styles.autoSize : styles.desContainer} ${className}`}>
      {preSplitLine && <div className={styles.divider}></div>}
      <Tooltip title={tooltip || ''}>
        <div
          style={{ fontSize: fontSize }}
          className={`${styles.productInfoRow} ${columnFlex && styles.columnFlex} ${columnFlex &&
            textEnd &&
            styles.textEnd}`}
        >
          <span className={styles.productIdLabel}>{label}</span>
          <span className={styles.productId}>
            <div className={`${styles.textWrapper} ${columnFlex && textEnd ? styles.textWrapperEnd : ''}`}>
              {text ? (
                <>
                  <span className={`${styles.valueText}`}>{text}</span>
                  {copy && (
                    <CopyToClipboard
                      text={text}
                      onCopy={() => {
                        if (copyIconState !== 'clicked') {
                          copyCb?.()
                          setCopyIconState('clicked');
                        }
                      }}
                    >
                      <span
                        className={styles.copyInner}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={e => e.stopPropagation}
                      >
                        <img
                          onClick={e => e.stopPropagation}
                          src={getIconSrc()}
                          className={styles.productIdIcon}
                          alt="icon"
                        />
                      </span>
                    </CopyToClipboard>
                  )}
                </>
              ) : (
                '-'
              )}
            </div>
          </span>
        </div>
      </Tooltip>
    </div>
  );
};

export default ProductInfoComponent;
