import React from 'react';
import { Button, Space, Col, DatePicker, Radio, Row } from 'antd';

import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';
import Goods from './Goods/priceGoods';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from '../../hooks';
import moment from 'moment';

const Index = (props: any) => {
  const history = useHistory();
  const [shipment, setShipment] = useRecoilState('shipment');

  const title = (
    <>
      <div className={styles.title}>
        <span
          onClick={() => {
            history.push({
              pathname: '/cart/carts',
            });
            log('jdisp', 'Settlement_BacktoCart');
          }}
        >
          {TLT(`返回修改采购清单`)}
        </span>
      </div>
    </>
  );

  return (
    <div className={styles.products}>
      {shipment?.groupList?.map((ele, idx) => {
        return (
          <div className={styles.orderItemContainer} key={idx}>
            <div className={styles.orderHeader}>
              <span className={styles.shippingNumber}>
                {TLT('订单')} #{idx + 1}
              </span>
              <span className={styles.productInfoLabel}>{TLT(`商品信息`)}</span>
              <span className={styles.quantityLabel}>{TLT(`数量`)}</span>
              <span className={styles.subtotalLabel}>{TLT(`小计`)}</span>
            </div>
            <div className={styles.orderContent}>
              <div className={styles.deliveryMethodWrapper}>
                <div className={styles.deliveryMethodContainer}>
                  <span className={styles.deliveryMethodLabel}>{TLT(`配送方式`)}：</span>
                  <div className={styles.deliveryOptions}>
                    <Radio.Group
                      value={ele.deliveryType}
                      onChange={(e: any) => {
                        const newShipment = JSON.parse(JSON.stringify(shipment));
                        newShipment.groupList[idx].deliveryType = e.target.value;
                        newShipment.productList.forEach((item: any) => {
                          if (item.localType == idx) {
                            item.deliveryType = e.target.value;
                            if (e.target.value == '2') {
                              item.scheduledDeliveryTime = ele?.minDeliveryTime;
                            } else {
                              item.scheduledDeliveryTime = '';
                            }
                          }
                        });
                        setShipment(newShipment);
                      }}
                    >
                      <Space direction="vertical">
                        <Radio value={1}>{TLT(`尽快送达`)}</Radio>
                        <Radio value={2}>{TLT(`预约送货`)}</Radio>
                      </Space>
                    </Radio.Group>
                  </div>
                  <div className={styles.time}>
                    {ele?.deliveryType == '2' && (
                      <div className={styles.times}>
                        <div className={styles.timesTitle}>{TLT(`预约送货时间`)}</div>
                        <div className={styles.timesInfo}>{`${ele.scheduledDeliveryTime &&
                          moment(ele.scheduledDeliveryTime).format('LL')}`}</div>
                        <div
                          className={`${styles.timesHandler} timesHandlerbox`}
                          onClick={e => {
                            const newShipment = JSON.parse(JSON.stringify(shipment));
                            newShipment.groupList[idx].isShowDate = !newShipment.groupList[idx].isShowDate;
                            setShipment(newShipment);
                          }}
                        >
                          {TLT(`修改`)}
                        </div>
                      </div>
                    )}

                    <DatePicker
                      style={{ opacity: 0 }}
                      key={idx}
                      open={ele?.isShowDate}
                      popupClassName={styles.datePicker}
                      getPopupContainer={(node: HTMLElement) =>
                        document.querySelectorAll('.timesHandlerbox')[idx] as HTMLElement
                      }
                      disabledDate={(current: any) => {
                        // 指定日期后一个月内的日期
                        console.log(new Date(ele?.minDeliveryTime), 'sddds');
                        const startDate = moment(new Date(ele?.minDeliveryTime), 'YYYY-MM-DD');
                        const endDate = startDate.clone().add(1, 'month');
                        if (current < startDate || current > endDate) {
                          return true;
                        }

                        // 禁止选择周末的日期
                        const dayOfWeek = current.day();
                        if (dayOfWeek === 0 || dayOfWeek === 6) {
                          return true;
                        }

                        return false;
                      }}
                      onChange={e => {
                        const datetime = e.valueOf();
                        const newShipment = JSON.parse(JSON.stringify(shipment));
                        newShipment.groupList[idx].isShowDate = !newShipment.groupList[idx].isShowDate;
                        newShipment.groupList[idx].scheduledDeliveryTime = datetime;
                        newShipment.productList.forEach((item: any) => {
                          if (item.localType == idx) {
                            item.scheduledDeliveryTime = datetime;
                          }
                        });
                        setShipment(newShipment);
                      }}
                    />
                  </div>
                </div>
              </div>

              <Goods idx={idx} />
              {/* <div className={styles.productDetails}>
                <img
                  className={styles.productImage}
                  src="//img14.360buyimg.com/ling/jfs/t1/266811/39/3057/1899/676bd26fF0f7c5876/b25f1498d0e69d80.jpg"
                  alt="Product"
                />
                <div className={styles.productInfo}>
                  <span className={`${styles.productName} ${styles.truncate}`}>
                    Batianan HD camera monitoring outdoor wireless wifi home 4g monitor home mobile phone remote 360
                    ​​degree no blind angle with night vision panoramic rotating rural T model
                  </span>
                  <div className={styles.productMetadata}>
                    <div className={styles.metadataRow}>
                      <span className={styles.metadataLabel}>Product ID</span>
                      <span className={styles.metadataValue}>100015927921</span>
                    </div>
                    <div className={styles.metadataRow}>
                      <span className={styles.metadataLabel}>Price Incl. Tax</span>
                      <span className={styles.metadataValue}>\$178.90</span>
                    </div>
                    <div className={styles.metadataRow}>
                      <span className={styles.metadataLabel}>Price Excl. Tax</span>
                      <span className={styles.metadataValue}>\$178.90</span>
                    </div>
                    <div className={styles.metadataRow}>
                      <span className={styles.metadataLabel}>Tax Rate</span>
                      <span className={styles.metadataValue}>13%</span>
                    </div>
                  </div>
                  <div className={styles.customsClearance}>
                    <img
                      className={styles.customsIcon}
                      src="//img12.360buyimg.com/ling/jfs/t1/257882/37/3134/1351/676bd26fF0f610702/09c2758c0d79f1e1.png"
                      alt="Customs"
                    />
                    <span className={styles.customsText}>Customs Clearance</span>
                  </div>
                </div>
                <span className={styles.quantity}>x 92</span>
                <div className={styles.subtotalInfo}>
                  <div className={styles.subtotalRow}>
                    <span className={styles.subtotalLabel}>Price Incl. Tax</span>
                    <span className={styles.subtotalValue}>\$178.90</span>
                  </div>
                  <div className={styles.subtotalRow}>
                    <span className={styles.subtotalLabel}>Price Excl. Tax</span>
                    <span className={styles.subtotalValue}>\$178.90</span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        );
      })}

      <div className={styles.m_box}>
        <Row
          style={{
            display: 'flex',
          }}
        >
          <Col span={18}>{/* <Goods idx={idx}/> */}</Col>
        </Row>

        {shipment?.productList && shipment?.productList.some(ele => ele?.sourceCountryCode == 'CN') && (
          <div className={styles.questionTip}>
            {TLT(`跨境商品，货期和报关方式与境内贸易商品不同，敬请留意。如有疑问，请随时联系我们的运营经理`)}
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
