/*
 * @Author: duchongchong1
 * @Date: 2022-11-27 17:12:55
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-04-09 15:34:24
 * @FilePath: /isp_International/src/pages/SkuDetailV2/components/Main/Summary/Basic/Panel/Amount/index.tsx
 * @Description:  
 */
import React, { useEffect, useState } from 'react';

import cookies from 'react-cookies';
import _ from 'lodash';
import { useRecoilState, useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import InputNumberPanel from '@/components/InputNumberPanel';
import {
  getDeliveryDate
} from '@/api/newApi/skudetail';
let flag: any = null; // inputnumber使用，暂存计时器

import styles from './index.module.scss';

interface IProps {
  changeDeliveryDate?: (params: any) => void, // 回调函数
}
const Index: React.FC<IProps> = (props: any) => {
  const {changeDeliveryDate} = props;
  const [location, setLocation] = useState(cookies.load('stationType'))
  const [amount, setAmount] = useRecoilState('amount');
  // const main = useRecoilValue('main');
  const basicInfo = useRecoilValue('basicInfo');
  // const tip = main?.stockTip;
  if (basicInfo?.lowestBuy > amount) {
    setAmount(basicInfo?.lowestBuy);
  }
  const onChange = _.debounce(amount => {
    setAmount(amount);
  }, 10);

  // 防抖输入
  const onChangeDeliveryDate = (value:any) =>{
    if (flag) {
      clearTimeout(flag);
      flag = null;
    }
    flag = setTimeout(() => {
      const params = {
        mkuId: basicInfo?.skuId,
        reqNum: value
      }
      changeDeliveryDate && changeDeliveryDate(params);
    }, 600);
  };

  useEffect(() => {
    console.log("basicInfo--->", basicInfo);
  }, [basicInfo]);
  // 获取最小库存
  const getMinStockNum = (item: any) => {
    // console.log("获取最小库存--->",item);
      //有库存
      if(item?.remainNum>0) {
        //选中数量大于库存值
        if(amount > item?.remainNum){
          return item?.remainNum || 1;
        }else{
          return item?.lowestBuy || 1;
        }
      }else{
        // 无库存
        return item?.lowestBuy || 1;
      }
    // return isDisableProduct ? 99999 : item?.lowestBuy;
  };
  //获得最大库存
  const getMaxStockNum = (item: any) => {
      //有库存
      if(item?.remainNum > 0) {
        return item?.remainNum;
      }else{
        return 99999;
      }
  }
  return (
    <div className={styles.m}>
      <div className={styles.amount}>
        {/*<InputNumber*/}
        {/*  min={main?.skuNum}*/}
        {/*  max={main?.maximumNumber}*/}
        {/*  value={amount}*/}
        {/*  onChange={value => onChange(value)}*/}
        {/*  upHandler={<PlusOutlined />}*/}
        {/*  downHandler={<MinusOutlined />}*/}
        {/*  prefixCls="cable-number"*/}
        {/*/>*/}
        {/**/}
        {/*<InputNumber*/}
        {/*  min={basicInfo?.lowestBuy}*/}
        {/*  max={99999}*/}
        {/*  value={amount}*/}
        {/*  onChange={value => onChange(value)}*/}
        {/*  upHandler={<PlusOutlined />}*/}
        {/*  downHandler={<MinusOutlined />}*/}
        {/*  prefixCls="cable-number"*/}
        {/*/>*/}
        <div className={styles.amountNumber}>
          <InputNumberPanel
            min={getMinStockNum(basicInfo)}
            max={getMaxStockNum(basicInfo)}
            trackInfo={{
              increase: 'ProductDetail_IncreaseQty',
              decrease: 'ProductDetail_DecreaseQty',
              input: 'ProductDetail_ModifyQty',
            }}
            value={amount}
            // stock={basicInfo?.remainNum}
            disabled={basicInfo?.remainNum===0}
            changeCallBack={(params: any) => {
              onChange(params);
              onChangeDeliveryDate(params);
            }}
          >
          </InputNumberPanel>
        </div>
      </div>
      {/* {tip && <div className={styles.tip}>{tip}</div>} */}
    </div>
  );
};

export default Index;
