import React, { useEffect, useState } from 'react';
// import { pathOr } from 'ramda';
// import { message } from 'antd';
import { RecoilRoot } from 'recoil';
import { useParams } from 'react-router-dom';
import cookies from 'react-cookies';
// import { useDispatch } from 'react-redux';
// import _ from 'lodash';
import { Spin } from 'antd';

// 新的API
import {
  getInitSimilarProduct2,
  getInitProductAttr2,
  getInitBasicInfo2,
  getInitBigField2,
  getInitProductCategory2,
  breadCrumbDataFormat,
  getProDetail,
  getProGroup,
} from '@/api/newApi/skudetail';
// import { getCompareBarData } from '@/api/search/api';
// import { dispatchAllCheckContrast } from '@/redux/action';

import Graphic from './components/Graphic';
import Main from './components/Main';
// import Models from './components/Models';
import Nav from './components/Nav';
import Parameter from './components/Parameter';

import { useSetRecoilState, useRecoilValue } from './hooks';
// import { mainDataFormat, otherDataFormat, modelDataFormat, logger } from './utils/index';

import styles from './index.module.scss';
import { querySpecialAttr } from '@/api/newApi/home';
import { moveToFirst } from '@/util/tools';

const Index = () => {
  const { skuId } = useParams<Record<string, any>>();
  const [stationtype] = useState(cookies.load('stationType'));
  const [loading, setloading] = useState(false);

  // const dispatch = useDispatch();
  // const setCompare = useSetRecoilState('compare');
  // const setSpecifications = useSetRecoilState('specifications');
  // const setModel = useSetRecoilState('model');
  // const setService = useSetRecoilState('service');

  //
  // const setMain = useSetRecoilState('main');
  // const setOthers = useSetRecoilState('others');
  const setBigField = useSetRecoilState('bigField');
  const setBasicInfo = useSetRecoilState('basicInfo');
  const setProductAttr = useSetRecoilState('productAttr');
  const setSimilarProduct = useSetRecoilState('similarProduct');
  const setProductCategory = useSetRecoilState('productCategory');
  const setMaterialInfo = useSetRecoilState('materialInfo');
  const specialAttrs = useSetRecoilState('specialAttrs');

  // const amount = useRecoilValue('amount');

  useEffect(() => {
    setloading(true);
    getProDetail({
      mkuId: +skuId,
    })
      .then((res: any) => {
        if (res?.success) {
          const value = res.value;
          setBigField(value.bigField);
          setBasicInfo(value.baseInfo);
          setProductAttr(value.productAttr);
          setProductCategory(breadCrumbDataFormat(value.productCategory));
          console.log(value?.materialInfo, 'value?.materialInfo');
          setMaterialInfo(value?.mkuMaterial);
        }
      })
      .finally(() => {
        setloading(false);
      });

    getProGroup({
      mkuId: +skuId,
    }).then((res: any) => {
      if (res?.success) {
        setSimilarProduct(res.value);
      }
    });
   
    //京准达
    querySpecialAttr({
      mkuIds:[+skuId]
    }).then((res) => {
      if(res?.success){
        let result = Object.entries(res?.value[Object.keys(res?.value)[0]]).map(([key, value]) => ({
          key,
          value
        }));
        //跨境放第一位
        const index = result.findIndex((ele)=>ele.key == 'CrossBorder')
        result = moveToFirst(result, index)
        specialAttrs(result)
      }
    })

    // getInitBigField2({
    //   skuId: skuId,
    // }).then((res: any) => {
    //   if (res?.success) {
    //     setBigField(res.value);
    //   }
    // });
    // getInitBasicInfo2({
    //   skuId: skuId,
    //   num: 1,
    // }).then((res: any) => {
    //   if (res?.success) {
    //     setBasicInfo(res.value);
    //   }
    // });
    // getInitProductAttr2({
    //   skuId: skuId,
    // }).then((res: any) => {
    //   if (res?.success) {
    //     setProductAttr(res.value);
    //   }
    // });
    // getInitSimilarProduct2({
    //   skuId: skuId,
    // }).then((res: any) => {
    //   if (res?.success) {
    //     setSimilarProduct(res.value);
    //   }
    // });
    // getInitProductCategory2({
    //   skuId: skuId,
    // }).then((res: any) => {
    //   if (res?.success) {
    //     setProductCategory(breadCrumbDataFormat(res.value));
    //   }
    // });
  }, [stationtype]);

  // const getInitSkuDetailFunc = _.debounce((amount: any) => {
  //   if (!amount) return;
  //   getInitSkuDetail({
  //     skuId,
  //     buyNum: amount,
  //   }).then((res: any) => {
  //     if (res?.success) {
  //       // logger('initSkuDetail:', res?.value);
  //       setMain(mainDataFormat(res?.value));
  //     } else {
  //       message.error(res?.msg);
  //     }
  //   });
  //   // getProductBindInfo({
  //   //   skuId,
  //   //   num: amount,
  //   // }).then((res: any) => {
  //   //   if (res?.success) {
  //   //     logger('serviceList:', res?.value);
  //   //     setService(res?.value);
  //   //   } else {
  //   //     // message.error(res?.msg);
  //   //   }
  //   // });
  // }, 300);

  // useEffect(() => {
  //   getInitSkuDetailFunc(amount);
  // }, [amount]);

  return (
    <Spin spinning={loading}>
      <div className={styles.m}>
        <Main />
        {/*<Models />*/}
        <div className={styles.mc}>
          <Nav />
          <Parameter />
          <Graphic />
        </div>
      </div>
    </Spin>
  );
};

const Container: React.FC = () => {
  return (
    <RecoilRoot>
      <Index />
    </RecoilRoot>
  );
};

export default Container;
