import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// openType枚举：
// 1: 内部，当前页
// 2： 内部，新开页
// 3：外部，当前页
// 4：外部，新开页

export interface OpenDirOption {
  openType: number;
  jumpUrl: string;
}

export const useOpenDir = () => {
  const history = useHistory();

  const openDir = useCallback((option: OpenDirOption) => {
    if ([3, 4].includes(option.openType)) {
      window.open(option.jumpUrl, option.openType === 4 ? '_blank' : '_self');
    } else if ([1, 2].includes(option.openType)) {
      if (option.openType === 2) {
        window.open(option.jumpUrl, '_blank');
      } else {
        history.push(option.jumpUrl);
      }
    }
  }, [history]);

  return openDir;
};
