import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { getRecommend } from '@/api/newApi/homePage';
import { addSearchHistory, isSkuId } from '@/api/newApi/search';
import { useHistory } from 'react-router-dom';
import { customClickReportSimple } from '@/dataPort';

const MyComponent: React.FC<{ title: string }> = ({ title }) => {
  const [data, setData] = useState<
    Array<{
      icon: string;
      catName: string;
      catId: number;
    }>
  >([]);
  const history = useHistory();

  const init = async () => {
    const result = await getRecommend();
    if (result.success) {
      setData(result.value);
    }
  };

  const handleClick = async (item: any) => {
    await addSearchHistory(item.catName);
    const result = (await isSkuId(item.catName)) as any;
    if (result.success && result.value) {
      window.location.href = `${window.location.origin}/sku/${result.value}`;
      return;
    }
    const path = {
      pathname: '/page/list',
      cat: item.catId,
      cataIdList: item.catId + '',
      query: item.catName || '',
    };
    history.push(path);
  };

  useEffect(() => {
    init();
  }, []);
  return data?.length ? (
    <div className={styles.categoryContainer}>
      <h2 className={styles.categoryTitle}>{title}</h2>
      <div className={styles.categoryGrid}>
        {data.map((item, index) => (
          <div
            key={index}
            className={styles.categoryItem}
            onClick={() => {
              customClickReportSimple('HomePage_RecommendedCatClk', item)
              handleClick(item);
            }}
          >
            <img className={styles.categoryImage} src={item.icon} alt={item.catName} />
            <span className={styles.categoryName}>{item.catName}</span>
          </div>
        ))}
      </div>
    </div>
  ) : (
    ''
  );
};

export default MyComponent;
