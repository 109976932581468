import React, { useState, useEffect, FC } from 'react';
import { BrowserRouter, Route, Switch, RouteComponentProps, Redirect, matchPath, RouteProps } from 'react-router-dom';
import BaseLayout from '@/layout/BaseLayout';
import WorkBenchRoutes from './WorkBench';

import AfterSalesPolicy from '@/pages/AfterSalesPolicy'
import SelectList from '../pages/SelectList';
import SearchList from '../pages/SearchList';
import Home from '../pages/NewHome/index';
import Carts from '../pages/CartsNew/index';
import OtherCarts from '../pages/OtherCarts/index';
import Login from '../pages/Login';
import UpdatePassword from '../pages/UpdatePassword';
import SkuDetailV2 from '../pages/SkuDetailV2/index';
import OrderInfo from '../pages/OrderInfo/index';
import BatchCarts from '@/pages/BatchCarts';
import { useDispatch } from 'react-redux';
import { dispatchUser } from '@/redux/action';
import { setGlobalCssVars } from '@/layout/themes/themeManage';
import HomePage from '@/pages/HomePage';
import { Spin, ConfigProvider } from 'antd';
import { getPersonInfo } from '@/api/newApi/carts';
import { loadJAScript } from '@/dataPort';
import Account from '@/pages/Account'

interface CommonComponentProps {
  isAudit?: boolean;
  isDelete?: boolean;
  isVsp?: boolean;
  isBatch?: boolean;
}

interface RouteMeta {
  title: string;
  pageId?: number;
  eventId?: string;
}

interface RouteConfig {
  path: string;
  name: string;
  exact: boolean;
  meta?: RouteMeta;
  component: React.ComponentType<CommonComponentProps & RouteComponentProps>;
}

const routes: Array<{
  meta?: {
    pageId?: string;
    [index: string]: any;
  };
  [index: string]: any;
}> = [
  //搜索列表
  {
    path: `/page/list`,
    name: 'SearchList',
    exact: true,
    meta: {
      title: 'searchList', //搜索列表
      pageId: 'ProductList',
    },
    component: SearchList,
  },
  {
    path: `/`,
    name: 'Home',
    exact: true,
    meta: {
      title: 'home', //首页
      pageId: 'HomePage'
    },
    component: HomePage,
  },
  {
    path: `/select`,
    name: 'Select',
    exact: true,
    meta: {
      title: 'select', //精选商品-落地页
    },
    component: SelectList,
  },
  //购物车
  {
    path: `/cart/otherCart`,
    name: 'OtherCarts',
    exact: true,
    meta: {
      title: 'otherCart', //其他采购清单
    },
    component: OtherCarts,
  },
  {
    path: `/sku/:skuId`,
    name: 'SkuDetail',
    exact: true,
    meta: {
      title: 'skuDetail', //商品详情页
      pageId: 'ProductDetail',
    },
    component: SkuDetailV2,
  },
  {
    path: `/cart/carts`,
    name: 'Carts',
    exact: true,
    meta: {
      title: 'cartList', //采购清单
      background: '#F3F6F9',
      pageId: 'Cart'
    },
    component: Carts,
  },
  {
    path: `/cart/batchAdd`,
    name: 'batchCarts',
    exact: true,
    meta: {
      title: 'batchCarts', //采购清单
      pageId: 'BatchAddCart'
    },
    component: BatchCarts,
  },
  {
    path: `/order/goOrderInfo`,
    name: 'OrderInfo',
    exact: true,
    meta: {
      title: 'settlement', //结算页
      pageId: 'Checkout'
    },
    component: OrderInfo,
  },
  {
    path: `/afterSalesPolicy`,
    name: 'AfterSalesPolicy',
    exact: true,
    meta: {
      title: 'afterSalesPolicy', //结算页
      // moduleName: '售后须知'
    },
    component: AfterSalesPolicy,
  },
  ...WorkBenchRoutes,
  {
    path: '*',
    name: 'Home',
    exact: true,
    meta: {
      title: 'home', //首页
    },
    component: HomePage,
  },
];

const BasicRoute: FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  // 用户认证检查函数
  const checkUserAuth = async (): Promise<boolean> => {
    try {
      const response = await getPersonInfo();
      if (response.success) {
        const value = response?.value;
        console.log('checkUserAuth', value);
        loadJAScript(value.pin, value);
        if (value) {
          dispatch(dispatchUser(value));
        }
        return !!value;
      }
      return false;
    } catch (error) {
      console.error('Error checking user auth:', error);
      return false;
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      const authResult = await checkUserAuth();
      setIsAuthenticated(authResult);
      setIsLoading(false);
    };
    if (window.location.pathname !== '/passport/login' && window.location.pathname !== '/forget') {
      checkAuth();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <ConfigProvider
        theme={{
          components: {
            Spin: {
              colorBgMask: '#FFF',
              colorWhite: '#00678B',
            },
          },
        }}
      >
        <Spin size="large" fullscreen />
      </ConfigProvider>
    );
  }

  const RouteWithAuth: FC<RouteConfig> = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        if (!isAuthenticated && rest.path !== '/passport/login' && rest.path !== '/forget') {
          return <Redirect to="/passport/login" />;
        }

        setGlobalCssVars({
          'container-bg-color': rest.meta?.background || '#FFF',
        });

        document.title = TLT(`工采国际`);
        return <Component {...props} />;
      }}
    />
  );

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/passport/login" component={Login} />
        <Route exact path="/forget" component={UpdatePassword} />
        <BaseLayout backgroundColor="#f0f0f0" isAuth={isAuthenticated}>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithAuth key={i} {...route} />
            ))}
          </Switch>
        </BaseLayout>
      </Switch>
    </BrowserRouter>
  );
};

export function getRouteMetaInfo(pathname: string) {
  const matchedRoute = routes.find(route => {
    const match = matchPath(pathname, {
      path: route.path,
      exact: route.exact,
      strict: route.strict
    });
    return match !== null;
  });
  
  console.log('matchedRoute', matchedRoute)
  return matchedRoute ? matchedRoute.meta : null;
}

export default BasicRoute;
