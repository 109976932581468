/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2025-01-02 09:54:28
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2025-01-07 17:19:23
 * @FilePath: /isp_International/src/i18n/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { getChangeEnv } from '@/api/newApi/header';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { getLangVersion } from '@/api/newApi/lang';
import { setLocal, getLocal, LocalNameEnum } from '@/util/storage'
import moment from 'moment';

import 'moment/locale/vi'
import 'moment/locale/zh-cn'
import 'moment/locale/en-gb';
import 'moment/locale/hu';
import 'moment/locale/th';
import 'moment/locale/ms';
import 'moment/locale/pt-br';
import 'moment/locale/zh-hk';
import 'moment/locale/id';

// -- multiple langs TODO --
const supportLangs = ['vi', 'en', 'zh', 'hu', 'th', 'ms', 'pt-BR', 'zh_Hant', 'id'];
const momentLangs: Record<string, string> = {
  vi: 'vi',
  en: 'en-gb',
  zh: 'zh-cn',
  hu: 'hu',
  th: 'th',
  ms: 'ms',
  'pt-BR': 'pt-br',
  'zh_Hant': 'zh-hk',
  id: 'id'
}

// 动态解析浏览器语言对应的Unicode值
function getBaseLanguage() {
  const language = navigator.language;
  const locale = new Intl.Locale(language);
  return locale.language;
}

const initI18n = async (callback: () => void) => {
  const currentSelectLangCode = localStorage.getItem('_language'); //上次设置语言
  const resources: Record<string, { translation: Record<string, string | object> }> = {};
  const browserDefaultLang = getBaseLanguage();
  const langCode: string =
    currentSelectLangCode || (supportLangs.includes(browserDefaultLang) && browserDefaultLang) || 'zh';
    setLocal(LocalNameEnum.mulLangLocalTagRemark, langCode)

  let langInfo: any = {}
  try {
    langInfo = await getLangVersion({
      tag: 'tagWisp',
    });
  } catch (e) {
    console.error(e)
  }

  let langDataUrl = ''
  if (langInfo?.success && langInfo.value?.length && langInfo.value[0].remark) {
    langDataUrl = langInfo.value[0].remark.replace('${lang}', langCode.replace('-', '_'));
    setLocal(LocalNameEnum.langRemark, langInfo.value[0].remark)
  } else {
    const localCache = getLocal(LocalNameEnum.langRemark)
    localCache && (langDataUrl = localCache)
  }

  try {
    const result = await fetch(langDataUrl);
    const langResult = await result['json']()
    if (langResult) {
      if (resources[langCode]) {
        // 为了应对语言框架系统解决不了的问题
        resources[langCode].translation = Object.assign(resources[langCode].translation, langResult);
      } else {
        resources[langCode] = { translation: langResult };
      }

      moment.locale(momentLangs[langCode]);
    }
  } catch (e) {
    console.error(e)
  }
  
  //初始化
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init(
      {
        resources,
        lng: langCode,
        interpolation: {
          escapeValue: false,
        },
      },
      () => {
        // 暂时只有首页会有初次进入页面，网关中英文状态不对
        if (location.pathname === '/' || location.pathname === '/passport/login') {
          //巴西语 pt-BR 后端使用pt_br
          getChangeEnv({ env: i18n.language?.replace(/-/g, '_'), reqStartTime: new Date().getTime() })
            .then(res => {
              callback();
            })
            .catch(() => {
              callback();
            });
        } else {
          callback();
        }
      },
    );
};

export default initI18n;
