/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-01-02 22:02:39
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-01-03 15:00:09
 * @FilePath: /isp_International/src/pages/SkuDetailV2/components/Main/Summary/Basic/localSimilarPro/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import styles from './index.module.scss';

const Index = () => {
  const basicInfo = useRecoilValue('basicInfo');
  const similarProducts = useRecoilValue('similarProduct');
  const skuId = basicInfo?.skuId;
  const [products, setProducts] = useState([]);
  useEffect(() => {
    if (similarProducts) {
      setProducts(similarProducts?.members || []);
    }
  }, [similarProducts]);
  return (
    <div className={styles.attributeContainer}>
      <span className={styles.attributeTitle}>{TLT('选择')}</span>
      {products?.map((ele, idx) => (
        <div
          key={idx}
          className={styles.optionsWrapper}
          onClick={() => {
            window.location.href = `${window.location.origin}/sku/${ele?.mkuId}`;
          }}
        >
          <div className={styles.optionRow}>
            <div className={styles.rowBox}>
              <img className={styles.optionIcon} src={ele.mainImg} />
            </div>

            <span className={styles.optionLabel}>{ele.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Index;
