/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-09-29 21:59:39
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2025-01-09 22:05:23
 * @FilePath: /isp_International/src/pages/SkuDetailV2/atoms/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import amount from './amount';
import compare from './compare';
import filter from './filter';
import main from './main';
import model from './model';
import others from './others';
import products from './products';
import service from './service';
import specifications from './specifications';
import bigField from './bigField';
import basicInfo from './basicInfo';
import productAttr from './productAttr';
import similarProduct from './similarProduct';
import productCategory from './productCategory';
import materialInfo from './materialInfo';
import specialAttrs from './specialAttrs';

const atoms = {
  amount,
  compare,
  filter,
  main,
  model,
  others,
  products,
  service,
  specifications,
  bigField,
  basicInfo,
  productAttr,
  similarProduct,
  productCategory,
  materialInfo,
  specialAttrs
};

export default atoms;
