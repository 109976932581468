/*
 * @Author: your name
 * @Date: 2021-09-16 11:05:14
 * @LastEditTime: 2024-05-20 20:26:19
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: In User Settings Edit
 * @FilePath: /isp_pro/src/components/common/SimplePagination/index.tsx
 */
import React from 'react';
import { Row, Pagination, ConfigProvider } from 'antd';
import Icon from '@/components/common/Icon';
import { getJdiOSFile } from '@/util/index';

import iconPageLeft from '@/assets/images/common/icon_page_left.svg';
import iconPageLeftDis from '@/assets/images/common/icon_page_left_dis.svg';
import iconPageRight from '@/assets/images/common/icon_page_right.svg';
import iconPageRightDis from '@/assets/images/common/icon_page_right_dis.svg';

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

const SimplePagination: React.FC<{
  total: number;
  page: number;
  totalPage: number;
  pageSize: number;
  onPageChange?: React.MouseEventHandler<HTMLDivElement>;
  // onNext?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ total, page, pageSize, onPageChange }) => {
  const { t } = useTranslation();
  return (
    <Row style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
      <span style={{ color: 'rgba(7, 14, 50, 0.6)', marginRight: '4px', fontSize: '14px' }}>
        {TLT(`共`)} <span style={{ color: '#666' }}>{total}</span> {TLT(`个订单`)}
      </span>
      <ConfigProvider
        theme={{
          components: {
            Pagination: {
              marginXS: 2,
              marginSM: 1,
            },
          },
        }}
      >
        <Pagination
          simple={{ readOnly: true }}
          showSizeChanger={false}
          current={page}
          total={total}
          pageSize={pageSize}
          onChange={onPageChange}
        />
      </ConfigProvider>
      {/* <Icon
        icon={page <= 1 ? iconPageLeftDis : iconPageLeft}
        width="20px"
        height="20px"
        style={{ marginRight: '15px' }}
        onClick={onPrev}
      />
      <span style={{ fontSize: '14px', color: '#666' }}>
        <span className={[styles.blue, styles.bold].join(' ')}>{page > totalPage ? totalPage : page}</span>/{totalPage}
      </span>
      <Icon
        icon={page >= totalPage ? iconPageRightDis : iconPageRight}
        width="20px"
        height="20px"
        style={{ marginLeft: '15px' }}
        onClick={onNext}
      /> */}
    </Row>
  );
};

export default SimplePagination;
