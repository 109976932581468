import React from 'react';
import { Button, Checkbox, message } from 'antd';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import styles from './index.module.scss';
import iconCopy from '@/assets/images/orderList/icon_copy.svg';
import { ReactComponent as IconPrint } from '@/assets/images/orderList/icon_print1.svg';
import { ReactComponent as IconDelete2 } from '@/assets/images/orderList/icon_delete2.svg';
import iconInfo from '@/assets/images/orderList/icon_info.svg';
import stylesBox from '@/pages/OrderList/index.module.scss';
import Des from '@/components/Atomics/Des/des';
import Time from '@/components/Atomics/Time';
import Tag from '@/components/Atomics/Tags/tags';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { customClickReportSimple } from '@/dataPort';

import { OrderStatus, orderStatus, print, orderTime } from '@/components/Order';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { UserType } from '../../components/SearchForm';
import { useTranslation } from 'react-i18next';

const OrderTitle: React.FC<{
  order: any;
  type?: 'parent' | 'child';
  isAudit: boolean;
  isDelete: boolean;
  isVsp: boolean;
  auditQueryType: string;
  checked: boolean;
  onCheckOrder: (e: CheckboxChangeEvent, order: any) => void;
  onRemoveOrder: React.MouseEventHandler<any>;
  isConfirm: boolean;
  orderConfirm: () => void;
}> = ({
  order,
  type,
  isAudit,
  isDelete,
  isVsp,
  checked,
  auditQueryType,
  onCheckOrder,
  onRemoveOrder,
  isConfirm,
  orderConfirm,
}) => {
  const { t } = useTranslation();
  const status = orderStatus(order);
  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  const userType: UserType = currentUser?.userType ?? 0;

  const userPin: string = currentUser.pin;

  const orderConsignee = order.orderConsignee || order.consignee;

  const receiver = () => (
    <div>
      <div className={styles.receiver_title}>{orderConsignee + ' ' + order.deliveryPhone}</div>
      <div className={styles.receiver_title} style={{ marginTop: '10px' }}>
        {order.deliveryCountry || ''}
      </div>
      <div className={styles.receiver_detail} style={{ marginTop: '15px' }}>
        {order.deliveryAddress}
      </div>
    </div>
  );

  return (
    <div
      className={`${stylesBox.orderListHeader} ${type !== 'parent' ? stylesBox.orderListHeaderBorder : ''}`}
      style={{ marginBottom: 0 }}
    >
      <div className={`${stylesBox.headerRow}`} style={{ ...(type === 'child' ? { background: '#FFF' } : {}) }}>
        <div className={`${stylesBox.productBox} ${stylesBox.productBoxHeader}`}>
          {/* 多选框 */}
          <Checkbox clstag="h|keycount|myorder|15" checked={checked} onChange={e => onCheckOrder(e, order)} />

          {/* 时间 */}
          <div className={styles.itemMergeTime}>
            <Time timestamp={orderTime(order)} />
          </div>

          <Des
            className={styles.itemMerge}
            label={TLT(`订单编号`)}
            autoSize={true}
            text={type === 'parent' ? order.parentJdOrderId : order.jdOrderId}
            columnFlex={true}
            copyCb={() => {
              customClickReportSimple('ApprovalList_CopyOrderId')
            }}
          ></Des>

          <Des
            className={styles.itemMerge}
            label={TLT(`采购账号`)}
            autoSize={true}
            text={order.pin || ''}
            columnFlex={true}
            copy={false}
          ></Des>

          {order.thirdOrderId && (
            <Des
              className={styles.itemMerge}
              label={TLT(`采购单号`)}
              autoSize={true}
              text={order.thirdOrderId}
              columnFlex={true}
              copy={false}
            ></Des>
          )}

          <Des
            className={styles.itemMerge}
            label={TLT(`收货人`)}
            autoSize={true}
            text={orderConsignee.name}
            columnFlex={true}
            copy={false}
            // tooltip={receiver}
          ></Des>
        </div>

        <span className={stylesBox.action}>
          {type !== 'parent' && (
            <Tag
              type="print"
              align="end"
              onClick={() => {
                customClickReportSimple('ApprovalList_PrintSingle')
                print(order.jdOrderId, '1', t);
              }}
            />
          )}
        </span>
      </div>
    </div>
  );
};

export default OrderTitle;
