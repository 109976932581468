import { HashCode, transListToContent } from '@/pages/Approval/Create/components/drawFlow/utils';
import { initFieldSel, initSymbol } from '@api/newApi/approval';
import { Button, Drawer, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONDITION_DRAWER_TYPE, PriorityList } from '../../constants/index';
import SelectCondition from '../selectCondition/index';
const { Option } = Select;

import { numberToChinese } from '@/util/format';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

interface IProps {
  conditionDrawerType?: number;
  title?: string;
  node?: any;
  manageOpen?: boolean;
  cancelCallBack?: () => void;
  confirmCallBack?: (node: any) => void;
}
const ManageConditionDrawer: React.FC<IProps> = (props: any) => {
  const { conditionDrawerType, title, manageOpen = false, cancelCallBack, confirmCallBack, node } = props;

  const [fieldSel, setFieldSel] = useState([]);
  const [symbol, setSymbol] = useState([]);

  const [conditionList, setConditionList] = useState([]);
  const { t } = useTranslation();

  //翻译审批级别数组字符串
  PriorityList.forEach((item, idx) => {
    item.content = TLT(`优先级${numberToChinese(idx + 1)}`);
  })

  /**
   * 选过之后的条件就不能再次选择
   */
  const initFieldIsCanSelect = () => {
    const tempFieldSel = [...fieldSel];
    const temp = [];
    conditionList.forEach(condition => {
      if (condition.fieldKey !== '') {
        temp.push(condition.fieldKey);
      }
    });
    tempFieldSel.filter(field => {
      if (temp.indexOf(field?.category) > -1) {
        field.isSelect = true;
      } else {
        field.isSelect = false;
      }
    });
    setFieldSel(tempFieldSel);
  };

  const addSelectCondition = () => {
    initFieldIsCanSelect();

    let fieldKey = '';
    let fieldName = '';
    let hasEnum = false;
    let fieldEnumValues = [];
    for (let i = 0; i < fieldSel?.length; i++) {
      const field = fieldSel[i];
      if (!field?.isSelect) {
        fieldKey = field?.category;
        fieldName = field?.categoryName;
        hasEnum = field?.hasEnum;
        fieldEnumValues = field?.fieldEnumValues;
        break;
      }
    }
    const tempList = [...conditionList];
    if (tempList.length < fieldSel?.length) {
      const newCondition = {
        fieldId: HashCode(),
        fieldKey: fieldKey,
        fieldName: fieldName,
        hasEnum: hasEnum,
        fieldEnumValues: fieldEnumValues,
        operation: symbol[0].operation,
        operationName: symbol[0].operationName,
        fieldValue: '',
        minFieldValue: '',
        maxFieldValue: '',
      };
      if (hasEnum) {
        newCondition.fieldValue = fieldEnumValues[0];
      }
      tempList.push(newCondition);
    }
    setConditionList(tempList);
  };

  const initFieldSelFn = async () => {
    const fieldSel: any = await initFieldSel();
    const tempFieldSel = fieldSel?.value;
    setFieldSel(tempFieldSel);
    const symbol: any = await initSymbol();
    const tempSymbol = symbol?.value;
    setSymbol(tempSymbol);

    // 如果是编辑则赋值
    if (conditionDrawerType === CONDITION_DRAWER_TYPE.modifyCondition) {
      const tempList = node?.conditionList;

      tempList.forEach((item: any) => {
        const currentFieldList = tempFieldSel.filter((field: any) => field?.categoryName == item?.fieldName);
        const currentField = currentFieldList[0];
        console.log(currentField);
        item.hasEnum = currentField.hasEnum;
        item.fieldEnumValues = currentField.fieldEnumValues;
      });

      setConditionList(node?.conditionList);
    } else {
      setTimeout(() => {
        if (conditionList.length === 0 && tempFieldSel && tempSymbol) {
          const firstField = tempFieldSel[0];
          const firstSymbol = tempSymbol[0];
          const tempList = [...conditionList];
          const newCondition = {
            fieldId: HashCode(),
            fieldKey: firstField.category,
            fieldName: firstField.categoryName,
            hasEnum: firstField.hasEnum,
            fieldEnumValues: firstField.fieldEnumValues,
            operation: firstSymbol.operation,
            operationName: firstSymbol.operationName,
            fieldValue: '',
            minFieldValue: '',
            maxFieldValue: '',
          };
          tempList.push(newCondition);
          setConditionList(tempList);
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (manageOpen) {
      initFieldSelFn();
    }
  }, [manageOpen]);

  useEffect(() => {
    initFieldIsCanSelect();
  }, [conditionList]);

  const handleFieldSelChange = () => {
    initFieldIsCanSelect();
  };
  const handleDeleteSelectCondition = (fieldId: string) => {
    const tempList = conditionList.filter(field => field.fieldId != fieldId);
    if (tempList.length === 0) {
      const newCondition = {
        fieldId: HashCode(),
        fieldKey: fieldSel[0].category,
        fieldName: fieldSel[0].categoryName,
        operation: symbol[0].operation,
        operationName: symbol[0].operationName,
        fieldValue: '',
        minFieldValue: '',
        maxFieldValue: '',
      };
      setConditionList([newCondition]);
    } else {
      setConditionList(tempList);
    }
  };

  const [conditionName, setConditionName] = useState(node.nodeName);
  const [errorMessage, setErrorMessage] = useState(false); // 没有输入就点击提交按钮
  const [conditionPriority, setConditionPriority] = useState(node.priority);

  const handlePriorityChange = (value: number) => {
    setConditionPriority(value);
  };
  const handleInputConditionName = (event: any) => {
    const name = event.target.value;
    setConditionName(name);
    if (name.length > 0) {
      setErrorMessage(false);
    }
  };
  const handleBlurConditionName = (event: any) => {
    const name = event.target.value;
    setConditionName(name);
    if (name.length > 0) {
      setErrorMessage(false);
    } else {
      setErrorMessage(true);
    }
  };
  const handleClickSubmit = () => {
    // 条件名称不能为空
    if (conditionName.length === 0) {
      setErrorMessage(true);
      return;
    }
    let validate = true;
    console.log('conditionList--->', conditionList);
    conditionList.forEach(condition => {
      if (!condition?.fieldValue) {
        validate = false;
        condition.isError = true;
      }
      if (!condition?.hasEnum) {
        const reg = /^([1-9][0-9]*)?$/;
        if (!reg.test(condition?.fieldValue)) {
          validate = false;
        }
        if (condition?.operation === 'BETWEEN' && Number(condition.fieldValue) >= Number(condition.maxFieldValue)) {
          validate = false;
        }
      }
    });
    if (validate) {
      node.nodeName = conditionName;
      node.conditionList = conditionList;
      node.priority = conditionPriority;
      node.content = transListToContent(conditionList);
      confirmCallBack && confirmCallBack(node);
    } else {
      const temp = [...conditionList];
      setConditionList(temp);
    }
  };
  return (
    (<div id="manageConditionDrawer">
      <Drawer
        rootClassName={styles.manageConditionModel}
        width={800}
        title={title}
        open={manageOpen}
        destroyOnClose={true}
        footer={
          <div className={styles.manageFooter}>
            <div className={styles.footerLeft}></div>
            <Button 
              style={{marginRight: '16px'}} 
              onClick={() => {
                cancelCallBack && cancelCallBack();
              }}>{TLT(`取消`)}</Button>
            <Button type='primary' onClick={handleClickSubmit}>{TLT(`提交`)}</Button>
            {/* <div className={styles.footerRight}>
              <span className={styles.primaryBtn} onClick={handleClickSubmit}>
                提交
              </span>
              <span
                className={styles.defaultBtn}
                onClick={() => {
                  cancelCallBack && cancelCallBack();
                }}
              >
                取消
              </span>
            </div> */}
          </div>
        }
        closeIcon={<div className={styles.iconClose}></div>}
        onClose={() => {
          cancelCallBack && cancelCallBack();
        }}
        getContainer={() => document.getElementById('manageConditionDrawer') as HTMLDivElement}
      >
        <div className={styles.manageContent}>
          <div className={styles.contentTop}>
            <div className={styles.contentItem}>
              <span className={styles.itemTitle}>{TLT(`审批条件名称`)}：</span>
              <input
                placeholder={TLT(`请输入名称`)}
                maxLength={15}
                value={conditionName}
                onInput={handleInputConditionName}
                onBlur={handleBlurConditionName}
                className={`${errorMessage ? styles.error : ''}`}
              ></input>
              <span className={styles.itemTips}>{conditionName?.length}/15</span>
              {errorMessage && <span className={styles.errorMessage}>{TLT(`请输入审批条件名称`)}</span>}
            </div>

            <div className={`${styles.contentItem} ${styles.mrt}`}>
              <span className={styles.itemTitle}>{TLT(`条件优先级`)}：</span>
              <Select defaultValue={conditionPriority} style={{ width: '650px' }} onChange={handlePriorityChange}>
                {PriorityList.map((item, index) => {
                  return (
                    <Option key={index} value={item.value}>
                      {item.content}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className={styles.contentBottom}>
            <div className={styles.bottomTitle}>{TLT(`审批条件设置（需同时满足以下条件）`)}</div>

            {conditionList.map((item, index) => {
              return (
                <SelectCondition
                  isCanDelete={conditionList?.length > 1}
                  fieldSel={fieldSel}
                  symbol={symbol}
                  key={item?.fieldId}
                  content={item}
                  deleteSelectCondition={handleDeleteSelectCondition}
                  fieldSelChange={handleFieldSelChange}
                ></SelectCondition>
              );
            })}

            <div
              className={`${styles.bottomAdd} ${conditionList?.length === fieldSel?.length ? styles.disable : ''}`}
              onClick={addSelectCondition}
            >
              <em>+</em>{TLT(`增加条件`)}（{conditionList?.length}/{fieldSel?.length}）
            </div>
          </div>
        </div>
      </Drawer>
    </div>)
  );
};

export default ManageConditionDrawer;
