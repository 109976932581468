/*
 * @Author: weiyanna
 * @Date: 2021-12-07 16:29:28
 * @LastEditTime: 2024-03-08 09:32:29
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_pro/src/components/Address/List/index.tsx
 */
import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddressItem from './Item/index';

export interface IAppProps {
  addressList: any;
  onCheckAddress: (e: boolean, address: any) => void;
  onDeleteAddress: (e: boolean, address: any) => void;
  onEditAddress: (e: boolean, address: any) => void;
  onEditAlloc: (e: boolean, address: any) => void;
  checkedIds: Array<string | number>;
}

const List: React.FC<IAppProps> = props => {
  const { addressList, onCheckAddress, onDeleteAddress, onEditAddress, onEditAlloc, checkedIds } = props;
  const { t } = useTranslation();
  return (
    <div
      style={{
        marginTop: '20px',
        marginBottom: '20px',
        flexWrap: 'wrap',
        alignItems: 'center',
        display: 'flex',
        marginLeft: '10px',
        marginRight: '-10px',
      }}
    >
      <Row
        style={{
          width: '100%',
          padding: '10px 16px',
          background: 'rgba(0,3,20,0.03)',
          border: '1px solid rgba(225,225,225,1)',
        }}
        align="middle"
        gutter={[20, 0]}
      >
        {/* 名称 */}
        <Col span={3} style={{ fontSize: '14px', color: 'rgba(0,3,20,0.45)', paddingLeft: '34px' }}>
          {TLT(`姓名`)}
        </Col>
        {/* 电话 */}
        <Col span={4} style={{ fontSize: '14px', color: 'rgba(0,3,20,0.45)' }}>
          {TLT(`手机号`)}
        </Col>
         {/* 地区 */}
         {/* <Col span={8} style={{ fontSize: '14px', color: 'rgba(0,3,20,0.45)' }}>
          {TLT(`地址`)}
        </Col> */}
        {/* 地址 */}
        <Col span={13} style={{ fontSize: '14px', color: 'rgba(0,3,20,0.45)' }}>
          {TLT(`地址`)}
        </Col>
        {/* 操作 */}
        <Col span={4} style={{ fontSize: '14px', color: 'rgba(0,3,20,0.45)', textAlign: 'center' }}>
          {TLT(`操作`)}
        </Col>
      </Row>
      {addressList.map((o: any) => (
        <AddressItem
          key={o.id}
          address={o}
          checked={checkedIds.includes(o.id)}
          onCheckAddress={onCheckAddress}
          onDeleteAddress={onDeleteAddress}
          onEditAddress={onEditAddress}
          onEditAlloc={onEditAlloc}
        />
      ))}
    </div>
  );
};
export default List;
