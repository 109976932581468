import React, { useState } from 'react';
import { message } from 'antd';
import { createAddress } from '@/api/newApi/personalCenter/address';
import { useRecoilState } from '../../hooks';
import ADDRESS from '@/assets/images/orderInfo/address.png';
import styles from './index.module.scss';
import AddArea from '@/components/Address/Drawer';
import AddressSelector from './AddressSelector/index';
import { useTranslation } from 'react-i18next';

const Index = () => {
  const [shipment] = useRecoilState('shipment'); // main message
  const [consigneeList, setConsigneeList] = useRecoilState('consigneeList');
  const [consignee, setConsignee] = useRecoilState('consignee');
  const [showAddressSelector, setShowAddressSelector] = useState(false); // 打开修改地址
  const [addressModelVisible, setAddressModelVisible] = useState(false); // 打开新增地址
  const { t } = useTranslation();
  const onSaveAddress = async (address: any, isDefault: any) => {
    const params = { ...address };
    params.defaultStatus = isDefault ? 1 : 0;

    const res = (await createAddress(params)) as any;

    if (res.success) {
      setConsignee({
        ...res?.value,
        id: res?.value?.id,
      });

      let newList;
      if (isDefault && consigneeList) {
        newList = [res?.value].concat(
          consigneeList.map(c => {
            return {
              ...c,
              defaultStatus: 0,
            };
          }),
        );
      } else {
        newList = [res?.value].concat(consigneeList);
      }

      setConsigneeList(newList);
      setAddressModelVisible(false);
    } else {
      message.error(res.msg);
    }
  };

  return (
    <div className={styles.m}>
      <div className={`${styles.shippingAddressContainer} ${consignee ? '' : styles.onlyAddressTitle}`}>
        {/* <h2 className={styles.orderInstructionTitle}>Please fill in and check the order information</h2> */}
        <div className={styles.addressSection}>
          <div className={`${styles.addressTitle}`}>
            <div className={styles.sectionTitle}>{TLT('收货地址')}</div>
            {!!shipment?.addressAuthority && (
              <div
                className={styles.changeAddressButton}
                data-track={
                  JSON.stringify({
                    eventId: 'Checkout_AddAddress'
                  })
                }
                onClick={() => {
                  setAddressModelVisible(true);
                  log('jdisp', 'Settlement_NewAddress');
                }}
              >
                {TLT(`新建收货地址`)}
              </div>
            )}
          </div>
          {consignee && (
            <div className={styles.addressDetails}>
              <div className={styles.addressInfo}>
                <div className={styles.contactInfo}>
                  <span className={styles.recipientName}>{consignee?.receiveName}</span>
                  <span className={styles.recipientPhone}>{consignee?.receivePhone}</span>
                </div>
                <p className={styles.addressLine}>
                  {consignee?.sortRule !== 'childPriority'
                    ? consignee?.areaAddress + ' ' + consignee?.detailAddress
                    : consignee?.detailAddress + ' ' + consignee?.areaAddress}
                </p>
              </div>
              <div
                className={styles.changeAddressButton}
                data-track={
                  JSON.stringify({
                    eventId: 'Checkout_SwitchAddress'
                  })
                }
                onClick={() => {
                  setShowAddressSelector(true);
                  log('jdisp', 'Settlement_ChangeAddress');
                }}
              >
                {TLT(`更换地址`)}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* 更换地址 */}
      {consigneeList && consignee && (
        <AddressSelector
          showDrawer={showAddressSelector}
          consignee={consignee}
          consigneeList={consigneeList}
          setConsigneeList={setConsigneeList}
          setConsignee={setConsignee}
          // closeDrawer={item => {
          //   Object.keys(item).length > 0 && setConsignee(item);
          //   setShowAddressSelector(false);
          // }}
          closeDrawer={() => {
            setShowAddressSelector(false);
          }}
        />
      )}

      <AddArea
        address={{}}
        visible={addressModelVisible}
        onOk={(address: any) => {
          onSaveAddress(address, address.defaultStatus);
          log('jdisp', 'Settlement_save');
        }}
        onCancel={() => {
          setAddressModelVisible(false);
          log('jdisp', 'Settlement_cancel');
        }}
      />
    </div>
  );
};

export default Index;
