import React from 'react';
import cookies from 'react-cookies';
import styles from './index.module.scss';
import search from '@/assets/images/skuDetail/search.png';
import left from '@/assets/images/skuDetail/left.png';
import left_cant from '@/assets/images/skuDetail/left_cant.png';
import right from '@/assets/images/skuDetail/right.png';
import right_cant from '@/assets/images/skuDetail/right_cant.png';
import { updatePathWithParams } from '@/util/getImg';
interface State {
  curIndex: number;
  MagX: number;
  MagY: number;
  Zoom: number;
  SourceWidth: number;
  SourceHeight: number;
  MagWidth: number;
  MagHeight: number;
  SourceTop: number;
  SourceLeft: number;
  imgIndex: number;
  isEnter: boolean;
  isStationType: string;
}
interface Props {
  images: Record<string, any>[];
}
export default class Carousel extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  public readonly state: Readonly<State> = {
    curIndex: 0, // list  选中第几个
    MagX: 0, // 放大镜开始坐标 x
    MagY: 0, // // 放大镜开始坐标 y
    Zoom: 2.5, // 放大倍数
    SourceWidth: 0, // 展示图片大小
    SourceHeight: 0, // 展示图片大小
    SourceTop: 0, //放大图片的位置
    SourceLeft: 0,
    MagWidth: 0, // 放大镜
    MagHeight: 0,
    isEnter: false, // 是否鼠标进入
    imgIndex: 0, // 滚到第几个了
    isStationType: cookies.load('stationType'), // 本土还是跨境
  };
  initAttr() {
    const area: any = document.querySelector('#main');
    const { Zoom } = this.state;
    if (area) {
      this.setState({
        SourceWidth: area.offsetWidth,
        SourceHeight: area.offsetHeight,
        SourceLeft: area.getBoundingClientRect().left,
        SourceTop: area.getBoundingClientRect().top,
        MagWidth: area.offsetWidth / Zoom,
        MagHeight: area.offsetHeight / Zoom,
      });
    }
  }
  ZoomMove(e: any) {
    const { MagWidth, MagHeight, SourceTop, SourceLeft, SourceWidth, SourceHeight } = this.state;
    const scrollLeft = document.body.scrollLeft || document.documentElement.scrollLeft;
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    let x = e.clientX - MagWidth / 2 - (SourceLeft - scrollLeft);
    x = x < 0 ? 0 : x;
    if (x < 0) {
      x = 0;
    } else if (x > SourceWidth - MagWidth) {
      x = SourceWidth - MagWidth;
    }
    let y = e.clientY - MagHeight / 2 - (SourceTop - scrollTop);
    if (y < 0) {
      y = 0;
    } else if (y > SourceHeight - MagHeight) {
      y = SourceHeight - MagHeight;
    }
    this.setState({
      MagX: x,
      MagY: y,
    });
  }
  componentDidMount() {
    setTimeout(() => {
      this.initAttr();
    }, 0);
  }
  prevImg() {
    this.setState((prev: State) => {
      const nextIndex = prev.imgIndex - 3;
      const imgIndex = nextIndex < 0 ? 0 : nextIndex;
      return {
        imgIndex,
      };
    });
  }
  nextImg() {
    const imglist = this.props.images;
    this.setState((prev: State) => {
      const nextIndex = prev.imgIndex + 3;
      const total = imglist && imglist.length;
      const imgIndex = nextIndex > total ? prev.imgIndex : nextIndex;
      return {
        imgIndex,
      };
    });
  }
  setCurrent(index: any) {
    this.setState({
      curIndex: index,
    });
  }
  render() {
    const imglist = this.props.images;
    const { MagX, MagY, MagWidth, MagHeight, Zoom, isEnter, curIndex, imgIndex, isStationType } = this.state;
    const nextDisable = imglist?.length < 4 || imgIndex + 3 > imglist?.length;
    return imglist && imglist.length > 0 ? (
      <div className={styles.m}>
        <div className={styles.mc} id="main">
          <div
            className={styles.move}
            onMouseEnter={() => {
              this.setState({
                isEnter: true,
              });
            }}
            onMouseLeave={() => {
              this.setState({
                isEnter: false,
              });
            }}
            onMouseMove={e => {
              this.ZoomMove(e);
            }}
          ></div>
          {imglist[0] && (
            <img
              src={updatePathWithParams(imglist[curIndex].path, { 'x-oss-process': 'img/shb/760/760' })}
              // src={isStationType == '0' ? `${imglist[curIndex].path}` : ` https://m.360buyimg.com/da/s452x452_${imglist[curIndex].path}`}
            />
          )}
          {/* <i className={styles.icon} style={{ backgroundImage: `url(${search})` }}></i> */}
          {isEnter && (
            <div
              className={styles.zoom}
              style={{
                width: `${MagWidth}px`,
                height: `${MagHeight}px`,
                top: `${MagY}px`,
                left: `${MagX}px`,
              }}
            ></div>
          )}
          {isEnter && (
            <div className={styles.big} style={{ width: '150%', height: '150%' }}>
              {imglist[0] && (
                <img
                  style={{
                    width: `${Zoom * 150}%`,
                    height: `${Zoom * 150}%`,
                    top: `${-MagY * Zoom * 2.5}px`,
                    left: `${-MagX * Zoom * 1.5}px`,
                  }}
                  src={updatePathWithParams(imglist[curIndex].path, { 'x-oss-process': 'img/shb/306/306' })}
                  // src={isStationType == '0' ? `${imglist[curIndex].path}` :`https://m.360buyimg.com/da/s${452 * Zoom}x${452 * Zoom}_${imglist[curIndex].path}`}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.mb}>
          {imglist?.length < 5 ? (
            ''
          ) : (
            <div onClick={this.prevImg.bind(this)} className={`${styles.prev} ${imgIndex === 0 ? styles.disable : ''}`}>
              <div className={`${styles.triangleLeft} ${imgIndex === 0 ? styles.triggerDisabled : ''}`}></div>
            </div>
          )}
          <div className={styles.container} style={{ left: imglist?.length < 5 ? '-4px' : '26px' }}>
            <div
              className={styles.list}
              style={{
                width: imglist.length * 84,
                left: -imgIndex * 84,
              }}
            >
              {imglist.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={[styles.item, curIndex === index && styles.cur].filter(Boolean).join(' ')}
                    onClick={this.setCurrent.bind(this, index)}
                    onMouseOver={this.setCurrent.bind(this, index)}
                  >
                    <img
                      style={{
                        width: '100%',
                      }}
                      src={updatePathWithParams(item.path, { 'x-oss-process': 'img/shb/306/306' })}
                      // src={isStationType == '0' ? `${item.path}` : `https://img14.360buyimg.com/n5/${item.path}`} width={38} height={38}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {imglist?.length < 5 ? (
            ''
          ) : (
            <div
              className={[styles.next, nextDisable && styles.disable].filter(Boolean).join(' ')}
              onClick={this.nextImg.bind(this)}
            >
              <div className={`${styles.triangle} ${nextDisable ? styles.triggerDisabled : ''}`}></div>
            </div>
          )}
        </div>
      </div>
    ) : null;
  }
}
