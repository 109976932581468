import React, { useCallback, useState } from 'react';
import { message, Button, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import Replenishment from '@/components/Replenishment/index';
import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import { isGuest } from '@/util/commonUtils';
import cookies from 'react-cookies';
// import { log } from '@/pages/SkuDetailCable/utils';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { addCarMku2, addBuyMku2, addCarBefore2 } from '@/api/newApi/skudetail';
import { getJdiOSFile } from '@/util';

const Index = () => {
  const [isLocalPro] = useState(cookies.load('stationType') == '0');
  const [isShow, setIsShow] = useState(false); //展示
  const checkedService = useSelector((state: any) => {
    return state?.SkuDetail.checkedService;
  });
  // const userInfo = useSelector((state: any) => {
  //   return state?.User;
  // });
  const amount = useRecoilValue('amount');
  // const main = useRecoilValue('main');

  const basicInfo = useRecoilValue('basicInfo');
  const { t } = useTranslation();
  //加入采购清单
  const addCart = useCallback((skuId: number, num: number) => {
    addCarMku2({
      skuId,
      num,
      purchaseListId: 1,
    }).then((res: any) => {
      if (res.success == true) {
        // message.success('加入采购清单成功');
        message.success(`${TLT(`加入采购清单成功`)}`);
        // window.setTimeout(function() {
        //   window.location.href = '/cart/carts';
        // }, 100);
      } else {
        message.warning(res.msg);
      }
    });
  }, []);

  //
  //加入采购清单
  const addCart2 = useCallback((sku: number, num: number, skuPrice?: number) => {
    addCarMku2([
      {
        sku,
        num,
        skuPrice,
      },
    ]).then((res: any) => {
      if (res.success == true) {
        // message.success('加入采购清单成功');
        message.success(`${TLT(`加入采购清单成功`)}`);
        window.setTimeout(function() {
          window.location.href = '/cart/carts';
        }, 100);
      } else {
        message.warning(res.msg);
      }
    });
  }, []);

  //立即购买
  const addBuy2 = useCallback(async (sku: number, num: number, skuPrice?: number) => {
    const params: Record<string, any> = {
      sku,
      num,
      skuPrice,
    };
    const result: any = await addBuyMku2(params);
    if (result.success == true) {
      window.setTimeout(function() {
        window.location.href = '/order/goOrderInfo?tradeModel=2'; //跳转结算
      }, 100);
    } else {
      message.warning(result.msg);
    }
  }, []);

  const addCarBe2 = useCallback(
    async (type: number) => {
      // const mkuIds = main?.mkuId;
      const sku = basicInfo?.skuId;
      const skuPrice = basicInfo?.showCurrency?.salePrice;
      if (!sku || !amount) {
        return;
      }
      // const result = JSON.parse(basicInfo?.saleState);
      if (basicInfo?.skuSaleState?.saleState == 1) {
        if (type == 1) {
          addCart2(sku, amount, skuPrice);
          // log('isp_info', 'add_cart', skuId, getCookie('pin'), 'skuDetail');
        } else {
          addBuy2(sku, amount, skuPrice);
          // log('isp_info', 'to_buy', skuId, getCookie('pin'), 'skuDetail');
        }
      } else {
        message.warning(basicInfo?.skuSaleState?.notAllowAttrLimitList?.message);
      }
    },
    [basicInfo?.skuId, amount, checkedService],
  );

  return (
    <div className={styles.m}>
      <div style={{ display: 'flex' }}>
        {(isLocalPro || basicInfo?.remainNum !== 0) && (
          // <Tooltip placement="top" title={TLT(`加入采购清单`)}>
          <Button
            type="primary"
            danger
            size="large"
            className={`${styles.btn}`}
            disabled={
              isGuest() ||
              (basicInfo?.productPriceVo && basicInfo.productPriceVo.price <= 0) ||
              (checkedService && checkedService?.length > 0) ||
              (basicInfo?.skuSaleState && basicInfo?.skuSaleState?.saleState == 0) ||
              basicInfo?.remainNum === 0
            }
            data-track={JSON.stringify({
              eventId: 'ProductDetail_AddCart'
            })}
            onClick={() => {
              addCarBe2(1);
              log('jdisp', 'Product_AddtoCart');
            }}
          >
            {/* <div className={styles.btn_car}> */}
            <img className={styles.btn_car_icon} src={getJdiOSFile('mubla1/131989502889417054.png')} />
            {TLT(`加入采购清单`)}
            {/* </div> */}
          </Button>
        )
        // </Tooltip>
        }
        {
          <Button
            danger
            className={styles.btn}
            size="large"
            data-track={JSON.stringify({
              eventId: 'ProductDetail_BuyNow'
            })}
            disabled={
              isGuest() ||
              (basicInfo?.productPriceVo && basicInfo.productPriceVo.price <= 0) ||
              (basicInfo?.skuSaleState && basicInfo?.skuSaleState?.saleState == 0) ||
              basicInfo?.remainNum === 0
            }
            onClick={() => {
              addCarBe2(2);
              log('jdisp', 'Product_Placeorder');
            }}
          >
            {/*立即购买*/}
            {TLT(`立即购买`)}
          </Button>
        }
        {!isLocalPro && basicInfo?.remainNum === 0 && !isGuest() && (
          <button
            className={`${styles.Replenishment}`}
            data-track={JSON.stringify({
              eventId: 'ProductDetail_ApplyInventory',
              mku: basicInfo?.skuId,
            })}
            onClick={() => {
              setIsShow(true);
            }}
          >
            {TLT(`申请补货`)}
          </button>
        )}
        {/**/}
      </div>
      <Replenishment
        isVisible={isShow}
        setIsVisible={isVisble => setIsShow(isVisble)}
        shopInfo={{
          mkuId: basicInfo?.skuId,
          img: basicInfo?.imagePath,
          productName: basicInfo?.productName,
        }}
      />
    </div>
  );
};

export default Index;
