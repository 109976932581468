import React from 'react';
import styles from './index.module.scss';
interface Props {
  children: any;
  onClick: (e?: any) => void;
  className?: string;
}
const TextButton: React.FC<Props> = ({ className, children, onClick }) => {
  return (
    <span className={`${styles.textButton} ${className} underText`} onClick={onClick}>
      {children}
    </span>
  );
};

export default TextButton;
